import _ from 'lodash';
import React, { useState, useCallback } from 'react';
import { Translate } from 'react-redux-i18n';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Language from '@material-ui/icons/Language';
import { LOCALES } from 'constants.js';

const MenuLocale = ({ activeLocale, onLocaleChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleClick = useCallback(
    (locale) => {
      onLocaleChange(locale);
      handleClose();
    },
    [onLocaleChange, handleClose]
  );

  const renderButtons = () => {
    const isSelected = (name) => {
      return activeLocale === name ? true : false;
    };

    return _.map(LOCALES, ({ label, name }) => {
      return (
        <MenuItem
          key={name}
          onClick={() => handleClick(name)}
          selected={isSelected(name)}
        >
          <Translate value={label} />
        </MenuItem>
      );
    });
  };

  const isMenuOpen = Boolean(anchorEl);

  return (
    <div>
      <IconButton
        aria-owns={isMenuOpen ? 'menu-locale' : null}
        aria-haspopup="true"
        onClick={handleOpen}
        color="inherit"
      >
        <Language />
      </IconButton>
      <Menu
        id="menu-locale"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMenuOpen}
        onClose={handleClose}
      >
        {renderButtons()}
      </Menu>
    </div>
  );
};

export default MenuLocale;
