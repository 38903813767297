import React from 'react';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { PASSWORD_MIN_LENGTH } from 'constants.js';

export const FORM_FIELDS = [
  {
    label: 'account.current_password_if_set',
    name: 'currentPassword',
    type: 'password',
  },
  {
    label: 'account.new_password',
    name: 'newPassword',
    type: 'password',
    endAdornmentIcon: <HelpOutlineIcon fontSize="small" color="disabled" />,
    endAdornmentTooltip: {
      translationKey: 'auth.password_conditions',
      translationValue: {
        number: PASSWORD_MIN_LENGTH,
      },
    },
  },
  {
    label: 'account.confim_new_password',
    name: 'newPasswordConfirm',
    type: 'password',
  },
];
