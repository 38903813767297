import _ from 'lodash';
import React, { useState, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'react-redux-i18n';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const styles = () => ({});

const ConfirmDialog = ({
  children,
  onConfirm,
  onCancel,
  onOpen,
  title,
  description,
  isEnabled = true,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    if (!isEnabled) {
      return;
    }
    if (_.isFunction(onOpen)) {
      onOpen();
    }
    setOpen(true);
  }, [isEnabled, onOpen, setOpen]);

  const handleClose = useCallback(() => {
    if (_.isFunction(onCancel)) {
      onCancel();
    }
    setOpen(false);
  }, [setOpen, onCancel]);

  const handleConfirm = useCallback(() => {
    if (_.isFunction(onConfirm)) {
      onConfirm();
    }
    setOpen(false);
  }, [setOpen, onConfirm]);

  return (
    <>
      <Box component="span" onClick={handleOpen}>
        {children}
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-description"
      >
        {title && <DialogTitle>{title}</DialogTitle>}
        {description && (
          <DialogContent id="alert-dialog-description">
            {description}
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleClose} size="small">
            <Translate value="common.cancel" />
          </Button>
          <Button
            onClick={handleConfirm}
            color="secondary"
            variant="contained"
            size="small"
            autoFocus
          >
            <Translate value="common.confirm" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(ConfirmDialog);
