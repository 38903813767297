import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { Translate, I18n } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import useCurrencySettings from 'hooks/useCurrencySettings';
import * as calculatorQueries from 'modules/calculator/queries';
import CalculatorDivider from 'modules/calculator/components/CalculatorDivider';
import CalculatorTextField from 'modules/calculator/components/CalculatorTextField';
import CalculatorGrid from 'modules/calculator/components/CalculatorGrid';
import CalculatorResult from 'modules/calculator/components/CalculatorResult';
import CalculatorResultDetails from 'modules/calculator/components/CalculatorResultDetails';
import CalculatorPeriodType from 'modules/calculator/components/CalculatorPeriodType';
import CalculatorCaption from 'modules/calculator/components/CalculatorCaption';
import { PERIOD_MONTHLY } from 'modules/calculator/constants';
import {
  STARTING_BALANCE_DEFAULT,
  INTEREST_RATE_DEFAULT,
  CONTRIBUTION_FREQUENCY_DEFAULT,
  PERIOD_DEFAULT,
  CONTRIBUTION_DEFAULT,
  CONTRIBUTION_TERM_DEFAULT,
} from './constants';

const styles = () => ({});

const SavingRetirementCalculator = () => {
  const [yearsValue, setYearsValue] = useState(PERIOD_DEFAULT);
  const [startingBalanceValue, setStartingBalanceValue] = useState(
    STARTING_BALANCE_DEFAULT
  );
  const [interestRateValue, setInterestRateValue] = useState(
    INTEREST_RATE_DEFAULT
  );
  const [contributionFrequencyValue, setContributionFrequencyValue] = useState(
    CONTRIBUTION_FREQUENCY_DEFAULT
  );
  const [contributionValue, setContributionValue] =
    useState(CONTRIBUTION_DEFAULT);
  const [contributionYearsValue, setContributionYearsValue] = useState(
    CONTRIBUTION_TERM_DEFAULT
  );

  const [result, setResult] = useState(0);

  const months = _.toNumber(yearsValue) * 12;
  const startingBalance = _.toNumber(startingBalanceValue);
  const interestRate = _.toNumber(interestRateValue);
  const isMonthlyContribution = contributionFrequencyValue === PERIOD_MONTHLY;
  const contribution = _.toNumber(contributionValue);
  const contributionMonths =
    contributionYearsValue === ''
      ? months
      : _.toNumber(contributionYearsValue) * 12;

  const currencySettings = useCurrencySettings();

  useEffect(() => {
    const result = calculatorQueries.getSavingRetirementResult({
      months,
      startingBalance,
      interestRate: interestRate / 100,
      isMonthlyContribution,
      contribution,
      contributionMonths,
    });

    setResult(result);
  }, [
    startingBalance,
    interestRate,
    months,
    contribution,
    contributionFrequencyValue,
    contributionMonths,
    isMonthlyContribution,
  ]);

  const renderForm = () => (
    <>
      <CalculatorTextField
        label={I18n.t('calculator.years_to_save')}
        value={yearsValue}
        defaultValue={1}
        minValue={1}
        onChange={setYearsValue}
      />
      <CalculatorTextField
        label={I18n.t('calculator.starting_balance')}
        value={startingBalanceValue}
        defaultValue={0}
        minValue={0}
        onChange={setStartingBalanceValue}
      />
      <CalculatorTextField
        label={I18n.t('calculator.annual_interest_rate')}
        value={interestRateValue}
        defaultValue={0}
        minValue={0}
        onChange={setInterestRateValue}
      />
      <CalculatorPeriodType
        value={contributionFrequencyValue}
        label={I18n.t('calculator.contribution_frequency')}
        monthLabel={I18n.t('calculator.monthly')}
        yearLabel={I18n.t('calculator.annual')}
        onChange={setContributionFrequencyValue}
      />
      <CalculatorTextField
        label={I18n.t('calculator.contribution_amount')}
        value={contributionValue}
        defaultValue={0}
        minValue={0}
        onChange={setContributionValue}
      />
      <CalculatorTextField
        label={`${I18n.t('calculator.year_contribution')} *`}
        value={contributionYearsValue}
        defaultValue={''}
        minValue={0}
        maxValue={months / 12}
        onChange={setContributionYearsValue}
      />
      <CalculatorCaption>
        <Translate value="calculator.how_long_contributions" />
      </CalculatorCaption>
    </>
  );

  const renderResult = () => {
    const additionalDeposits =
      contribution * contributionMonths * (isMonthlyContribution ? 1 : 1 / 12);
    const interest = result - startingBalance - additionalDeposits;

    return (
      <>
        <CalculatorResult
          value={
            <NumberFormat
              value={_.round(result, 2)}
              displayType="text"
              {...currencySettings}
            />
          }
          text1={I18n.t('calculator.you_will_have')}
          text2={I18n.t('calculator.after_n_months', { count: months })}
        />
        <CalculatorDivider />
        <CalculatorResultDetails
          label={I18n.t('calculator.initial_balance')}
          value={startingBalance}
        />
        <CalculatorResultDetails
          label={I18n.t('calculator.total_contributions')}
          value={additionalDeposits}
        />
        <CalculatorResultDetails
          label={I18n.t('calculator.total_interest')}
          value={Math.round(interest)}
        />
      </>
    );
  };

  return (
    <CalculatorGrid leftColumn={renderForm()} rightColumn={renderResult()} />
  );
};

export default withStyles(styles)(SavingRetirementCalculator);
