import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'react-redux-i18n';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import globalStyles from 'globalStyles.js';
import BackButton from 'components/backButton/BackButton';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import SubscriptionStepper from './subscriptionStepper/SubscriptionStepper';
import SubscriptionPlansTable from './subscriptionPlansTable/SubscriptionPlansTable';

const styles = (theme) => ({
  root: {
    padding: theme.custom.wrapper.padding,
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      width: theme.breakpoints.width('lg'),
    },
  },
  heading: {
    marginBottom: theme.spacing(2),
  },
});

const SubscriptionPlans = ({ classes }) => {
  const globalClasses = globalStyles();

  return (
    <div className={classes.root}>
      <BackButton />
      <Grid container justifyContent="center">
        <Grid item className={globalClasses.containerLg}>
          <Grid container>
            <Grid item xs>
              <SubscriptionStepper activeStep={1} />
              <Grid container justifyContent="center" spacing={10}>
                <Grid item xs={6}>
                  <Typography className={classes.heading} variant="h6">
                    <Translate value="subscription.plan" />{' '}
                    <Translate value="subscription.subscription_type.premium" />
                  </Typography>
                  <SubscriptionPlansTable showPremium isDense noBorder />
                </Grid>
                <Grid item xs={6}>
                  <Card>
                    <CardContent>Form</CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(SubscriptionPlans);
