import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { Translate, I18n } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import useCurrencySettings from 'hooks/useCurrencySettings';
import * as calculatorQueries from 'modules/calculator/queries';
import CalculatorDivider from 'modules/calculator/components/CalculatorDivider';
import CalculatorTextField from 'modules/calculator/components/CalculatorTextField';
import CalculatorGrid from 'modules/calculator/components/CalculatorGrid';
import CalculatorResult from 'modules/calculator/components/CalculatorResult';
import CalculatorPeriodLength from 'modules/calculator/components/CalculatorPeriodLength';
import { OTHER, YEARS_CUSTOM_DEFAULT } from 'modules/calculator/constants';
import {
  CURRENT_BALANCE_DEFAULT,
  CURRENT_MONTHLY_PAYMENT_DEFAULT,
  CURRENT_MONTHS_DEFAULT,
  NEW_INTEREST_RATE_DEFAULT,
  NEW_MONTHLY_CONTRIBUTION_DEFAULT,
} from './constants';

const styles = (theme) => ({
  subtitle: {
    marginTop: theme.spacing(2),
  },
  tableCell: {
    padding: 0,
    paddingTop: theme.spacing(1.5),
    paddingLeft: theme.spacing(2),
    border: 'none',
  },
  tableCellLabel: {
    padding: 0,
    paddingTop: theme.spacing(1),
    border: 'none',
  },
  secondary: {
    color: theme.custom.color.secondary,
  },
});

const CompoundInterestCalculator = ({ classes }) => {
  const [currentAmountValue, setCurrentAmountValue] = useState(
    CURRENT_BALANCE_DEFAULT
  );
  const [currentMonthlyPaymentValue, setCurrentMonthlyPaymentValue] = useState(
    CURRENT_MONTHLY_PAYMENT_DEFAULT
  );
  const [currentMonthsValue, setCurrentMonthsValue] = useState(
    CURRENT_MONTHS_DEFAULT
  );
  const [newInterestRateValue, setNewInterestRateValue] = useState(
    NEW_INTEREST_RATE_DEFAULT
  );
  const [newAmountValue, setNewAmountValue] = useState(
    NEW_MONTHLY_CONTRIBUTION_DEFAULT
  );
  const [newYearsValue, setRefinanceYearsValue] = useState(0);
  const [newYearsCustomValue, setRefinanceYearsCustomValue] =
    useState(YEARS_CUSTOM_DEFAULT);

  const [newMonthlyPayment, setNewMonthlyPayment] = useState(0);

  const currentAmount = _.toNumber(currentAmountValue);
  const currentMonthlyPayment = _.toNumber(currentMonthlyPaymentValue);
  const currentMonths = _.toNumber(currentMonthsValue);
  const newAmount = _.toNumber(newAmountValue);
  const newInterestRate = _.toNumber(newInterestRateValue);
  const newYears =
    newYearsValue === OTHER
      ? _.toNumber(newYearsCustomValue)
      : _.toNumber(newYearsValue);

  const currencySettings = useCurrencySettings(true);

  useEffect(() => {
    if (!newAmount || !newYears) {
      return;
    }

    const result = calculatorQueries.getLoanResult({
      loanAmount: newAmount,
      interestRate: newInterestRate / 100,
      years: newYears,
    });

    if (result < 1) {
      return;
    }

    setNewMonthlyPayment(result);
  }, [newAmount, newYears, newInterestRate]);

  const renderTableCell = (value, isNumber) => (
    <TableCell key={_.uniqueId()} className={classes.tableCell} align="center">
      {isNumber ? (
        <NumberFormat
          className={Math.round(value) < 0 ? classes.secondary : null}
          value={Math.round(value)}
          displayType="text"
          {...currencySettings}
        />
      ) : (
        <span
          className={
            _.isNumber(value) && Math.round(value) < 0
              ? classes.secondary
              : null
          }
        >
          {value}
        </span>
      )}
    </TableCell>
  );

  const renderTableRow = ({ label, vaules, isNumber }) => {
    return (
      <TableRow>
        <TableCell className={classes.tableCellLabel} align="right">
          {label}
          {label ? ':' : null}
        </TableCell>
        {_.map(vaules, (value) => renderTableCell(value, isNumber))}
      </TableRow>
    );
  };

  const renderForm = () => (
    <>
      <Typography variant="subtitle1" gutterBottom>
        <Translate value="calculator.current_loan" />
      </Typography>
      <CalculatorTextField
        label={I18n.t('calculator.remaining_amount')}
        value={currentAmountValue}
        defaultValue={0}
        minValue={0}
        onChange={setCurrentAmountValue}
      />
      <CalculatorTextField
        label={I18n.t('calculator.monthly_payment')}
        value={currentMonthlyPaymentValue}
        defaultValue={0}
        minValue={0}
        onChange={setCurrentMonthlyPaymentValue}
      />
      <CalculatorTextField
        label={I18n.t('calculator.remaining_months')}
        value={currentMonthsValue}
        defaultValue={0}
        minValue={0}
        onChange={setCurrentMonthsValue}
      />
      <Typography className={classes.subtitle} variant="subtitle1" gutterBottom>
        <Translate value="calculator.new_loan" />
      </Typography>
      <CalculatorTextField
        label={I18n.t('calculator.loan_amount')}
        value={newAmountValue}
        defaultValue={0}
        minValue={0}
        onChange={setNewAmountValue}
      />
      <CalculatorPeriodLength
        yearsValue={newYearsValue}
        yearsOnChange={setRefinanceYearsValue}
        yearsCustomValue={newYearsCustomValue}
        yearsCustomOnChange={setRefinanceYearsCustomValue}
      />
      <CalculatorTextField
        label={I18n.t('calculator.annual_interest_rate')}
        value={newInterestRateValue}
        defaultValue={0}
        minValue={0}
        onChange={setNewInterestRateValue}
      />
    </>
  );

  const renderResult = () => {
    const currentTotalPayment = currentMonths * currentMonthlyPayment;
    const currentTotalInterest = currentTotalPayment - currentAmount;
    const newTotalPayment = newMonthlyPayment * newYears * 12;
    const newTotalInterest = newTotalPayment - newAmount;
    const interestDifference = currentTotalInterest - newTotalInterest;

    return (
      <>
        <CalculatorResult
          value={
            <NumberFormat
              value={_.round(interestDifference, 2)}
              displayType="text"
              {...currencySettings}
            />
          }
          isSecondaryColor={0 > interestDifference}
          text1={`${I18n.t('calculator.total_cost_difference')}:`}
        />
        <CalculatorDivider />
        <TableContainer>
          <Table size="small">
            <TableHead>
              {renderTableRow({
                label: null,
                vaules: [
                  I18n.t('calculator.difference'),
                  I18n.t('calculator.current'),
                  I18n.t('calculator.new'),
                ],
              })}
            </TableHead>
            <TableBody>
              {renderTableRow({
                label: <Translate value="calculator.loan_amount" />,
                vaules: [currentAmount - newAmount, currentAmount, newAmount],
                isNumber: true,
              })}
              {renderTableRow({
                label: <Translate value="calculator.monthly_payment" />,
                vaules: [
                  currentMonthlyPayment - newMonthlyPayment,
                  currentMonthlyPayment,
                  newMonthlyPayment,
                ],
                isNumber: true,
              })}
              {renderTableRow({
                label: <Translate value="calculator.months" />,
                vaules: [
                  currentMonths - newYears * 12,
                  currentMonths,
                  newYears * 12,
                ],
              })}
              {renderTableRow({
                label: <Translate value="calculator.total_interest" />,
                vaules: [
                  currentTotalInterest - newTotalInterest,
                  currentTotalInterest,
                  newTotalInterest,
                ],
                isNumber: true,
              })}
              {renderTableRow({
                label: <Translate value="calculator.total_payments" />,
                vaules: [
                  currentTotalPayment - newTotalPayment,
                  currentTotalPayment,
                  newTotalPayment,
                ],
                isNumber: true,
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  return (
    <CalculatorGrid leftColumn={renderForm()} rightColumn={renderResult()} />
  );
};

export default withStyles(styles)(CompoundInterestCalculator);
