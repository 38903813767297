import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { I18n } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import useCurrencySettings from 'hooks/useCurrencySettings';
import * as calculatorQueries from 'modules/calculator/queries';
import CalculatorDivider from 'modules/calculator/components/CalculatorDivider';
import CalculatorTextField from 'modules/calculator/components/CalculatorTextField';
import CalculatorGrid from 'modules/calculator/components/CalculatorGrid';
import CalculatorResult from 'modules/calculator/components/CalculatorResult';
import CalculatorResultDetails from 'modules/calculator/components/CalculatorResultDetails';
import CalculatorPeriodType from 'modules/calculator/components/CalculatorPeriodType';
import { PERIOD_MONTHLY } from 'modules/calculator/constants';
import {
  SAVINGS_GOAL_DEFAULT,
  STARTING_BALANCE_DEFAULT,
  INTEREST_RATE_DEFAULT,
  PERIOD_TYPE_DEFAULT,
  PERIOD_DEFAULT,
} from './constants';

const styles = () => ({});

const SavingsGoalCalculator = () => {
  const [savingsGoalValue, setSavingsGoalValue] =
    useState(SAVINGS_GOAL_DEFAULT);
  const [startingBalanceValue, setStartingBalanceValue] = useState(
    STARTING_BALANCE_DEFAULT
  );
  const [interestRateValue, setInterestRateValue] = useState(
    INTEREST_RATE_DEFAULT
  );
  const [periodTypeValue, setPeriodTypeValue] = useState(PERIOD_TYPE_DEFAULT);
  const [periodValue, setPeriodValue] = useState(PERIOD_DEFAULT);
  const [result, setResult] = useState(0);

  const savingsGoal = _.toNumber(savingsGoalValue);
  const startingBalance = _.toNumber(startingBalanceValue);
  const interestRate = _.toNumber(interestRateValue);
  const months =
    _.toNumber(periodValue) * (periodTypeValue === PERIOD_MONTHLY ? 1 : 12);

  const currencySettings = useCurrencySettings();

  useEffect(() => {
    const result = calculatorQueries.getSavingsGoalResult({
      savingsGoal,
      startingBalance,
      months,
      interestRate: interestRate / 100,
    });

    setResult(result);
  }, [savingsGoal, startingBalance, interestRate, months]);

  const renderForm = () => (
    <>
      <CalculatorTextField
        label={I18n.t('calculator.savings_goal')}
        value={savingsGoalValue}
        defaultValue={0}
        minValue={0}
        onChange={setSavingsGoalValue}
      />
      <CalculatorTextField
        label={I18n.t('calculator.starting_balance')}
        value={startingBalanceValue}
        defaultValue={0}
        minValue={0}
        onChange={setStartingBalanceValue}
      />
      <CalculatorTextField
        label={I18n.t('calculator.period')}
        value={periodValue}
        defaultValue={1}
        minValue={1}
        onChange={setPeriodValue}
      />
      <CalculatorPeriodType
        value={periodTypeValue}
        onChange={setPeriodTypeValue}
      />
      <CalculatorTextField
        label={I18n.t('calculator.annual_interest_rate')}
        value={interestRateValue}
        defaultValue={0}
        minValue={0}
        onChange={setInterestRateValue}
      />
    </>
  );

  const renderResult = () => {
    const resultDaily = result > 0 ? result / 30 : 0;
    const netSavings =
      interestRate > 0 ? months * result : savingsGoal - startingBalance;
    const interest = interestRate > 0 ? savingsGoal - netSavings : 0;
    const resultYearly =
      result > 0 ? (months < 12 ? result * months : result * 12) : 0;

    return (
      <>
        <CalculatorResult
          value={
            <NumberFormat
              value={result}
              displayType="text"
              {...currencySettings}
            />
          }
          text1={I18n.t('calculator.you_need_save')}
          text2={
            months === 1
              ? I18n.t('calculator.for_1_month_to_reach_goal', {
                count: months,
              })
              : I18n.t('calculator.for_n_months_to_reach_goal', {
                count: months,
              })
          }
        />
        <CalculatorDivider />
        <CalculatorResultDetails
          label={I18n.t('calculator.annual')}
          value={resultYearly}
        />
        <CalculatorResultDetails
          label={I18n.t('calculator.daily')}
          value={resultDaily}
        />
        <CalculatorResultDetails
          label={I18n.t('calculator.net_savings')}
          value={netSavings}
        />
        <CalculatorResultDetails
          label={I18n.t('calculator.interest')}
          value={interest}
        />
      </>
    );
  };

  return (
    <CalculatorGrid leftColumn={renderForm()} rightColumn={renderResult()} />
  );
};

export default withStyles(styles)(SavingsGoalCalculator);
