import React from 'react';
import { Translate } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import config from 'config.js';

const styles = (theme) => ({
  button: {
    marginTop: theme.spacing(2),
  },
});

const AuthExternalButtons = ({ classes }) => {
  return (
    <div>
      <Button
        href={'/api/auth/google'}
        variant="outlined"
        className={classes.button}
        fullWidth
      >
        <Translate value="auth.sign_in_google" />
      </Button>
      {config.isFacebookAuthEnabled && (
        <Button
          href={'/api/auth/facebook'}
          variant="outlined"
          className={classes.button}
          fullWidth
        >
          <Translate value="auth.sign_in_facebook" />
        </Button>
      )}
    </div>
  );
};

export default withStyles(styles)(AuthExternalButtons);
