import _ from 'lodash';
import React, { useState, useCallback, useEffect } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CurrencySelector from 'components/currencySelector/CurrencySelector';
import BudgetTemplateSelector from 'components/budgetTemplateSelector/BudgetTemplateSelector';
import { getNewBudgetName } from 'models/budget/queries';
import globalStyles from 'globalStyles.js';

const styles = (theme) => ({
  dialogContent: {
    [theme.breakpoints.up('sm')]: {
      minWidth: 400,
    },
  },
  titleInput: {
    marginTop: 0,
  },
  currencySelector: {
    marginTop: theme.spacing(1),
  },
  templateSelector: {
    marginTop: theme.spacing(1),
  },
  footer: {
    marginTop: theme.spacing(2),
  },
});

const BudgetDetailsDialog = ({
  name = '',
  currency,
  defaultCurrency,
  description,
  budgetsList,
  budgetTemplates,
  isBudgetTemplatesVisible,
  confirmButtonText,
  fetchBudgetTemplates,
  isOpen,
  setIsOpen,
  onConfirm,
  onCancel,
  classes,
}) => {
  const [nameValue, setNameValue] = useState(name);
  const [templateValue, setTemplateValue] = useState('');
  const [currencyValue, setCurrencyValue] = useState(
    currency || defaultCurrency
  );

  const globalClasses = globalStyles();

  useEffect(() => {
    if (isBudgetTemplatesVisible && isOpen && !budgetTemplates) {
      fetchBudgetTemplates();
    }
  }, [isBudgetTemplatesVisible, isOpen, budgetTemplates, fetchBudgetTemplates]);

  useEffect(() => {
    setTemplateValue('');
  }, [isOpen]);

  useEffect(() => {
    setNameValue(name || '');
  }, [isOpen, name]);

  const handleClose = useCallback(() => {
    if (_.isFunction(onCancel)) {
      onCancel();
    }
    setIsOpen(false);
  }, [setIsOpen, onCancel]);

  const handleConfirm = useCallback(() => {
    const budgetListSize = _.size(budgetsList);
    const newBudgetName = getNewBudgetName(nameValue, budgetListSize);

    onConfirm({
      name: newBudgetName,
      currency: currencyValue || defaultCurrency,
      ...(templateValue && { template: templateValue }),
    });
    setIsOpen(false);
  }, [
    setIsOpen,
    nameValue,
    currencyValue,
    templateValue,
    budgetsList,
    onConfirm,
    defaultCurrency,
  ]);

  const handleCurrencyChange = useCallback(
    (value) => {
      setCurrencyValue(value);
    },
    [setCurrencyValue]
  );

  const handleNameValueChange = (event) => {
    setNameValue(event.target.value);
  };

  const handleTemplateValueChange = useCallback(
    (event) => {
      setTemplateValue(event.target.value);
    },
    [setTemplateValue]
  );

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      aria-labelledby="edit-budget-dialog-description"
      data-test-id="budget-details-dialog"
    >
      <DialogTitle id="edit-budget-dialog-description">
        {description}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <TextField
          className={classes.titleInput}
          label={I18n.t('budget.budget_name')}
          value={nameValue}
          onChange={handleNameValueChange}
          margin="dense"
          variant="outlined"
          fullWidth
          inputProps={{ maxLength: 100 }}
        />
        {isBudgetTemplatesVisible && (
          <div className={classes.templateSelector}>
            <BudgetTemplateSelector
              label={I18n.t('budget.budget_template')}
              templates={budgetTemplates}
              templateValue={templateValue}
              handleTemplateValueChange={handleTemplateValueChange}
            />
          </div>
        )}
        <div className={classes.currencySelector}>
          <CurrencySelector
            selectedCurrency={currencyValue}
            onCurrencyChange={handleCurrencyChange}
          />
        </div>
      </DialogContent>
      <DialogActions className={globalClasses.dialogActions}>
        <Button onClick={handleClose} size="medium">
          <Translate value="common.cancel" />
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          variant="contained"
          size="medium"
          autoFocus
        >
          {confirmButtonText || <Translate value="common.confirm" />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(BudgetDetailsDialog);
