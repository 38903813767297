import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {},
});

const BudgetMonthDetails = () => {
  return (
    <>
      BudgetMonthDetails
    </>
  );
};

export default withStyles(styles)(BudgetMonthDetails);
