import React from 'react';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const styles = {
  button: {
    marginTop: 2,
    marginLeft: 16,
    backgroundColor: '#fff',
    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
  },
};

const BackButton = ({ classes }) => {
  let history = useHistory();

  const handleClick = () => {
    history.goBack();
  };

  return (
    <IconButton
      className={classes.button}
      onClick={handleClick}
      variant="contained"
    >
      <ArrowBackIcon />
    </IconButton>
  );
};

export default withStyles(styles)(BackButton);
