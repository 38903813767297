// TODO:
// 1. wrong colums size, not aligned with other tables
// 2. styling
import _ from 'lodash';
import React from 'react';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import { Translate, I18n } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import debug from 'utils/debug';
import useCurrencySettings from 'hooks/useCurrencySettings';
import BudgetTableHeaderRow from 'modules/budget/budgetTable/budgetTableHeaderRow/BudgetTableHeaderRow';
import * as budgetTableQueries from 'modules/budget/budgetTable/queries';
import globalStyles from 'globalStyles.js';

const DEBUG_PATH = 'modules/budget/BudgetOverviewTable';

const styles = (theme) => ({
  paper: {
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.custom.color.primaryLighter,
    border: '1px solid',
    borderColor: '#bdbdc3',
    borderRadius: 5,
    boxShadow: 'none',
    overflow: 'hidden',
  },
  title: {
    fontWeight: 500,
    paddingTop: theme.spacing(1),
    marginLeft: theme.spacing(2.5),
  },
  table: {
    minWidth: 650,
  },
  row: {
    '&:first-child > th, &:first-child > td': {
      borderColor: theme.custom.color.primaryLight,
    },
    '& > th, & > td': {
      paddingTop: theme.spacing(0.15),
      paddingBottom: theme.spacing(0.15),
      backgroundColor: theme.custom.color.primaryLighter,
      border: 'none',
    },
  },
  headerCell: {
    width: '15%',
    minWidth: 180,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2.75),
    fontWeight: 500,
  },
  number: {
    textAlign: 'right',
    fontSize: 14,
    lineHeight: 1.43,
    display: 'block',
    padding: '6px 0 7px',
    minWidth: 36,
  },
  emptyCell: {
    width: 47,
    paddingLeft: 16,
    paddingRight: 0,
  },
  sumCell: {
    fontWeight: 500,
    '&:last-child': {
      paddingRight: theme.custom.budget.lastCellPaddingRight,
    },
  },
});

const BudgetOverviewTable = ({
  headerCells,
  budgetYearOverview,
  budgetCurrency,
  budgetTableVariants,
  classes,
}) => {
  debug(DEBUG_PATH, 'render');
  const globalClasses = globalStyles();
  const colsSum = budgetYearOverview?.colsSum?.[budgetCurrency] || {};
  const variantsSum = budgetYearOverview?.variantsSum?.[budgetCurrency] || {};
  const currencySettings = useCurrencySettings();
  const defaultRowCells = budgetTableQueries.getDefaultRowCells();

  const cellClass = classNames(globalClasses.budgetTableCell, classes.cell);

  const getHeaderClass = (variant) => {
    return classNames(classes.headerCell, classes[`${variant}HeaderCell`]);
  };

  const getRowCells = (values) =>
    _.map(defaultRowCells, ({ value }) => {
      return {
        value,
        amount: values?.[value] || 0,
      };
    });

  const renderNumber = (value) => (
    <NumberFormat
      className={classes.number}
      value={value}
      displayType="text"
      disabled
      {...currencySettings}
    />
  );

  const renderRowCells = (variant) => {
    const values = colsSum?.[variant];
    const rowCells = getRowCells(values);

    return _.map(rowCells, ({ value, amount }) => (
      <TableCell key={value} className={cellClass}>
        {renderNumber(amount)}
      </TableCell>
    ));
  };

  return (
    <Paper className={classes.paper} data-test-id="budget-overview-table">
      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          aria-label="Budget overview table"
        >
          <BudgetTableHeaderRow
            className={classes.headerRow}
            label={I18n.t('budget.year_overview')}
            headerCells={headerCells}
            isRowSelectionAvailable={false}
            noBorder
          />
          <TableBody>
            {_.map(budgetTableVariants, (variant) => (
              <TableRow className={classes.row} key={variant}>
                <TableCell
                  className={getHeaderClass(variant)}
                  component="th"
                  scope="row"
                >
                  <Translate value={`budget.${variant}`} />
                </TableCell>
                <TableCell className={classes.emptyCell}></TableCell>
                {renderRowCells(variant)}
                <TableCell className={classes.sumCell}>
                  {renderNumber(variantsSum[variant] || 0)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default withStyles(styles)(BudgetOverviewTable);
