import _ from 'lodash';
import { I18n } from 'react-redux-i18n';
import * as localeModelQueries from 'models/locale/queries';
import {
  SAVINGS,
  SPENDINGS,
  INCOMES,
} from 'modules/budget/constants';
import { MONTHS_ID } from 'constants.js';

export const getPieChartData = (variantSum) =>
  _.reduce(
    variantSum,
    (result, value, key) => {
      if (value > 0) {
        result = [
          ...result,
          {
            name: key,
            value: value,
          },
        ];
      }
      return result;
    },
    []
  );

export const getBarChartData = (colsSum, activeYear) => {
  return _.reduce(MONTHS_ID, (result, value, index) => {
    return [
      ...result,
      {
        date: `${activeYear.year}-${("0" + (index + 1)).slice(-2)}`, // eg.: 2022-02
        incomes: colsSum?.[INCOMES]?.[value] || 0,
        spendings: colsSum?.[SPENDINGS]?.[value] || 0,
        savings: colsSum?.[SAVINGS]?.[value] || 0,
      }
    ]
  }, [])
}

export const getVerticalBarChartData = (
  variantsSum,
  balanceValue,
  currencyDetails
) => [
    {
      name: 'incomes',
      label: I18n.t('budget.incomes'),
      value: variantsSum.incomes || 0,
      valueLabel: localeModelQueries.getFormattedAmount(
        variantsSum.incomes,
        currencyDetails
      ),
    },
    {
      name: 'spendings',
      label: I18n.t('budget.spendings'),
      value: variantsSum.spendings || 0,
      valueLabel: localeModelQueries.getFormattedAmount(
        variantsSum.spendings,
        currencyDetails
      ),
    },
    {
      name: 'net',
      label: I18n.t('common.net'),
      value: balanceValue,
      valueLabel: localeModelQueries.getFormattedAmount(
        balanceValue,
        currencyDetails
      ),
    },
  ];
