import _ from 'lodash';
import React, { useState, useEffect, useCallback } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Spinner from 'components/spinner/spinner';
import Footer from 'components/footer/Footer';
import SavingsGoalCalculator from 'modules/calculator/savingsGoalCalculator/SavingsGoalCalculator';
import MortgageLoanCalculator from 'modules/calculator/mortgageLoanCalculator/MortgageLoanCalculator';
import CompoundInterestCalculator from 'modules/calculator/compoundInterestCalculator/CompoundInterestCalculator';
import SavingRetirementCalculator from 'modules/calculator/savingRetirementCalculator/SavingRetirementCalculator';
import RateReturnCalculator from 'modules/calculator/rateReturnCalculator/RateReturnCalculator';
import LoanRefinanceCalculator from 'modules/calculator/loanRefinanceCalculator/LoanRefinanceCalculator';
import globalStyles from 'globalStyles.js';
import { CALCULATORS_LIST_DEFINITION } from './constants';

const styles = (theme) => ({
  root: {
    padding: theme.custom.wrapper.padding,
    marginBottom: theme.custom.global.marginBottom,
    minHeight: theme.custom.global.minHeightWithFooter,
  },
  cardActionArea: {
    height: '100%',
  },
  card: {
    height: '100%',
  },
  cardContent: {
    paddingTop: theme.spacing(1),
  },
  title: {
    textAlign: 'center',
    fontWeight: 500,
  },
  image: {
    maxWidth: '100%',
  },
  disclaimer: {
    margin: 0,
    fontStyle: 'italic',
    textAlign: 'center',
  },
  dialogTitleText: {
    marginRight: theme.spacing(3),
  },
  dialogContent: {
    paddingBottom: theme.spacing(4),
  },
  dialogFooter: {
    padding: theme.spacing(3),
  },
  dialogCloseButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
});

const CalculatorsOverview = ({ classes }) => {
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [openDialogId, setOpenDialogId] = useState(null);

  const globalClasses = globalStyles();

  useEffect(() => {
    const timer = setTimeout(() => {
      setImagesLoaded(true);
    }, 3000);

    const loadImage = (imageUrl) => {
      return new Promise((resolve, reject) => {
        const loadImage = new Image();
        loadImage.src = imageUrl;
        loadImage.onload = () => resolve();
        loadImage.onerror = (err) => reject(err);
      });
    };

    Promise.all(
      _.map(CALCULATORS_LIST_DEFINITION, (calculator) =>
        loadImage(calculator.imageUrl)
      )
    )
      .then(() => setImagesLoaded(true))
      .catch((err) => console.log('Failed to load images', err));

    return () => clearTimeout(timer);
  }, []);

  const handleClose = useCallback(() => {
    setOpenDialogId(null);
  }, [setOpenDialogId]);

  const handleClick = useCallback(
    (id) => {
      setOpenDialogId(id);
    },
    [setOpenDialogId]
  );

  const getDialogSize = () => 'md';

  const idOpenDialog = (id) => openDialogId === id;

  const renderDialogContent = (id) => {
    switch (id) {
      case 'savings-goal-calculator':
        return <SavingsGoalCalculator />;
      case 'mortgage-loan-calculator':
        return <MortgageLoanCalculator />;
      case 'compound-interest-calculator':
        return <CompoundInterestCalculator />;
      case 'saving-for-retirement-calculator':
        return <SavingRetirementCalculator />;
      case 'rate-return-calculator':
        return <RateReturnCalculator />;
      case 'loan-refinance-calculator':
        return <LoanRefinanceCalculator />;
    }
  };

  const renderListItem = (calculator) => {
    const { id, label, imageUrl } = calculator;

    return (
      <Grid key={id} item xs={12} sm={6} md={3}>
        <CardActionArea
          className={classes.cardActionArea}
          onClick={() => handleClick(id)}
        >
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <img
                className={classes.image}
                src={imageUrl}
                alt={I18n.t(label)}
              />
              <Typography className={classes.title} variant="subtitle1">
                <Translate value={label} />
              </Typography>
            </CardContent>
          </Card>
        </CardActionArea>
        <Dialog
          fullWidth
          maxWidth={getDialogSize(id)}
          open={idOpenDialog(id)}
          onClose={handleClose}
        >
          <DialogTitle>
            <Translate className={classes.dialogTitleText} value={label} />
            <IconButton
              className={classes.dialogCloseButton}
              aria-label="close"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            {renderDialogContent(id)}
          </DialogContent>
          <div className={classes.dialogFooter}>
            <Typography
              variant="caption"
              paragraph
              className={classes.disclaimer}
            >
              <Translate value="calculator.disclaimer" />
            </Typography>
          </div>
        </Dialog>
      </Grid>
    );
  };

  if (!imagesLoaded) {
    return <Spinner />;
  }

  return (
    <>
      <div className={classes.root} data-test-id="calculators-overview">
        <Grid container justifyContent="center">
          <Grid item className={globalClasses.containerLg}>
            <Typography className={globalClasses.pageTitle} variant="h2">
              <Translate value="app.calculators" />
            </Typography>
            <Grid container spacing={3} alignItems="stretch">
              {_.map(CALCULATORS_LIST_DEFINITION, (calculator) =>
                renderListItem(calculator)
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Footer isImagesLinkVisible />
    </>
  );
};

export default withStyles(styles)(CalculatorsOverview);
