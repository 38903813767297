import _ from 'lodash';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
} from 'recharts';
import { withStyles } from '@material-ui/core/styles';
import * as budgetModulesQueries from 'modules/budget/queries';
import * as localeModelQueries from 'models/locale/queries';
import { VARIANTS_COLOR } from 'modules/budget/constants';

// Data example:
//
// const data = [
//   {
//     date: '2000-01',
//     income: 4000,
//     spending: 2400,
//     savings: 0,
//   },
// ]

const styles = (theme) => ({
  root: {
    '& .recharts-legend-wrapper': {
      paddingLeft: '5%',
    },
    '& .recharts-tooltip-wrapper': {
      outline: 'none',
    },
  },
  tooltip: {
    background: '#ffffffee',
    border: '1px solid #ccc',
    outline: 'none',
    borderRadius: 3,
    padding: theme.spacing(1),
    margin: 5,
  },
  row: {
    minWidth: 130,
    marginTop: theme.spacing(0.25),
    marginBottom: 0,
    '&:first-child': {
      marginTop: 0,
    },
  },
  line: {
    borderBottom: '1px solid #ccc',
  },
});

const BudgetYearOverviewBarChart = ({ data, currencyDetails, classes }) => {
  const monthTickFormatter = (tick) => {
    const date = new Date(tick);
    return date.getMonth() + 1;
  };

  const legendFormatter = (value) => I18n.t(`budget.${value}`);

  const renderRow = ({ name, value }) => (
    <p className={classes.row}>
      {`${I18n.t(`budget.${name}`)}: ${localeModelQueries.getFormattedAmount(
        value,
        currencyDetails
      )}`}
    </p>
  );

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const incomes = _.find(payload, { name: 'incomes' });
      const spending = _.find(payload, { name: 'spendings' });
      const savings = _.find(payload, { name: 'savings' });
      const balanceValue = budgetModulesQueries.getBalanceValue(
        incomes?.value,
        spending?.value
      );

      return (
        <div className={classes.tooltip}>
          <p className={classes.row}>
            <strong>{label}</strong>
          </p>
          {renderRow(incomes)}
          {renderRow(spending)}
          {renderRow({ value: balanceValue, name: 'net' })}
          {renderRow(savings)}
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer
      className={classes.root}
      width="100%"
      height="100%"
      minHeight={280}
    >
      <BarChart
        width={500}
        height={300}
        data={data}
        barGap={-8}
        margin={{
          top: 25,
          right: 23,
          left: 25,
          bottom: 35,
        }}
      >
        <CartesianGrid stroke="#e8e6fd" strokeDasharray="5" />
        <XAxis
          dataKey="date"
          stroke="#272727"
          tickFormatter={monthTickFormatter}
        />
        <XAxis
          dataKey="date"
          axisLine={false}
          tickLine={false}
          tick={false}
          height={1}
          interval={0}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend formatter={legendFormatter} />
        <Bar dataKey="spendings" fill={VARIANTS_COLOR['spendings']} />
        <Bar dataKey="incomes" fill={VARIANTS_COLOR['incomes']} />
        <Bar dataKey="savings" fill={VARIANTS_COLOR['savings']} />
        <YAxis unit={currencyDetails?.symbol_native} stroke="#272727" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default withStyles(styles)(BudgetYearOverviewBarChart);
