import { connect } from 'react-redux';
import * as authModelSelectors from 'models/auth/selectors';
import * as authModelActions from 'models/auth/actions';
import Signup from './Signup';

const mapStateToProps = () => {
  return (state) => {
    return {
      isAuthenticated: authModelSelectors.getIsAuthenticated(state),
    };
  };
};

const mapDispatchToProps = {
  onSignup: authModelActions.signup,
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
