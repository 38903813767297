import { connect } from 'react-redux';
import * as budgetModelSelectors from 'models/budget/selectors';
import * as budgetModelActions from 'models/budget/actions';
import BudgetRow from './BudgetRow';

const mapStateToProps = () => {
  return (state, props) => {
    const { rowId } = props;
    const row = budgetModelSelectors.getRowById(state, { rowId: rowId });

    return {
      row: row,
    };
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onUpdateCell(data, updateSum) {
    dispatch(
      budgetModelActions.triggerUpdateCell(ownProps.rowId, data, updateSum)
    );
  },
  onUpdateRow(data) {
    dispatch(budgetModelActions.updateRow(ownProps.rowId, data));
  },
  onDeleteRow() {
    dispatch(budgetModelActions.deleteRows([ownProps.rowId]));
  },
  onMoveRow(direction) {
    dispatch(budgetModelActions.triggerMoveRow(ownProps.rowId, direction));
  },
  onRowSelect(tableId, rowId) {
    dispatch(budgetModelActions.addSelectedRow(tableId, rowId));
  },
  onRowUnselect(tableId, rowId) {
    dispatch(budgetModelActions.removeSelectedRow(tableId, rowId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BudgetRow);
