import { connect } from 'react-redux';
import * as localeModelSelectors from 'models/locale/selectors';
import * as budgetModelSelectors from 'models/budget/selectors';
import * as authModelSelectors from 'models/auth/selectors';
import * as budgetModelActions from 'models/budget/actions';
import * as localeModelQueries from 'models/locale/queries';
import Budget from './Budget';

const mapStateToProps = () => {
  return (state, props) => {
    const id = props?.match?.params?.id;
    const activeBudgetId = budgetModelSelectors.getActiveBudgetId(state, {
      budgetId: id,
    });
    const activeBudget = budgetModelSelectors.getBudgetById(state, {
      budgetId: activeBudgetId,
    });
    const activeYearId = budgetModelSelectors.getActiveYearId(state, {
      budgetId: activeBudgetId,
    });
    const activeLocale = localeModelSelectors.getActiveLocale(state);
    const defaultCurrency =
      activeBudget?.currency ||
      localeModelQueries.getDefaultCurrency(activeLocale);

    return {
      budgetsList: budgetModelSelectors.getBudgetsList(state),
      budgetTemplates: budgetModelSelectors.getBudgetTemplates(state),
      tableTemplates: budgetModelSelectors.getTableTemplates(state),
      activeBudget: activeBudget,
      activeBudgetId: activeBudgetId,
      userDefaultCurrency: authModelSelectors.getUserProfileCurrency(state),
      defaultCurrency: defaultCurrency,
      activeYearId: activeYearId,
      activeYear: budgetModelSelectors.getYearById(state, {
        yearId: activeYearId,
      }),
      activeYearOverview: budgetModelSelectors.getYearOverview(state, {
        yearId: activeYearId,
      }),
      activeYearTableIds: budgetModelSelectors.getYearTableIds(state, {
        yearId: activeYearId,
      }),
      activeYearTableVariants: budgetModelSelectors.getYearTableVariants(
        state,
        {
          yearId: activeYearId,
        }
      ),
      activeBudgetYears: budgetModelSelectors.getBudgetYears(state, {
        budgetId: activeBudgetId,
      }),
      headerCells: budgetModelSelectors.getHeaderCells(state),
      isSidebarVisible:
        budgetModelSelectors.getPersistedIsSidebarVisible(state),
      expandedSummaryAccordions:
        budgetModelSelectors.getPersistedExpandedSummaryAccordions(state),
    };
  };
};

const mapDispatchToProps = {
  fetchBudgets: budgetModelActions.fetchBudgets,
  fetchBudgetTemplates: budgetModelActions.fetchBudgetTemplates,
  fetchTableTemplates: budgetModelActions.fetchTableTemplates,
  triggerFetchYear: budgetModelActions.triggerFetchYear,
  triggerSetActiveBudgetId: budgetModelActions.triggerSetActiveBudgetId,
  setActiveYearId: budgetModelActions.setActiveYearId,
  setIsSidebarVisible: budgetModelActions.setIsSidebarVisible,
  setExpandedSummaryAccordions: budgetModelActions.setExpandedSummaryAccordions,
  onAddBudget: budgetModelActions.addBudget,
  onAddTable: budgetModelActions.addTable,
  onAddYear: budgetModelActions.addYear,
  onUpdateBudget: budgetModelActions.updateBudget,
  onDeleteBudget: budgetModelActions.deleteBudget,
  onDeleteYear: budgetModelActions.deleteYear,
};

export default connect(mapStateToProps, mapDispatchToProps)(Budget);
