import _ from 'lodash';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'react-redux-i18n';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import debug from 'utils/debug';
import BudgetRow from 'modules/budget/budgetRow/';
import BudgetTableHeaderRow from 'modules/budget/budgetTable/budgetTableHeaderRow/BudgetTableHeaderRow';
import BudgetRowSummary from 'modules/budget/budgetRow/budgetRowSummary/BudgetRowSummary';
import BudgetTableHeader from 'modules/budget/budgetTable/budgetTableHeader/BudgetTableHeader';
import BudgetRowGroupActionsMenu from 'modules/budget/budgetRow/budgetRowGroupActionsMenu/BudgetRowGroupActionsMenu';
import * as localeModelQueries from 'models/locale/queries';
import { getBudgetVariantTranslationKey } from 'modules/budget/utils';
import * as queries from './queries';

const DEBUG_PATH = 'modules/budget/BudgetTable';

const styles = (theme) => ({
  table: {
    minWidth: 650,
  },
  paper: {
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(2),
  },
  button: {
    paddingRight: theme.spacing(2),
  },
  buttonIcon: {
    marginTop: -1,
  },
});

const BudgetTable = ({
  budgetTable,
  headerCells,
  budgetTableRowIds,
  budgetCategories,
  isNew = false,
  onAddRow,
  onSetIsRowSelectionEnabled,
  onDeleteRows,
  onMoveTable,
  onDeleteTable,
  onUpdateTable,
  onUpdateRows,
  classes,
}) => {
  debug(DEBUG_PATH, 'render');

  const [rowIds, setRowIds] = useState([]);
  const [newRowIds, setNewRowIds] = useState([]);

  const {
    _id,
    currency,
    name,
    variant,
    type,
    colsSum,
    isRowSelectionEnabled,
    selectedRows,
  } = budgetTable;

  const budgetVariantTranslationKey =
    getBudgetVariantTranslationKey(variant) || '';

  useEffect(() => {
    if (rowIds.length && rowIds !== budgetTableRowIds) {
      setNewRowIds(
        _.concat(newRowIds, _.differenceBy(budgetTableRowIds, rowIds))
      );
    }
    setRowIds(budgetTableRowIds);
  }, [rowIds, newRowIds, budgetTableRowIds]);

  const defaultRowCells = useMemo(
    () => queries.getDefaultRowCells(type),
    [type]
  );

  const summaryCells = useMemo(
    () => queries.getSummaryCells(defaultRowCells, colsSum),
    [colsSum, defaultRowCells]
  );

  const currencyDetails = useMemo(
    () => localeModelQueries.getCurrencyDetails(currency),
    [currency]
  );

  const currencySettings = useMemo(() => {
    return localeModelQueries.getCurrencySettings({ currencyDetails });
  }, [currencyDetails]);

  const handleAddRow = useCallback(() => {
    onAddRow();
  }, [onAddRow]);

  return (
    <Paper className={classes.paper} key={_id} data-test-id="budget-table">
      <BudgetTableHeader
        name={name}
        variant={variant}
        currency={currency}
        onMoveTable={onMoveTable}
        onDeleteTable={onDeleteTable}
        onUpdateTable={onUpdateTable}
      />
      <TableContainer>
        <Table className={classes.table} size="small" aria-label="Budget table">
          <BudgetTableHeaderRow
            headerCells={headerCells}
            isRowSelectionEnabled={isRowSelectionEnabled}
            onSetIsRowSelectionEnabled={onSetIsRowSelectionEnabled}
          />
          <TableBody>
            {_.map(budgetTableRowIds, (rowId) => (
              <BudgetRow
                key={rowId}
                tableCurrency={currency}
                tableType={type}
                rowId={rowId}
                defaultRowCells={defaultRowCells}
                currencySettings={currencySettings}
                budgetCategories={budgetCategories}
                isNew={
                  _.includes(newRowIds, rowId) ||
                  (isNew && budgetTableRowIds.length === 1)
                }
                isRowSelectionEnabled={isRowSelectionEnabled}
              />
            ))}
            <BudgetRowSummary
              cells={summaryCells}
              currencySettings={currencySettings}
            />
          </TableBody>
        </Table>
      </TableContainer>
      <Grid
        className={classes.footer}
        container
        justifyContent="space-between"
        direction="row"
        alignItems="center"
      >
        <Grid item>
          <Button
            className={classes.button}
            variant="outlined"
            color="primary"
            size="small"
            aria-label="add budget row"
            onClick={handleAddRow}
            startIcon={
              <AddIcon className={classes.buttonIcon} fontSize="small" />
            }
          >
            <Translate value={budgetVariantTranslationKey} />
          </Button>
        </Grid>
        {isRowSelectionEnabled && (
          <Grid className={classes.groupActions} item>
            <BudgetRowGroupActionsMenu
              selectedRows={selectedRows}
              onDeleteRows={onDeleteRows}
              onUpdateRows={onUpdateRows}
            />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default withStyles(styles)(BudgetTable);
