import { connect } from 'react-redux';
import * as localeModelActions from 'models/locale/actions';
import * as localeModelSelectors from 'models/locale/selectors';
import MenuLocale from './MenuLocale';

const mapStateToProps = () => {
  return (state) => {
    return {
      activeLocale: localeModelSelectors.getActiveLocale(state),
    };
  };
};

const mapDispatchToProps = {
  onLocaleChange: localeModelActions.changeLocale,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuLocale);
