import _ from 'lodash';
import { createSelector } from 'reselect';
import * as userSelectors from 'models/auth/selectors';
import { I18n } from 'react-redux-i18n';
import { getCellKeys } from 'modules/budget/budgetTable/queries';
import { getBudgetVariantTranslationKey } from 'modules/budget/utils';
import {
  ACTIVE_BUDGET_ID_KEY,
  ACTIVE_YEAR_ID_KEY,
  HEADER_CELLS_NAME_FORMAT_KEY,
  IS_SIDEBAR_VISIBLE_KEY,
  EXPANDED_SUMMARY_ACCORDIONS_KEY,
  HEADER_CELLS_NAME_FORMAT_FULL,
} from './constants';
import { MONTHS, MONTHS_SHORT } from 'constants.js';

// Budget
export const getBudgets = (state) => state?.budgets;

export const getBudgetsList = createSelector(
  [getBudgets],
  (budgets) => budgets?.list
);

export const getBudgetsYears = createSelector(
  [getBudgets],
  (budgets) => budgets?.years
);

export const getBudgetsTables = createSelector(
  [getBudgets],
  (budgets) => budgets?.tables
);

export const getBudgetsRows = createSelector(
  [getBudgets],
  (budgets) => budgets?.rows
);

export const getBudgetTemplates = createSelector(
  [getBudgets],
  (budgets) => budgets?.budgetTemplates
);

export const getTableTemplates = createSelector(
  [getBudgets],
  (budgets) => budgets?.tableTemplates
);

export const getBudgetById = createSelector(
  [(_, props) => props.budgetId, getBudgetsList],
  (budgetId, budgetsList) => budgetsList?.[budgetId]
);

export const getBudgetUserSettings = createSelector(
  [userSelectors.getUserSettingsComponents],
  (components) => components?.budget
);

export const getBudgetYears = createSelector(
  [getBudgetById],
  (budget) => budget?._years
);

export const getPersistedBudgetId = createSelector(
  [getBudgetUserSettings],
  (budgetUserSettings) => budgetUserSettings?.[ACTIVE_BUDGET_ID_KEY]
);

export const getPersistedBudget = createSelector(
  [getPersistedBudgetId, getBudgetsList],
  (persistedBudgetId, budgetsList) => budgetsList?.[persistedBudgetId]
);

export const getActiveBudgetId = createSelector(
  [
    (_, props) => props.budgetId,
    getBudgetById,
    getPersistedBudgetId,
    getPersistedBudget,
    getBudgetsList,
  ],
  (budgetId, budget, persistedBudgetId, persistedBudget, budgetsList) => {
    if (budget) {
      return budgetId;
    }

    if (persistedBudget) {
      return persistedBudgetId;
    }

    return _.keys(budgetsList)[0];
  }
);

// Row
export const getRowById = createSelector(
  [(_, props) => props.rowId, getBudgetsRows],
  (rowId, budgetsRows) => budgetsRows?.[rowId]
);

export const getRowCell = createSelector(
  [(_, props) => props.value, getRowById],
  (value, budgetRow) => budgetRow?._cells[value]
);

// Table
export const getTableById = createSelector(
  [(_, props) => props.tableId, getBudgetsTables],
  (tableId, budgetsTables) => budgetsTables?.[tableId]
);

export const getTableRowIds = createSelector(
  [getTableById],
  (table) => table?._rows
);

export const getPersistedHeaderCellsNameFormat = createSelector(
  [getBudgetUserSettings],
  (budgetUserSettings) => budgetUserSettings?.[HEADER_CELLS_NAME_FORMAT_KEY]
);

export const getPersistedIsSidebarVisible = createSelector(
  [getBudgetUserSettings],
  (budgetUserSettings) => budgetUserSettings?.[IS_SIDEBAR_VISIBLE_KEY] ?? true
);

export const getPersistedExpandedSummaryAccordions = createSelector(
  [getBudgetUserSettings],
  (budgetUserSettings) =>
    budgetUserSettings?.[EXPANDED_SUMMARY_ACCORDIONS_KEY] ?? []
);

export const getHeaderCells = createSelector(
  [getPersistedHeaderCellsNameFormat],
  (nameFormat) => {
    if (nameFormat === HEADER_CELLS_NAME_FORMAT_FULL) {
      return MONTHS;
    }

    return MONTHS_SHORT;
  }
);

// Year
export const getYearById = createSelector(
  [(_, props) => props.yearId, getBudgetsYears],
  (yearId, budgetsYears) => budgetsYears?.[yearId]
);

export const getYearOverview = createSelector(
  [getYearById],
  (year) => year?.overview
);

export const getPersistedYearId = createSelector(
  [getBudgetUserSettings],
  (budgetUserSettings) => budgetUserSettings?.[ACTIVE_YEAR_ID_KEY]
);

export const getPersistedYear = createSelector(
  [getPersistedYearId, getBudgetYears],
  (persistedYearId, budgetYears) =>
    _.find(budgetYears, { _id: persistedYearId })
);

export const getActiveYearId = createSelector(
  [getPersistedYearId, getPersistedYear, getBudgetYears],
  (persistedYearId, persistedYear, budgetYearsList) => {
    if (persistedYear) {
      return persistedYearId;
    }

    return _.head(budgetYearsList)?._id;
  }
);

export const getYearTableIds = createSelector(
  [getYearById],
  (year) => year?._tables
);

export const getYearTableVariants = createSelector(
  [getYearTableIds, getBudgetsTables],
  (tableIds, budgetTables) =>
    _.reduce(
      tableIds,
      (tableVariants, tableId) => {
        const tableVariant = budgetTables[tableId].variant;
        if (_.indexOf(tableVariants, tableVariant) === -1) {
          return [...tableVariants, tableVariant];
        }
        return tableVariants;
      },
      []
    )
);

// Export
export const getExportDataByTableIds = (state, tableIds) =>
  _.reduce(
    tableIds,
    (result, tableId) => {
      const table = getTableById(state, { tableId });
      const { _rows, name, variant, currency, type } = table;
      const budgetVariantTranslationKey =
        getBudgetVariantTranslationKey(variant);
      const cellKeys = getCellKeys(type);

      const rowValues = _.reduce(
        _rows,
        (acc, rowId) => {
          const row = getRowById(state, { rowId });
          const { name, _cells } = row;

          const cellValues = _.reduce(
            cellKeys,
            (acc, key) => {
              return {
                ...acc,
                [key]:
                  (_cells?.[key]?.isComplete && _cells?.[key]?.amount) || 0,
              };
            },
            {}
          );

          return [
            ...acc,
            {
              row_name: name,
              ...cellValues,
            },
          ];
        },
        []
      );

      return [
        ...result,
        {
          table_name: name,
          table_variant: I18n.t(budgetVariantTranslationKey),
          table_currency: currency,
          row_name: null,
        },
        ...rowValues,
        {},
      ];
    },
    []
  );
