import _ from 'lodash';
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import BudgetTemplateSelector from 'components/budgetTemplateSelector/BudgetTemplateSelector';
import globalStyles from 'globalStyles.js';

const DEFAULT_YEAR = new Date().getFullYear();
const INPUT_MAX = 9999;
const INPUT_MIN = 0;

const styles = (theme) => ({
  dialogContent: {
    [theme.breakpoints.up('sm')]: {
      minWidth: 400,
    },
  },
  dialogActions: {
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  templateSelector: {
    marginTop: theme.spacing(1),
  },
});

const BudgetYearDetailsDialog = ({
  activeBudgetYears,
  currency,
  confirmButtonText,
  isOpen,
  setIsOpen,
  onConfirm,
  classes,
}) => {
  const globalClasses = globalStyles();

  const defaultYear = useMemo(() => {
    const getDefaultYear = (budgetYear) => {
      if (
        _.some(activeBudgetYears, ({ year }) => year === _.toString(budgetYear))
      ) {
        return getDefaultYear(budgetYear + 1);
      }
      return budgetYear;
    };

    return getDefaultYear(DEFAULT_YEAR);
  }, [activeBudgetYears]);

  const templateYears = useMemo(() => {
    return _.map(activeBudgetYears, (year) => {
      return {
        id: year._id,
        name: year.year,
      };
    });
  }, [activeBudgetYears]);

  const [yearValue, setYearValue] = useState(defaultYear);
  const [isNotValid, setIsNotValid] = useState(false);
  const [templateYearValue, setTemplateYearValue] = useState('');

  useEffect(() => {
    setTemplateYearValue('');
  }, [isOpen]);

  useEffect(() => {
    setYearValue(defaultYear);
  }, [isOpen, defaultYear]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handleConfirm = useCallback(() => {
    if (
      _.some(activeBudgetYears, ({ year }) => year === _.toString(yearValue))
    ) {
      setIsNotValid(true);
      return;
    }

    onConfirm({
      year: yearValue,
      templateYearId: templateYearValue,
      currency: currency,
    });
    setIsOpen(false);
  }, [
    yearValue,
    templateYearValue,
    currency,
    activeBudgetYears,
    setIsOpen,
    onConfirm,
  ]);

  const handleYearChange = (event) => {
    let value = event.target.value;

    if (isNotValid) {
      setIsNotValid(false);
    }

    const validatedValue =
      value < INPUT_MIN ? INPUT_MIN : value > INPUT_MAX ? INPUT_MAX : value;

    setYearValue(validatedValue);
  };

  const handleTemplateYearValueChange = useCallback(
    (event) => {
      setTemplateYearValue(event.target.value);
    },
    [setTemplateYearValue]
  );

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      aria-labelledby="edit-budget-year-dialog-description"
      data-test-id="budget-year-details-dialog"
    >
      <DialogTitle id="edit-budget-year-dialog-description">
        <Translate value="budget.year_details" />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <TextField
          type="number"
          value={yearValue}
          label={
            isNotValid ? I18n.t('budget.year_exists') : I18n.t('budget.year')
          }
          error={isNotValid}
          onChange={handleYearChange}
          margin="dense"
          variant="outlined"
          fullWidth
          inputProps={{
            maxLength: 4,
            min: INPUT_MIN,
            max: INPUT_MAX,
            step: 1,
          }}
        />
        <div className={classes.templateSelector}>
          <BudgetTemplateSelector
            label={I18n.t('budget.year_copy_year_tables')}
            templates={templateYears}
            templateValue={templateYearValue}
            handleTemplateValueChange={handleTemplateYearValueChange}
          />
        </div>
      </DialogContent>
      <DialogActions className={globalClasses.dialogActions}>
        <Button onClick={handleClose} size="medium">
          <Translate value="common.cancel" />
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          variant="contained"
          size="medium"
          autoFocus
        >
          {confirmButtonText || <Translate value="common.confirm" />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(BudgetYearDetailsDialog);
