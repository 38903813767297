import _ from 'lodash';
import React from 'react';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { Translate } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import validatePassword from 'utils/validatePassword';
import validatePasswordConfirm from 'utils/validatePasswordConfirm';
import FormField from 'components/formField/FormField';
import { FORM_FIELDS } from 'modules/account/updatePassword/constants';

const UPDATE_PASSWORD_FORM = 'updatePasswordForm';

const styles = (theme) => ({
  button: {
    marginTop: theme.spacing(3),
  },
});

const UpdatePasswordForm = ({
  onUpdateUserPassword,
  handleSubmit,
  pristine,
  submitting,
  classes,
  reset,
}) => {
  const onSubmit = ({ currentPassword, newPassword }) => {
    onUpdateUserPassword({ currentPassword, newPassword }, () => {
      reset(UPDATE_PASSWORD_FORM);
    });
  };

  const renderFields = () => {
    return _.map(FORM_FIELDS, (field) => {
      return <Field key={field.name} component={FormField} {...field} />;
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {renderFields()}
      <Button
        type="submit"
        className={classes.button}
        disabled={pristine || submitting}
        variant="contained"
        color="primary"
        fullWidth
      >
        <Translate value="common.save" />
      </Button>
    </form>
  );
};

function validate({ newPassword, newPasswordConfirm }) {
  const errors = {};

  errors.newPassword = validatePassword(newPassword);
  errors.newPasswordConfirm = validatePasswordConfirm(
    newPasswordConfirm,
    newPassword
  );

  return errors;
}

export default compose(
  reduxForm({ validate, form: UPDATE_PASSWORD_FORM, touchOnBlur: false }),
  withStyles(styles)
)(UpdatePasswordForm);
