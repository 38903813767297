import _ from 'lodash';
import React from 'react';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { Translate } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import validateEmail from 'utils/validateEmail';
import validatePassword from 'utils/validatePassword';
import validatePasswordConfirm from 'utils/validatePasswordConfirm';
import FormField from 'components/formField/FormField';
import { FORM_FIELDS } from 'modules/signup/constants';
import { BUDGET_CREATE_PATH } from 'constants.js';

const SIGNUP_FORM_NAME = 'signupForm';

const styles = (theme) => ({
  button: {
    marginTop: theme.spacing(3),
  },
});

const SignupForm = ({
  onSignup,
  classes,
  handleSubmit,
  submitting,
  history,
}) => {
  const onSubmit = ({ email, password }) => {
    onSignup({ email, password }, () => {
      history.push(BUDGET_CREATE_PATH);
    });
  };

  const renderFields = () => {
    return _.map(FORM_FIELDS, (field) => {
      return <Field key={field.name} component={FormField} {...field} />;
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {renderFields()}
        <Button
          type="submit"
          className={classes.button}
          disabled={submitting}
          variant="contained"
          color="primary"
          fullWidth
        >
          <Translate value="auth.sign_up" />
        </Button>
      </form>
    </div>
  );
};

function validate({ email, password, passwordConfirm }) {
  const errors = {};

  errors.email = validateEmail(email);
  errors.password = validatePassword(password);
  errors.passwordConfirm = validatePasswordConfirm(passwordConfirm, password);

  return errors;
}

export default compose(
  reduxForm({ validate, form: SIGNUP_FORM_NAME, touchOnBlur: false }),
  withStyles(styles),
  withRouter
)(SignupForm);
