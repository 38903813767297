import _ from 'lodash';
import React, { useCallback } from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = (theme) => ({
  accordionWrapper: {
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      height: 74,
    },
  },
  accordion: {
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      left: 0,
      right: 0,
      zIndex: 3,
    },
  },
  accordionSummary: {
    alignItems: 'flex-start',
  },
  accordionDetails: {
    padding: 0,
    '&#panel-summary': {
      display: 'block',
    },
  },
});

const BudgetYearOverviewAccordion = ({
  variant,
  accordionSummary,
  accordionDetails,
  isVertical,
  expandedSummaryAccordions,
  setExpandedSummaryAccordions,
  classes,
}) => {
  const handleChange = useCallback(
    (variant, expanded) => {
      if (!isVertical) {
        return;
      }

      const expandedAccordions = expanded
        ? _.union(expandedSummaryAccordions, [variant])
        : _.without(expandedSummaryAccordions, variant);

      setExpandedSummaryAccordions(expandedAccordions);
    },
    [isVertical, expandedSummaryAccordions, setExpandedSummaryAccordions]
  );

  const accordionWrapperClass = classNames(
    isVertical ? null : classes.accordionWrapper
  );

  const accordionClass = classNames(isVertical ? null : classes.accordion);

  return (
    <div className={accordionWrapperClass}>
      <Accordion
        className={accordionClass}
        defaultExpanded={
          isVertical && _.includes(expandedSummaryAccordions, variant)
        }
        onChange={(event, expanded) => handleChange(variant, expanded)}
      >
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-${variant}`}
          id={`panel-header-${variant}`}
        >
          <div>{accordionSummary}</div>
        </AccordionSummary>
        <AccordionDetails
          className={classes.accordionDetails}
          id={`panel-${variant}`}
        >
          {accordionDetails}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default withStyles(styles)(BudgetYearOverviewAccordion);
