import React, { useEffect, useCallback } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import validateEmail from 'utils/validateEmail';
import FormField from 'components/formField/FormField';

const UPDATE_EMAIL_FORM = 'updateEmailForm';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(3),
  },
});

const UpdateEmailForm = ({
  userEmail,
  onUpdateUserEmail,
  // reduxForm props:
  handleSubmit,
  pristine,
  submitting,
  classes,
  initialize,
}) => {
  const initializeEmail = useCallback(
    (email) => {
      initialize({ email });
    },
    [initialize]
  );

  useEffect(() => initializeEmail(userEmail), [initializeEmail, userEmail]);

  const onSubmit = ({ email }) => {
    onUpdateUserEmail({ email });
  };

  return (
    <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
      <Field
        key="email"
        component={FormField}
        type="text"
        name="email"
        icon="email"
        placeholder={I18n.t('account.enter_your_email')}
      />
      <Button
        type="submit"
        className={classes.button}
        disabled={pristine || submitting}
        variant="contained"
        color="primary"
        fullWidth
      >
        <Translate value="common.save" />
      </Button>
    </form>
  );
};

function validate({ email }) {
  const errors = {};

  errors.email = validateEmail(email);

  return errors;
}

export default compose(
  reduxForm({ validate, form: UPDATE_EMAIL_FORM }),
  withStyles(styles)
)(UpdateEmailForm);
