import { I18n } from 'react-redux-i18n';

export default (passwordConfirm, password) => {
  if (!passwordConfirm) {
    return I18n.t('auth.enter_password_confirmation');
  }

  if (password !== passwordConfirm) {
    return I18n.t('auth.password_must_match');
  }

  return;
};
