import _ from 'lodash';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import NumberFormat from 'react-number-format';
import debug from 'utils/debug';
import BudgetRowDetails from 'modules/budget/budgetRow/budgetRowDetails/BudgetRowDetails';
import BudgetCell from 'modules/budget/budgetCell/BudgetCell';
import * as queries from './queries';
import globalStyles from 'globalStyles.js';

const DEBUG_PATH = 'modules/budget/BudgetRow';
const COLUMNS_NUMBER = 15;

const styles = (theme) => ({
  row: {
    '& > *': {
      borderBottom: 'unset',
    },
    '&:nth-child(4n+1)': {
      backgroundColor: theme.custom.color.primaryLighter,
    },
    '& *': {
      color: 'inherit',
    },
  },
  detailsRow: {
    '&:nth-child(4n+2)': {
      backgroundColor: theme.custom.color.primaryLighter,
    },
  },
  expandCell: {
    paddingRight: 0,
  },
  headerCell: {
    width: '15%',
    minWidth: 180,
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: theme.spacing(2),
    lineHeight: 1.2,
    fontWeight: 500,
  },
  detailsCell: {
    paddingBottom: 0,
    paddingTop: 0,
    borderColor: theme.custom.color.primaryLighter,
  },
  sumCell: {
    minWidth: 100,
    fontWeight: 500,
    '&:last-child': {
      paddingRight: theme.custom.budget.lastCellPaddingRight,
    },
  },
  badge: {
    '& > span': {
      backgroundColor: 'currentColor',
      opacity: 0.3,
      top: -2,
      right: -5,
    },
  },
  checkbox: {
    color: '#0000008a',
    padding: 5,
  },
});

const BudgetRow = ({
  currencySettings,
  row = {},
  budgetCategories,
  defaultRowCells,
  isNew,
  isRowSelectionEnabled,
  onUpdateCell,
  onUpdateRow,
  onDeleteRow,
  onMoveRow,
  onRowSelect,
  onRowUnselect,
  classes,
}) => {
  debug(DEBUG_PATH, 'render');
  const globalClasses = globalStyles();

  const {
    name,
    note,
    defaultAmount,
    rowSum,
    textColor,
    backgroundColor,
    _cells,
    _id,
    _table,
  } = row;

  const [open, setOpen] = useState(false);
  const [isNewlyCreated, setIsNewlyCreated] = useState(false);

  const sumCellClass = classNames(
    globalClasses.budgetTableCell,
    classes.sumCell
  );

  useEffect(() => {
    if (isNew) {
      setIsNewlyCreated(true);
      setOpen(true);
    }
  }, [isNew, setOpen]);

  useEffect(() => {
    setIsNewlyCreated(false);
  }, [isNewlyCreated, setIsNewlyCreated]);

  const handleDetailsToggle = useCallback(() => {
    setOpen(!open);
  }, [open, setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleCheckboxChange = useCallback(
    (event) => {
      if (event.target.checked) {
        onRowSelect(_table, _id);
      } else {
        onRowUnselect(_table, _id);
      }
    },
    [_table, _id, onRowSelect, onRowUnselect]
  );

  const memoizedCells = useMemo(() => _cells, [_cells]);

  const rowCells = useMemo(
    () => queries.getRowCells(defaultRowCells, memoizedCells),
    [defaultRowCells, memoizedCells]
  );

  if (!row) {
    return;
  }

  return (
    <>
      <TableRow
        className={classes.row}
        style={{
          backgroundColor: backgroundColor,
          color: textColor,
          borderColor: backgroundColor,
        }}
      >
        <TableCell className={classes.expandCell}>
          {isRowSelectionEnabled ? (
            <Checkbox
              className={classes.checkbox}
              onChange={handleCheckboxChange}
              color="primary"
              size="small"
            />
          ) : (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={handleDetailsToggle}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell className={classes.headerCell} component="th" scope="row">
          {note ? (
            <Tooltip placement="top" title={note}>
              <Badge className={classes.badge} variant="dot">
                {name}
              </Badge>
            </Tooltip>
          ) : (
            name
          )}
        </TableCell>
        {_.map(rowCells, (cell) => (
          <BudgetCell
            key={cell.value}
            cell={cell}
            currencySettings={currencySettings}
            defaultAmount={defaultAmount}
            onUpdateCell={onUpdateCell}
          />
        ))}
        <TableCell className={sumCellClass} align="right">
          <NumberFormat
            value={rowSum}
            displayType="text"
            {...currencySettings}
          />
        </TableCell>
      </TableRow>
      <TableRow className={classes.detailsRow}>
        <TableCell className={classes.detailsCell} colSpan={COLUMNS_NUMBER}>
          <Collapse in={open} timeout={250} mountOnEnter unmountOnExit>
            <BudgetRowDetails
              closeDetails={handleClose}
              onUpdateRow={onUpdateRow}
              onDeleteRow={onDeleteRow}
              onMoveRow={onMoveRow}
              currencySettings={currencySettings}
              budgetCategories={budgetCategories}
              row={row}
              isNew={isNewlyCreated}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default withStyles(styles)(BudgetRow);
