import React from 'react';
import { useHistory } from 'react-router-dom';
import { Translate } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { SUBSCRIPTION_PLANS_PATH } from 'constants.js';

const styles = {
  button: {
    borderRadius: '2rem',
    padding: '7px 23px',
    backgroundColor: '#b658ed',
    boxShadow: 'none',

    '&:hover': {
      backgroundColor: '#b658ed',
      boxShadow: 'none',
    },
  },
};

const SubscriptionButton = ({ classes }) => {
  let history = useHistory();

  const handleClick = () => {
    history.push(SUBSCRIPTION_PLANS_PATH);
  };

  return (
    <Button
      className={classes.button}
      onClick={handleClick}
      variant="contained"
      color="secondary"
    >
      <Translate value="subscription.upgrade" />
    </Button>
  );
};

export default withStyles(styles)(SubscriptionButton);
