import _ from 'lodash';

export default (object) =>
  _.reduce(
    object,
    (result, value, key) => {
      result.push({ key: key, value: value });
      return result;
    },
    []
  );
