import React from 'react';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { PASSWORD_MIN_LENGTH } from 'constants.js';

export const FORM_FIELDS = [
  {
    label: 'auth.email',
    name: 'email',
    type: 'text',
  },
  {
    label: 'auth.password',
    name: 'password',
    type: 'password',
    endAdornmentIcon: <HelpOutlineIcon fontSize="small" color="disabled" />,
    endAdornmentTooltip: {
      translationKey: 'auth.password_conditions',
      translationValue: {
        number: PASSWORD_MIN_LENGTH
      }
    },
  },
  {
    label: 'auth.confirm_password',
    name: 'passwordConfirm',
    type: 'password',
  },
];
