import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { Translate } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import validateEmail from 'utils/validateEmail';
import validatePassword from 'utils/validatePassword';
import validatePasswordConfirm from 'utils/validatePasswordConfirm';
import FormField from 'components/formField/FormField';
import { FORM_FIELDS } from 'modules/resetPassword/constants';
import { ACCOUNT_PATH } from 'constants.js';

const RESET_PASSWORD_FORM_NAME = 'resetPasswordForm';

const styles = (theme) => ({
  button: {
    marginTop: theme.spacing(3),
  },
});

const ResetPasswordForm = ({
  onResetPassword,
  classes,
  match,
  history,
  handleSubmit,
  submitting,
}) => {
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    setToken(match?.params?.token);
    setEmail(match?.params?.email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = ({ password }) => {
    onResetPassword({ password, token, email }, () => {
      history.push(ACCOUNT_PATH);
    });
  };

  const renderFields = () => {
    return _.map(FORM_FIELDS, (field) => {
      return (
        <Field
          key={field.name}
          component={FormField}
          {...field}
        />
      );
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {renderFields()}
      <Button
        type="submit"
        className={classes.button}
        disabled={submitting}
        variant="contained"
        color="secondary"
        fullWidth
      >
        <Translate value="auth.reset_my_password" />
      </Button>
    </form>
  );
};

function validate({ email, password, passwordConfirm }) {
  const errors = {};

  errors.email = validateEmail(email);
  errors.password = validatePassword(password);
  errors.passwordConfirm = validatePasswordConfirm(passwordConfirm, password)

  return errors;
}

export default compose(
  reduxForm({ validate, form: RESET_PASSWORD_FORM_NAME, touchOnBlur: false }),
  withStyles(styles),
  withRouter
)(ResetPasswordForm);
