import React from 'react';
import { Translate } from 'react-redux-i18n';
import Typography from '@material-ui/core/Typography';
import UpdateEmailForm from './updateEmailForm/UpdateEmailForm';

const UpdateEmail = ({ userEmail, onUpdateUserEmail }) => {
  return (
    <div>
      <Typography variant="h6" gutterBottom>
        <Translate value="account.email" />
      </Typography>
      <Typography gutterBottom>
        <Translate value="account.your_email_is_identity" />
      </Typography>
      <UpdateEmailForm
        userEmail={userEmail}
        onUpdateUserEmail={onUpdateUserEmail}
      />
    </div>
  );
};

export default UpdateEmail;
