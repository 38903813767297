import _ from 'lodash';
import React, { useEffect } from 'react';
import { compose } from 'redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import Grid from '@material-ui/core/Grid';
import SnackbarContent from '@material-ui/core/SnackbarContent';

const variantIcon = {
  success: CheckCircleIcon,
  error: ErrorIcon,
};

const styles = (theme) => ({
  snackbarWrapper: {
    position: 'absolute',
    zIndex: 999,
  },
  snackbar: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  success: {
    backgroundColor: theme.custom.color.success,
  },
  warning: {
    backgroundColor: theme.custom.color.warning,
  },
  info: {
    backgroundColor: theme.custom.color.info,
  },
  error: {
    backgroundColor: theme.custom.color.error,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(),
  },
});

const Message = ({ messages, onClearMessages, classes }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onClearMessages(), []);

  const renderMessages = () => {
    return _.map(messages, ({ messageType, value }, index) => {
      const Icon = variantIcon[messageType];

      return (
        <SnackbarContent
          key={index}
          className={classNames(classes.snackbar, classes[messageType])}
          message={
            <div className={classes.message}>
              {Icon && (
                <Icon
                  className={classNames(classes.icon, classes.iconVariant)}
                />
              )}
              {value}
            </div>
          }
        />
      );
    });
  };

  if (!messages.length) {
    return null;
  }

  return (
    <Grid container justifyContent="center">
      <Grid className={classes.snackbarWrapper} item>
        {renderMessages()}
      </Grid>
    </Grid>
  );
};

export default compose(withStyles(styles))(Message);
