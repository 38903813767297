import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'react-redux-i18n';
import Typography from '@material-ui/core/Typography';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const MORE_BUTTON_COLOR = '#202020';

const styles = () => ({
  moreButton: {
    marginTop: 8,
    display: 'inline-block',
    color: MORE_BUTTON_COLOR,
    fontSize: 14,
    cursor: 'pointer',
    userSelect: 'none',
  },
  moreButtonIcon: {
    position: 'relative',
    top: 7,
  },
});

const ShowMoreButton = ({ isOpen, onClick, classes }) => {
  return (
    <Typography
      className={classes.moreButton}
      variant="body1"
      display="inline"
      onClick={onClick}
    >
      <Translate value="budget.row_more_options" />
      {isOpen ? (
        <ArrowDropUpIcon className={classes.moreButtonIcon} />
      ) : (
        <ArrowDropDownIcon className={classes.moreButtonIcon} />
      )}
    </Typography>
  );
};

export default withStyles(styles)(ShowMoreButton);
