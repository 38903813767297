import React from 'react';
import { Translate } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Footer from 'components/footer/Footer';
import ProfileForm from './profileForm/ProfileForm';
import globalStyles from 'globalStyles.js';

const styles = (theme) => ({
  root: {
    padding: theme.custom.wrapper.padding,
    marginBottom: theme.custom.global.marginBottom,
    minHeight: theme.custom.global.minHeightWithFooter,
  },
  cardContent: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },
});

const Profile = ({ classes }) => {
  const globalClasses = globalStyles();

  return (
    <>
      <div className={classes.root} data-test-id="profile">
        <Grid container justifyContent="center">
          <Grid item className={globalClasses.containerSm}>
            <Typography className={globalClasses.pageTitle} variant="h2">
              <Translate value="app.profile" />
            </Typography>
            <Grid container direction="column" alignItems="stretch" spacing={4}>
              <Grid item>
                <Card>
                  <CardContent className={classes.cardContent}>
                    <ProfileForm />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </>
  );
};

export default withStyles(styles)(Profile);
