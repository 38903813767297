import React from 'react';
import { Translate } from 'react-redux-i18n';
import Typography from '@material-ui/core/Typography';
import UpdatePasswordForm from './updatePasswordForm/UpdatePasswordForm';

const UpdatePassword = ({ onUpdateUserPassword }) => {
  return (
    <div>
      <Typography variant="h6" gutterBottom>
        <Translate value="account.password" />
      </Typography>
      <Typography>
        <Translate value="account.enter_all_to_change_password" />
      </Typography>
      <UpdatePasswordForm onUpdateUserPassword={onUpdateUserPassword} />
    </div>
  );
};

export default UpdatePassword;
