import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Translate } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AuthExternalButtons from 'components/authExternalButtons/AuthExternalButtons';
import LineSeparator from 'components/lineSeparator/LineSeparator';
import ForgotPasswordForm from 'modules/signin/forgotPasswordForm/ForgotPasswordForm';
import SigninForm from './signinForm/SigninForm';
import { SIGNUP_PATH, BUDGET_PATH } from 'constants.js';
import globalStyles from 'globalStyles.js';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(4),
    padding: theme.custom.wrapper.padding,
  },
  button: {
    marginTop: theme.spacing(2),
  },
  forgotButton: {
    marginTop: theme.spacing(3),
  },
  form: {
    marginTop: 15,
  },
});

const Signin = ({ isAuthenticated, onSignin, onForgotPassword, classes }) => {
  let history = useHistory();

  const globalClasses = globalStyles();

  const [showResetForm, setShowResetForm] = useState(false);

  const handleClick = useCallback(() => {
    setShowResetForm(!showResetForm);
  }, [showResetForm, setShowResetForm]);

  useEffect(() => {
    if (isAuthenticated) {
      history.push(BUDGET_PATH);
    }
  }, [history, isAuthenticated]);

  if (isAuthenticated === null) {
    return null;
  }

  return (
    <div className={classes.root} data-test-id="signin">
      <Grid container justifyContent="center">
        <Grid item className={globalClasses.containerXs}>
          <Grid container spacing={4}>
            <Grid item xs>
              <Card className={classes.paper}>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    <Translate value="auth.sign_in" />
                  </Typography>
                  <SigninForm onSignin={onSignin} />
                  <LineSeparator />
                  <Button
                    component={Link}
                    to={SIGNUP_PATH}
                    className={classes.button}
                    variant="outlined"
                    fullWidth
                  >
                    <Translate value="auth.create_account" />
                  </Button>
                  <AuthExternalButtons />
                  <Button
                    onClick={handleClick}
                    className={classes.forgotButton}
                    size="small"
                    fullWidth
                  >
                    <Translate value="auth.forgot_password_q" />
                  </Button>
                  {showResetForm && (
                    <div className={classes.form}>
                      <ForgotPasswordForm onForgotPassword={onForgotPassword} />
                    </div>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Signin);
