import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'react-redux-i18n';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TwoFactorAuthenticationDialog from './twoFactorAuthenticationDialog/TwoFactorAuthenticationDialog';

const styles = (theme) => ({
  button: {
    marginTop: theme.spacing(3),
  },
});

const TwoFactorAuthentication = ({ is2FaEnabled, classes }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h6" gutterBottom>
            <Translate value="auth.two_factor_authentication" />
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            {is2FaEnabled ? (
              <>
                (<Translate value="auth.enabled_2fa" />)
              </>
            ) : (
              <>
                (<Translate value="auth.disabled_2fa" />)
              </>
            )}
          </Typography>
        </Grid>
      </Grid>
      <Typography gutterBottom>
        <Translate value="auth.two_factor_authentication_description" />
      </Typography>
      <Button
        className={classes.button}
        type="submit"
        variant="contained"
        onClick={handleDialogOpen}
        color="primary"
        fullWidth
      >
        <Translate value="auth.enable_2fa" />
      </Button>
      {isDialogOpen && (
        <TwoFactorAuthenticationDialog
          isOpen={isDialogOpen}
          onClose={handleDialogClose}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(TwoFactorAuthentication);
