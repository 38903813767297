import React, { useState, useEffect } from 'react';
import { I18n } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import * as langQueries from 'lang/queries';
import { COUNTRIES } from 'lang/countries';

const styles = () => ({});

const CountrySelector = ({
  selectedCountry,
  onCountryChange,
  inputLabel = I18n.t('common.country'),
  inputVariant = 'outlined',
}) => {
  const [countryValue, setCountryValue] = useState(selectedCountry || null);
  const [countryInputValue, setCountryInputValue] = useState(
    selectedCountry || ''
  );

  useEffect(() => {
    if (selectedCountry !== countryValue) {
      setCountryValue(selectedCountry || null);
      setCountryInputValue(selectedCountry || '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry]);

  const handleCountryChange = (event, newValue) => {
    const code = newValue?.code;
    onCountryChange(code || undefined);
    setCountryValue(code || null);
  };

  const handleCountryInputChange = (event, newValue) => {
    setCountryInputValue(newValue || '');
  };

  const countryName = langQueries.getCountryNameByCode(countryValue);

  return (
    <Autocomplete
      size="small"
      value={countryName || null}
      onChange={handleCountryChange}
      inputValue={countryInputValue}
      onInputChange={handleCountryInputChange}
      options={COUNTRIES}
      getOptionLabel={(option) => option?.name || option}
      getOptionSelected={(option) => (option?.code || option) === countryValue}
      renderOption={(option) => option?.name}
      renderInput={(params) => (
        <TextField
          {...params}
          label={inputLabel}
          variant={inputVariant || 'outlined'}
          margin="dense"
          fullWidth
          InputLabelProps={{ disableAnimation: Boolean(selectedCountry) }}
        />
      )}
    />
  );
};

export default withStyles(styles)(CountrySelector);
