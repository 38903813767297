import { connect } from 'react-redux';
import * as localeModelSelectors from 'models/locale/selectors';
import * as budgetModelSelectors from 'models/budget/selectors';
import * as budgetModelActions from 'models/budget/actions';
import BudgetTable from './BudgetTable';

const mapStateToProps = () => {
  return (state, props) => {
    const { tableId } = props;
    const budgetTable = budgetModelSelectors.getTableById(state, {
      tableId,
    });
    const { variant } = budgetTable;

    return {
      budgetTable: budgetTable,
      budgetTableRowIds: budgetModelSelectors.getTableRowIds(state, {
        tableId,
      }),
      budgetCategories: localeModelSelectors.getBudgetVariantCategories(state, {
        variant,
      }),
    };
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAddRow(data) {
    dispatch(budgetModelActions.addRow(ownProps.tableId, data));
  },
  onSetIsRowSelectionEnabled(value) {
    dispatch(
      budgetModelActions.setIsRowSelectionEnabled(ownProps.tableId, value)
    );
  },
  onDeleteRows(rowIds) {
    dispatch(budgetModelActions.deleteRows(rowIds));
  },
  onMoveTable(direction) {
    dispatch(budgetModelActions.triggerMoveTable(ownProps.tableId, direction));
  },
  onDeleteTable() {
    dispatch(budgetModelActions.deleteTable(ownProps.tableId));
  },
  onUpdateTable(data) {
    dispatch(budgetModelActions.updateTable(ownProps.tableId, data));
  },
  onUpdateRows(rowIds, data) {
    dispatch(budgetModelActions.updateRows(rowIds, data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BudgetTable);
