import React, { useState, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { I18n, Translate } from 'react-redux-i18n';
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import BudgetDetailsDialog from 'modules/budget/budgetDetailsDialog/BudgetDetailsDialog';
import BudgetExportDialog from 'modules/budget/budgetExportDialog/BudgetExportDialog';
import ConfirmDialog from 'components/confirmDialog/ConfirmDialog';

const styles = (theme) => ({
  noBackground: {
    backgroundColor: 'transparent !important',
  },
  menuItemIcon: {
    minWidth: theme.spacing(4),
    verticalAlign: 'middle',
    position: 'relative',
    top: -2,
  },
});

const BudgetToolbarActionsMenu = ({
  activeBudget,
  activeBudgetId,
  activeBudgetYears,
  activeYear,
  activeYearId,
  defaultCurrency,
  isDeleteYearButtonAvailable,
  buttonSize,
  onUpdateBudget,
  onDeleteBudget,
  onDeleteYear,
  classes,
}) => {
  const { name, currency } = activeBudget || {};
  const { year, _tables } = activeYear || {};

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [isBudgetDetailsDialogOpen, setIsBudgetDetailsDialogOpen] =
    useState(false);

  const [isBudgetExportDialogOpen, setIsBudgetExportDialogOpen] =
    useState(false);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBudgetDetailsDialogOpen = useCallback(() => {
    setIsBudgetDetailsDialogOpen(true);
  }, []);

  const handleBudgetExportDialogOpen = useCallback(() => {
    setIsBudgetExportDialogOpen(true);
  }, []);

  const handleUpdateBudget = useCallback(
    (data) => {
      onUpdateBudget(activeBudgetId, data);
    },
    [onUpdateBudget, activeBudgetId]
  );

  const handleDeleteBudget = useCallback(() => {
    onDeleteBudget(activeBudgetId);
    setAnchorEl(null);
  }, [onDeleteBudget, setAnchorEl, activeBudgetId]);

  const handleDeleteYear = useCallback(() => {
    onDeleteYear(activeYearId);
    setAnchorEl(null);
  }, [onDeleteYear, setAnchorEl, activeYearId]);

  const BudgetDetailsMenuItem = () => (
    <MenuItem
      onKeyDown={(e) => e.stopPropagation()}
      focusVisibleClassName={classes.noBackground}
    >
      <Box component="span" onClick={handleBudgetDetailsDialogOpen}>
        <ListItemIcon className={classes.menuItemIcon}>
          <EditIcon fontSize="small" />
        </ListItemIcon>
        <Translate value="budget.budget_details" />
      </Box>
      {isBudgetDetailsDialogOpen && (
        <BudgetDetailsDialog
          isOpen={isBudgetDetailsDialogOpen}
          setIsOpen={setIsBudgetDetailsDialogOpen}
          name={name}
          currency={currency}
          defaultCurrency={defaultCurrency}
          description={I18n.t('budget.budget_details')}
          confirmButtonText={I18n.t('budget.budget_update')}
          onConfirm={handleUpdateBudget}
          onCancel={handleClose}
        />
      )}
    </MenuItem>
  );

  const ExportBudgetMenuItem = () => (
    <MenuItem
      onKeyDown={(e) => e.stopPropagation()}
      focusVisibleClassName={classes.noBackground}
    >
      <Box component="span" onClick={handleBudgetExportDialogOpen}>
        <ListItemIcon className={classes.menuItemIcon}>
          <GetAppIcon fontSize="small" />
        </ListItemIcon>
        <Translate value="budget.budget_export_year" />
      </Box>
      {isBudgetExportDialogOpen && (
        <BudgetExportDialog
          isOpen={isBudgetExportDialogOpen}
          name={name}
          tableIds={_tables}
          setIsOpen={setIsBudgetExportDialogOpen}
          onCancel={handleClose}
        />
      )}
    </MenuItem>
  );

  const DeleteBudgetMenuItem = () => (
    <MenuItem>
      <ConfirmDialog
        onConfirm={handleDeleteBudget}
        onClose={handleClose}
        onCancel={handleClose}
        title={`${I18n.t('budget.budget_confirm_delete_title')} "${name}"?`}
        description={I18n.t('budget.budget_confirm_delete')}
      >
        <ListItemIcon className={classes.menuItemIcon}>
          <DeleteIcon fontSize="small" />
        </ListItemIcon>
        <Translate value="budget.budget_delete" />
      </ConfirmDialog>
    </MenuItem>
  );

  const DeleteYearMenuItem = () => (
    <MenuItem>
      <ConfirmDialog
        onConfirm={handleDeleteYear}
        onClose={handleClose}
        onCancel={handleClose}
        title={`${I18n.t('budget.year_confirm_delete_title')} "${year}"?`}
        description={I18n.t('budget.year_confirm_delete')}
      >
        <ListItemIcon className={classes.menuItemIcon}>
          <DeleteIcon fontSize="small" />
        </ListItemIcon>
        <Translate value="budget.year_delete" />
      </ConfirmDialog>
    </MenuItem>
  );

  const isDeleteYearAvailable =
    isDeleteYearButtonAvailable && activeBudgetYears?.length > 1;

  return (
    <>
      <IconButton
        title={I18n.t('common.open_actions_menu')}
        aria-controls="table-toolbar-actions-menu"
        aria-haspopup="true"
        onClick={handleOpen}
        size={buttonSize}
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        id="table-toolbar-actions-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <BudgetDetailsMenuItem />
        <ExportBudgetMenuItem />
        {isDeleteYearAvailable && <DeleteYearMenuItem />}
        <DeleteBudgetMenuItem />
      </Menu>
    </>
  );
};

export default withStyles(styles)(BudgetToolbarActionsMenu);
