import { I18n } from 'react-redux-i18n';

export const ACTIVE_BUDGET_ID_KEY = 'activeBudgetId';
export const ACTIVE_YEAR_ID_KEY = 'activeYearId';
export const EXPANDED_SUMMARY_ACCORDIONS_KEY = 'expandedSummaryAccordions';
export const HEADER_CELLS_NAME_FORMAT_KEY = 'headerCellsNameFormat';
export const IS_SIDEBAR_VISIBLE_KEY = 'isSidebarVisible';

export const HEADER_CELLS_NAME_FORMAT_SHORT = 'shortName';
export const HEADER_CELLS_NAME_FORMAT_FULL = 'fullName';

export const DIRECTION_UP = 'up';
export const DIRECTION_DOWN = 'down';

export const DEFAULT_BUDGET_NAME = I18n.t('app.budget');
