import React from 'react';
import { Route, Switch } from 'react-router-dom';
import withRequireAuth from 'components/withRequireAuth/withRequireAuth';
import Signin from 'modules/signin/';
import Signup from 'modules/signup/';
import ResetPassword from 'modules/resetPassword/';
import Account from 'modules/account/';
import Profile from 'modules/profile/';
import Budget from 'modules/budget/';
import BudgetCreate from 'modules/budgetCreate/BudgetCreate';
import BudgetsOverview from 'modules/budgetsOverview';
import CalculatorsOverview from 'modules/calculatorsOverview/CalculatorsOverview';
import SubscriptionPlans from 'modules/subscription/SubscriptionPlans';
import SubscriptionSummary from 'modules/subscription/SubscriptionSummary';
import SubscriptionPlanPremium from 'modules/subscription/SubscriptionPlanPremium';
import {
  ROOT_PATH,
  ACCOUNT_PATH,
  SIGNIN_PATH,
  SIGNUP_PATH,
  PROFILE_PATH,
  BUDGET_PATH,
  BUDGET_CREATE_PATH,
  BUDGETS_PATH,
  CALCULATORS_PATH,
  PASSWORD_RESET_PATH,
  SUBSCRIPTION_PLAN_PREMIUM_PATH,
  SUBSCRIPTION_PLANS_PATH,
  SUBSCRIPTION_SUMMARY_PATH,
} from './constants';
import config from 'config.js';

const Routes = () => {
  return (
    <Switch>
      <Route exact path={ROOT_PATH} component={Signin} />
      <Route exact path={SIGNIN_PATH} component={Signin} />
      <Route exact path={SIGNUP_PATH} component={Signup} />
      <Route exact path={ACCOUNT_PATH} component={withRequireAuth(Account)} />
      <Route exact path={PASSWORD_RESET_PATH} component={ResetPassword} />
      <Route exact path={PROFILE_PATH} component={withRequireAuth(Profile)} />
      <Route exact path={BUDGET_PATH} component={withRequireAuth(Budget)} />
      <Route
        exact
        path={BUDGET_CREATE_PATH}
        component={withRequireAuth(BudgetCreate)}
      />
      <Route
        exact
        path={BUDGETS_PATH}
        component={withRequireAuth(BudgetsOverview)}
      />
      <Route
        exact
        path={CALCULATORS_PATH}
        component={withRequireAuth(CalculatorsOverview)}
      />
      {config.isSubscriptionPlanEnabled && (
        <>
          <Route
            exact
            path={SUBSCRIPTION_PLAN_PREMIUM_PATH}
            component={withRequireAuth(SubscriptionPlanPremium)}
          />
          <Route
            exact
            path={SUBSCRIPTION_PLANS_PATH}
            component={withRequireAuth(SubscriptionPlans)}
          />
          <Route
            exact
            path={SUBSCRIPTION_SUMMARY_PATH}
            component={withRequireAuth(SubscriptionSummary)}
          />
        </>
      )}
    </Switch>
  );
};

export default Routes;
