import _ from 'lodash';
import React, { useState, useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { utils, writeFile } from 'xlsx';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { getExportDataByTableIds } from 'models/budget/selectors';
import globalStyles from 'globalStyles.js';

const styles = (theme) => ({
  dialogContent: {
    [theme.breakpoints.up('sm')]: {
      minWidth: 400,
    },
  },
});

const BudgetExportDialog = ({
  name,
  tableIds,
  isOpen,
  setIsOpen,
  onCancel,
  classes,
}) => {
  const [fileType, setFileType] = useState('xlsx');

  const globalClasses = globalStyles();

  const exportData = useSelector(
    (state) => getExportDataByTableIds(state, tableIds),
    shallowEqual
  );

  const handleFileTypeChange = useCallback(
    (event) => {
      setFileType(event.target.value);
    },
    [setFileType]
  );

  const handleClose = useCallback(() => {
    if (_.isFunction(onCancel)) {
      onCancel();
    }
    setIsOpen(false);
  }, [setIsOpen, onCancel]);

  const handleConfirm = useCallback(() => {
    const worksheet = utils.json_to_sheet(exportData);
    const workbook = utils.book_new();
    const fileName = `wallett-${name ? _.kebabCase(name) : 'budget'
      }.${fileType}`;

    utils.book_append_sheet(workbook, worksheet, 'Data');
    writeFile(workbook, fileName);
  }, [name, exportData, fileType]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      aria-labelledby="export-budget-dialog-description"
      data-test-id="budget-export-dialog"
    >
      <DialogTitle id="export-budget-dialog-description">
        <Translate value="budget.budget_export_options" />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <FormControl variant="outlined" margin="dense" fullWidth>
          <InputLabel id="select-file-type-label">
            <Translate value="budget.budget_export_file_type" />
          </InputLabel>
          <Select
            labelId="select-file-type-label"
            label={I18n.t('budget.budget_export_file_type')}
            value={fileType}
            onChange={handleFileTypeChange}
          >
            <MenuItem value="xlsx">Microsoft Excel (.xlsx)</MenuItem>
            <MenuItem value="html">HTML Table (.html)</MenuItem>
            <MenuItem value="csv">Comma Separated Values (.csv)</MenuItem>
            <MenuItem value="ods">OpenDocument (.ods)</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions className={globalClasses.dialogActions}>
        <Button onClick={handleClose} size="medium">
          <Translate value="common.cancel" />
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          variant="contained"
          size="medium"
          autoFocus
        >
          <Translate value="common.export" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(BudgetExportDialog);
