import _ from 'lodash';
import React from 'react';
import { Translate } from 'react-redux-i18n';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import globalStyles from 'globalStyles.js';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.custom.color.primaryLight,
    '& > *': {
      borderBottom: 'unset',
      paddingTop: 12,
      paddingBottom: 12,
    },
  },
  cell: {
    fontWeight: 600,
    whiteSpace: 'nowrap',
    '&:last-child': {
      paddingRight: theme.custom.budget.lastCellPaddingRight,
    },
  },
  headerCell: {
    fontWeight: 600,
  },
});

const BudgetRowSummary = ({ cells, currencySettings, classes }) => {
  const globalClasses = globalStyles();

  const cellClass = classNames(globalClasses.budgetTableCell, classes.cell);

  return (
    <TableRow className={classes.root}>
      <TableCell key="1"></TableCell>
      <TableCell className={classes.headerCell} key="2">
        <Translate value="common.total" />:
      </TableCell>
      {_.map(cells, (cell) => (
        <TableCell className={cellClass} key={cell.value} align="right">
          <NumberFormat
            value={cell.amount}
            displayType="text"
            {...currencySettings}
          />
        </TableCell>
      ))}
    </TableRow>
  );
};

export default withStyles(styles)(BudgetRowSummary);
