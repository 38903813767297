import _ from 'lodash';
import { CURRENCIES, LANGUAGE_CURRENCY_MAP } from 'lang/currencies';
import { DEFAULT_CURRENCY_CODE } from 'constants.js';

const formatThousands = (value, thousandSeparator, decimalSeparator) => {
  const parts = value.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
  return parts.join(decimalSeparator);
};

export const getDefaultCurrency = (locale) =>
  _.get(LANGUAGE_CURRENCY_MAP, locale) || DEFAULT_CURRENCY_CODE;

export const getCurrencyDetails = (currency) => {
  return CURRENCIES[currency] || CURRENCIES.USD;
};

export const getCurrencySettings = ({
  currencyDetails = {},
  spellCheck = false,
  isNumericString = true,
  allowNegative = false,
}) => {
  const {
    symbol,
    decimal_digits,
    symbol_position,
    thousand_separator,
    decimal_separator,
  } = currencyDetails;

  return {
    [symbol_position === 'prefix' ? 'prefix' : 'suffix']: symbol,
    decimalScale: decimal_digits,
    thousandSeparator: thousand_separator,
    decimalSeparator: decimal_separator,
    spellCheck,
    isNumericString,
    allowNegative,
  };
};

export const getFormattedAmount = (amount = 0, currencyDetails) => {
  if (!_.isNumber(amount)) {
    return null;
  }

  if (!currencyDetails) {
    return amount;
  }

  const {
    symbol,
    symbol_position,
    decimal_digits,
    thousand_separator,
    decimal_separator,
  } = currencyDetails;

  const roundedAmount = _.round(amount, decimal_digits);
  const formattedAmount = formatThousands(
    roundedAmount,
    thousand_separator,
    decimal_separator
  );

  return symbol_position === 'prefix'
    ? `${symbol}${formattedAmount}`
    : `${formattedAmount}${symbol}`;
};