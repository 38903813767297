import { I18n } from 'react-redux-i18n';

const isEmailAddress =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
// const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default (email = '') => {
  if (!email) {
    return I18n.t('auth.enter_email');
  }

  if (!isEmailAddress.test(email)) {
    return I18n.t('auth.email_is_invalid');
  }

  return;
};
