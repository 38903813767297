import React, { useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { I18n, Translate } from 'react-redux-i18n';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { DIRECTION_UP, DIRECTION_DOWN } from 'models/budget/constants';
import ConfirmDialog from 'components/confirmDialog/ConfirmDialog';

const styles = (theme) => ({
  menuItemIcon: {
    minWidth: theme.spacing(4),
  },
});

const BudgetRowActionsMenu = ({ onDeleteRow, onMoveRow, classes }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteRow = useCallback(() => {
    onDeleteRow();
    handleClose();
  }, [onDeleteRow]);

  const handleMoveRowUp = useCallback(() => {
    onMoveRow(DIRECTION_UP);
    handleClose();
  }, [onMoveRow]);

  const handleMoveRowDown = useCallback(() => {
    onMoveRow(DIRECTION_DOWN);
    handleClose();
  }, [onMoveRow]);

  return (
    <>
      <IconButton
        aria-label={I18n.t('common.settings')}
        size="small"
        aria-controls="row-details-actions-menu"
        aria-haspopup="true"
        onClick={handleOpen}
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        id="row-details-actions-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleMoveRowUp}>
          <ListItemIcon className={classes.menuItemIcon}>
            <ArrowUpwardIcon fontSize="small" />
          </ListItemIcon>
          <Translate value="budget.move_up" />
        </MenuItem>
        <MenuItem onClick={handleMoveRowDown}>
          <ListItemIcon className={classes.menuItemIcon}>
            <ArrowDownwardIcon fontSize="small" />
          </ListItemIcon>
          <Translate value="budget.move_down" />
        </MenuItem>
        <MenuItem>
          <ListItemIcon className={classes.menuItemIcon}>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ConfirmDialog
            onConfirm={handleDeleteRow}
            onCancel={handleClose}
            description={I18n.t('budget.row_confirm_delete')}
          >
            <Translate value="budget.row_delete" />
          </ConfirmDialog>
        </MenuItem>
      </Menu>
    </>
  );
};

export default withStyles(styles)(BudgetRowActionsMenu);
