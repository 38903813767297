import React from 'react';
import { Translate } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Footer from 'components/footer/Footer';
import UpdateEmail from 'modules/account/updateEmail/UpdateEmail';
import UpdatePassword from 'modules/account/updatePassword/UpdatePassword';
import CloseAccount from 'modules/account/closeAccount/CloseAccount';
import SubscriptionPlan from 'modules/account/subscriptionPlan/SubscriptionPlan';
import TwoFactorAuthentication from 'modules/account/twoFactorAuthentication/TwoFactorAuthentication';
import globalStyles from 'globalStyles.js';
import config from 'config.js';

const styles = (theme) => ({
  root: {
    padding: theme.custom.wrapper.padding,
    marginBottom: theme.custom.global.marginBottom,
    minHeight: theme.custom.global.minHeightWithFooter,
  },
  cardContent: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },
});

const Account = ({
  classes,
  user,
  userEmail,
  onCloseAccount,
  onUpdateUserEmail,
  onUpdateUserPassword,
}) => {
  const globalClasses = globalStyles();

  return (
    <>
      <div className={classes.root} data-test-id="account">
        <Grid container justifyContent="center">
          <Grid item className={globalClasses.containerSm}>
            <Typography className={globalClasses.pageTitle} variant="h2">
              <Translate value="app.account" />
            </Typography>
            <Grid container direction="column" alignItems="stretch" spacing={4}>
              {config.appFeatures.is2FaEnabled && (
                <Grid item>
                  <Card>
                    <CardContent className={classes.cardContent}>
                      <TwoFactorAuthentication />
                    </CardContent>
                  </Card>
                </Grid>
              )}
              {config.appFeatures.isSubscriptionPlanEnabled && (
                <Grid item>
                  <Card>
                    <CardContent className={classes.cardContent}>
                      <SubscriptionPlan user={user} />
                    </CardContent>
                  </Card>
                </Grid>
              )}
              <Grid item>
                <Card>
                  <CardContent className={classes.cardContent}>
                    <UpdateEmail
                      userEmail={userEmail}
                      onUpdateUserEmail={onUpdateUserEmail}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item>
                <Card>
                  <CardContent className={classes.cardContent}>
                    <UpdatePassword
                      onUpdateUserPassword={onUpdateUserPassword}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item>
                <Card>
                  <CardContent className={classes.cardContent}>
                    <CloseAccount onCloseAccount={onCloseAccount} />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </>
  );
};

export default withStyles(styles)(Account);
