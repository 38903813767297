import _ from 'lodash';
import axios from 'axios';
import { I18n } from 'react-redux-i18n';
import debug from 'utils/debug';
import * as authModelActions from 'models/auth/actions';
import { fetchMessage } from 'models/message/actions';
import { ACTIVE_YEAR_ID_KEY } from './constants';
import {
  UPDATE_YEAR,
  TRIGGER_FETCH_YEAR,
  FETCH_YEAR,
  UPDATE_BUDGET,
} from './actionTypes';
import { COMPONENT_ID_BUDGET } from 'constants.js';

const DEBUG_PATH = 'models/budget/actionsYear';

export const updateYearState = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_YEAR,
    payload: data,
  });
};

export const addYear =
  (budgetId, data = {}) =>
    async (dispatch) => {
      debug(DEBUG_PATH, 'addYear', {
        budgetId,
        data,
      });

      try {
        const res = await axios.post('/api/budget/year', {
          budgetId,
          data,
        });

        debug(DEBUG_PATH, 'addYear: added', {
          data: res.data,
        });

        dispatch({
          type: UPDATE_BUDGET,
          payload: res.data?.budget,
        });

        dispatch(
          fetchMessage(I18n.t('app.message.year_successfully_created'), 'success')
        );
      } catch (err) {
        debug(DEBUG_PATH, 'addYear: error', {
          budgetId,
          data,
        });
        dispatch(fetchMessage(_.get(err, 'response.data.message')));
      }
    };

export const updateYear = (yearId, data) => async (dispatch) => {
  debug(DEBUG_PATH, 'updateYear', {
    yearId,
    data,
  });

  try {
    const res = await axios.patch('/api/budget/year', {
      yearId,
      data,
    });

    debug(DEBUG_PATH, 'updateYear: updated', {
      data: res.data,
    });

    dispatch({
      type: UPDATE_YEAR,
      payload: res.data,
    });
  } catch (err) {
    debug(DEBUG_PATH, 'updateYear: error', {
      yearId,
      data,
    });

    dispatch(fetchMessage(err));
  }
};

export const setActiveYearId = (yearId) => (dispatch) => {
  debug(DEBUG_PATH, 'setActiveYearId', { yearId });

  dispatch(
    authModelActions.updateComponentUserSettings({
      componentId: COMPONENT_ID_BUDGET,
      data: { [ACTIVE_YEAR_ID_KEY]: yearId },
    })
  );
};

export const triggerFetchYear = (activeYearId) => (dispatch) => {
  dispatch({ type: TRIGGER_FETCH_YEAR, payload: { activeYearId } });
};

export const fetchYear = (yearId) => async (dispatch) => {
  debug(DEBUG_PATH, 'fetchYear', {
    yearId: yearId,
  });

  try {
    const res = await axios.get(`/api/budget/year/${yearId}`);

    debug(DEBUG_PATH, 'fetchYear: fetched', { data: res.data });
    dispatch({ type: FETCH_YEAR, payload: res.data });
  } catch (err) {
    debug(DEBUG_PATH, 'fetchYear: error', {
      yearId: yearId,
    });
    dispatch(fetchMessage(I18n.t('app.message.cant_fetch_budget')));
  }
};

export const deleteYear = (yearId) => async (dispatch) => {
  debug(DEBUG_PATH, 'deleteYear', {
    yearId,
  });

  try {
    const res = await axios.delete(`/api/budget/year/${yearId}`);

    debug(DEBUG_PATH, 'deleteYear: deleted', {
      yearId,
    });

    dispatch({ type: UPDATE_BUDGET, payload: res.data?.budget });
    dispatch(
      fetchMessage(I18n.t('app.message.year_successfully_deleted'), 'success')
    );
  } catch (err) {
    debug(DEBUG_PATH, 'deleteYear: error', {
      yearId,
    });
  }
};
