import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

const Loading = () => {
  return (
    <div>
      <LinearProgress color="secondary" />
    </div>
  );
};

export default Loading;
