import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Translate } from 'react-redux-i18n';
import { withStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import SubscriptionButton from 'components/subscriptionButton/SubscriptionButton';
import MenuLocale from 'components/menuLocale';
import {
  ACCOUNT_PATH,
  SIGNIN_PATH,
  SIGNUP_PATH,
  PROFILE_PATH,
  BUDGET_PATH,
  BUDGETS_PATH,
  CALCULATORS_PATH,
  LOGOUT_PATH,
} from 'constants.js';
import config from 'config.js';

const styles = (theme) => ({
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
  menuItem: {
    marginLeft: theme.spacing(1),
  },
});

const MenuMain = ({ isAuthenticated, subscriptionType, classes }) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuMobileAnchorEl, setMenuMobileAnchorEl] = useState(null);

  const theme = useTheme();
  const breakpointMd = useMediaQuery(theme.breakpoints.up('md'));

  const handleMenuOpen = useCallback(
    (event) => {
      setMenuAnchorEl(event.currentTarget);
    },
    [setMenuAnchorEl]
  );

  const handleMobileMenuOpen = useCallback(
    (event) => {
      setMenuMobileAnchorEl(event.currentTarget);
    },
    [setMenuMobileAnchorEl]
  );

  const handleMenuClose = useCallback(() => {
    setMenuAnchorEl(null);
  }, [setMenuAnchorEl]);

  const handleMenuMobileClose = useCallback(() => {
    setMenuMobileAnchorEl(null);
  }, [setMenuMobileAnchorEl]);

  const isMenuOpen = Boolean(menuAnchorEl);
  const isMenuMobileOpen = Boolean(menuMobileAnchorEl);
  const isSubscriptionButtonAvailable =
    config.isSubscriptionPlanEnabled && subscriptionType === 'free';

  if (isAuthenticated === null) {
    return null;
  }

  const renderActionMenu = () => (
    <>
      <IconButton
        aria-owns={isMenuOpen ? 'menu-user' : null}
        aria-haspopup="true"
        onClick={handleMenuOpen}
        color="inherit"
      >
        {' '}
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-user"
        open={isMenuOpen}
        onClose={handleMenuClose}
        anchorEl={menuAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleMenuClose}>
          <Link to={PROFILE_PATH} className={classes.link}>
            <Translate value="app.profile" />
          </Link>
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          <Link to={ACCOUNT_PATH} className={classes.link}>
            <Translate value="app.account" />
          </Link>
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          <a href={LOGOUT_PATH} className={classes.link}>
            <Translate value="auth.sign_out" />
          </a>
        </MenuItem>
      </Menu>
    </>
  );

  const renderDesktopMenu = () => (
    <>
      {isAuthenticated ? (
        <>
          <Button
            className={classes.menuItem}
            component={Link}
            to={BUDGET_PATH}
            color="inherit"
          >
            <Translate value="app.budget" />
          </Button>
          <Button
            className={classes.menuItem}
            component={Link}
            to={BUDGETS_PATH}
            color="inherit"
          >
            <Translate value="app.budgets_overview" />
          </Button>
          <Button
            className={classes.menuItem}
            component={Link}
            to={CALCULATORS_PATH}
            color="inherit"
          >
            <Translate value="app.calculators" />
          </Button>
          {isSubscriptionButtonAvailable && (
            <span className={classes.menuItem}>
              <SubscriptionButton />
            </span>
          )}
          {renderActionMenu()}
        </>
      ) : (
        <>
          <Button
            className={classes.menuItem}
            component={Link}
            to={SIGNIN_PATH}
            color="inherit"
          >
            <Translate value="auth.sign_in" />
          </Button>
          <Button
            className={classes.menuItem}
            component={Link}
            to={SIGNUP_PATH}
            color="inherit"
          >
            <Translate value="auth.sign_up" />
          </Button>
        </>
      )}
    </>
  );

  const renderMenuMobile = () => (
    <>
      {isAuthenticated && renderActionMenu()}
      <IconButton
        aria-owns={isMenuMobileOpen ? 'menu-mobile' : null}
        aria-haspopup="true"
        onClick={handleMobileMenuOpen}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-mobile"
        open={isMenuMobileOpen}
        onClose={handleMenuMobileClose}
        anchorEl={menuMobileAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {isAuthenticated
          ? [
            <MenuItem key="budgets-overview" onClick={handleMenuMobileClose}>
              <a href={BUDGETS_PATH} className={classes.link}>
                <Translate value="app.budgets_overview" />
              </a>
            </MenuItem>,
            <MenuItem key="calculators" onClick={handleMenuMobileClose}>
              <a href={CALCULATORS_PATH} className={classes.link}>
                <Translate value="app.calculators" />
              </a>
            </MenuItem>,
          ]
          : [
            <MenuItem key="sign-in" onClick={handleMenuMobileClose}>
              <a href={SIGNIN_PATH} className={classes.link}>
                <Translate value="auth.sign_in" />
              </a>
            </MenuItem>,
            <MenuItem key="sign-up" onClick={handleMenuMobileClose}>
              <a href={SIGNUP_PATH} className={classes.link}>
                <Translate value="auth.sign_up" />
              </a>
            </MenuItem>,
          ]}
      </Menu>
    </>
  );

  return (
    <>
      {breakpointMd && renderDesktopMenu()}
      <MenuLocale />
      {!breakpointMd && renderMenuMobile()}
    </>
  );
};

export default withStyles(styles)(MenuMain);
