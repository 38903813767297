import React, { useState, useEffect } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import objectToArray from 'utils/objectToArray';
import { CURRENCIES } from 'lang/currencies';

const styles = () => ({
  option: {
    '& > div': {
      minWidth: 45,
    },
  },
});

const CurrencySelector = ({
  selectedCurrency,
  onCurrencyChange,
  inputLabel = I18n.t('budget.currency'),
  inputVariant = 'outlined',
  classes,
}) => {
  const [currencyValue, setCurrencyValue] = useState(selectedCurrency || null);
  const [currencyInputValue, setCurrencyInputValue] = useState(
    selectedCurrency || ''
  );

  useEffect(() => {
    if (selectedCurrency !== currencyValue) {
      setCurrencyValue(selectedCurrency || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrency]);

  const handleCurrencyChange = (event, newValue) => {
    const value = newValue?.key;
    onCurrencyChange(value || undefined);
    setCurrencyValue(value || null);
  };

  const handleCurrencyInputChange = (event, newValue) => {
    setCurrencyInputValue(newValue || '');
  };

  return (
    <Autocomplete
      size="small"
      classes={{
        option: classes.option,
      }}
      value={currencyValue || null}
      onChange={handleCurrencyChange}
      inputValue={currencyInputValue}
      onInputChange={handleCurrencyInputChange}
      options={objectToArray(CURRENCIES)}
      getOptionLabel={(option) => option?.value?.code || option}
      getOptionSelected={(option) =>
        (option?.value?.code || option) === currencyValue
      }
      renderOption={(option) => (
        <>
          <div>
            <Typography>{option?.value?.code}</Typography>
          </div>
          <Typography variant="caption" noWrap>
            <Translate value={`currency.${option?.value?.code}.name`} />
          </Typography>
        </>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={inputLabel}
          variant={inputVariant}
          margin="dense"
          fullWidth
          InputLabelProps={{ disableAnimation: Boolean(selectedCurrency) }}
        />
      )}
    />
  );
};

export default withStyles(styles)(CurrencySelector);
