import React from 'react';
import { Translate } from 'react-redux-i18n';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import Email from '@material-ui/icons/Email';

const FormField = ({
  input,
  label,
  neme,
  type,
  icon,
  placeholder,
  phrase,
  meta: { error, touched },
}) => {
  return (
    <FormControl fullWidth margin="dense">
      {label && (
        <InputLabel htmlFor={neme}>
          <Translate value={label} phrase={phrase} />
        </InputLabel>
      )}
      <Input
        id={neme}
        placeholder={placeholder}
        type={type}
        {...input}
        startAdornment={
          icon && (
            <InputAdornment position="start">
              {icon === 'email' && <Email />}
            </InputAdornment>
          )
        }
      />
      {touched && error && (
        <FormHelperText id={neme} error={Boolean(touched && error)}>
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default FormField;
