import _ from 'lodash';
import React, { useState, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { I18n } from 'react-redux-i18n';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import TableCell from '@material-ui/core/TableCell';
import InputBase from '@material-ui/core/InputBase';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import BudgetCellMenu from 'modules/budget/budgetCell/budgetCellMenu/BudgetCellMenu';
import { DEFAULT_AMOUNT } from 'constants.js';
import globalStyles from 'globalStyles.js';

const styles = (theme) => ({
  cell: {
    verticalAlign: 'bottom',
    position: 'relative',
  },
  cellContent: {
    position: 'relative',
  },
  input: {
    '& > input': {
      textAlign: 'right',
      fontSize: 14,
      lineHeight: 1.43,
      opacity: 0.2,
    },
  },
  inputComplete: {
    '& > input': {
      opacity: 1,
    },
    '& > div > p': {
      color: 'inherit',
    },
  },
  isInputFocused: {
    '&::after': {
      content: '""',
      display: 'block',
      borderBottom: '1px solid currentColor',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 3,
    },
  },
  badge: {
    '& > span': {
      backgroundColor: 'currentColor',
      opacity: 0.3,
      top: 3,
      right: -3,
    },
  },
  checkButton: {
    position: 'absolute',
    top: '5px',
    right: '-20px',
  },
  checkIcon: {
    fontSize: 13,
    opacity: 0.4,
  },
  checkIconComplete: {
    color: `${theme.custom.color.success} !important`,
    opacity: 1,
  },
  moreActionsButton: {
    position: 'absolute',
    top: '6px',
    left: '-16px',
  },
  moreActionsIcon: {
    fontSize: 12,
  },
  tooltip: {
    marginRight: -50,
  },
});

const BudgetCell = ({
  currencySettings,
  cell,
  defaultAmount = DEFAULT_AMOUNT,
  onUpdateCell,
  classes,
}) => {
  const globalClasses = globalStyles();

  const { value, amount, isComplete, note } = cell;
  const initialAmount = _.isNil(amount) ? defaultAmount : amount;

  const [cellAmount, setCellAmount] = useState(initialAmount);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isCheckVisible, setIsCheckVisible] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  const numberFormatClass = classNames(
    classes.input,
    isComplete === true ? classes.inputComplete : null,
    isInputFocused ? classes.isInputFocused : null
  );

  const checkIconClass = classNames(
    classes.checkIcon,
    isComplete === true ? classes.checkIconComplete : null
  );

  const cellClass = classNames(globalClasses.budgetTableCell, classes.cell);

  useEffect(() => {
    const updatedAmount = _.isNil(amount) ? defaultAmount : amount;
    setCellAmount(updatedAmount);
  }, [amount, defaultAmount, setCellAmount]);

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget.parentNode);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleUpdateCell = useCallback(
    (data, updateSum = false) => {
      onUpdateCell(
        {
          value: value,
          amount: _.toNumber(cellAmount),
          ...data,
        },
        updateSum
      );
    },
    [value, cellAmount, onUpdateCell]
  );

  const handleUpdateAmount = useCallback(() => {
    handleUpdateCell({ isComplete }, true);
  }, [handleUpdateCell, isComplete]);

  const handleNoteSave = useCallback(
    (note) => {
      handleUpdateCell({
        note: note,
      });
    },
    [handleUpdateCell]
  );

  const handleCheckToggle = useCallback(() => {
    handleUpdateCell(
      {
        isComplete: !isComplete,
      },
      true
    );
  }, [isComplete, handleUpdateCell]);

  const handleValueChange = useCallback(
    (values) => {
      setCellAmount(values.value);
    },
    [setCellAmount]
  );

  const handleBlur = useCallback(() => {
    if (!cellAmount) {
      setCellAmount(defaultAmount);
    }
    setIsInputFocused(false);
    handleUpdateAmount();
  }, [
    setCellAmount,
    setIsInputFocused,
    handleUpdateAmount,
    cellAmount,
    defaultAmount,
  ]);

  const handleFocus = useCallback(() => {
    setIsInputFocused(true);
  }, []);

  const handleClickAway = useCallback(() => {
    if (isInputFocused) {
      setIsInputFocused(false);
      setIsCheckVisible(false);
    }
  }, [setIsInputFocused, setIsCheckVisible, isInputFocused]);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        handleUpdateAmount();
      }
    },
    [handleUpdateAmount]
  );

  const handleClear = useCallback(() => {
    onUpdateCell(
      {
        value: value,
        amount: null,
        isComplete: false,
        comment: '',
      },
      true
    );
  }, [onUpdateCell, value]);

  const renderCellContent = () => {
    return (
      <div className={classes.cellContent}>
        <NumberFormat
          className={numberFormatClass}
          value={cellAmount}
          onValueChange={handleValueChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          customInput={InputBase}
          onKeyDown={handleKeyDown}
          {...currencySettings}
        />
        {(isInputFocused || isCheckVisible) && (
          <IconButton
            className={classes.checkButton}
            onMouseDown={handleCheckToggle}
            size="small"
            aria-label={I18n.t('budget.toggle_complete')}
          >
            <CheckCircleOutlineOutlinedIcon className={checkIconClass} />
          </IconButton>
        )}
        {isInputFocused && (
          <IconButton
            className={classes.moreActionsButton}
            onMouseDown={handleMenuClick}
            size="small"
          >
            <MoreVertIcon
              className={classes.moreActionsIcon}
              fontSize="small"
            />
          </IconButton>
        )}
        <BudgetCellMenu
          anchorEl={menuAnchorEl}
          cell={cell}
          closeMenu={handleMenuClose}
          onClear={handleClear}
          onCheckToggle={handleCheckToggle}
          onNoteSave={handleNoteSave}
        />
      </div>
    );
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <TableCell
        className={cellClass}
        align="right"
        onMouseEnter={() => setIsCheckVisible(true)}
        onMouseLeave={() => setIsCheckVisible(false)}
        data-test-id="budget-cell"
      >
        {note ? (
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            placement="top"
            title={note}
          >
            <Badge className={classes.badge} variant="dot">
              {renderCellContent()}
            </Badge>
          </Tooltip>
        ) : (
          renderCellContent()
        )}
      </TableCell>
    </ClickAwayListener>
  );
};

export default withStyles(styles)(BudgetCell);
