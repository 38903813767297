import imageSavings2 from 'assets/images/savings-2.jpg';
import imageSavings3 from 'assets/images/savings-3.jpg';
import imageSavings4 from 'assets/images/savings-4.jpg';
import imageTarget2 from 'assets/images/target-2.jpg';
import imageHousing1 from 'assets/images/housing-1.jpg';
import imageRetirement1 from 'assets/images/retirement-1.jpg';

export const CALCULATORS_LIST_DEFINITION = [
  {
    id: 'savings-goal-calculator',
    label: 'calculator.savings_goal_calculator',
    imageUrl: imageTarget2,
  },
  {
    id: 'mortgage-loan-calculator',
    label: 'calculator.mortgage_loan_calculator',
    imageUrl: imageHousing1,
  },
  {
    id: 'compound-interest-calculator',
    label: 'calculator.compound_interest_calculator',
    imageUrl: imageSavings2,
  },
  {
    id: 'loan-refinance-calculator',
    label: 'calculator.loan_refinance_calculator',
    imageUrl: imageSavings3,
  },
  {
    id: 'saving-for-retirement-calculator',
    label: 'calculator.savings_retirement_calculator',
    imageUrl: imageRetirement1,
  },
  {
    id: 'rate-return-calculator',
    label: 'calculator.rate_return_calculator',
    imageUrl: imageSavings4,
  },
];
