import _ from 'lodash';
import { CLEAR_MESSAGE, CLEAR_MESSAGES, FETCH_MESSAGE } from './actionTypes';

export const clearMessage = (timestamp) => (dispatch) => {
  dispatch({
    type: CLEAR_MESSAGE,
    payload: { timestamp },
  });
};

export const clearMessages = () => (dispatch) => {
  dispatch({
    type: CLEAR_MESSAGES,
  });
};

export const fetchMessage =
  (value, messageType = 'error') =>
  (dispatch) => {
    dispatch({
      type: FETCH_MESSAGE,
      payload: {
        messageType,
        value,
        timestamp: _.now(),
      },
    });
  };
