import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as localeModelSelectors from 'models/locale/selectors';
import * as localeModelQueries from 'models/locale/queries';

export default function useCurrencySettings(allowNegative) {
  const activeLocale = useSelector(localeModelSelectors.getActiveLocale);
  const defaultCurrency = localeModelQueries.getDefaultCurrency(activeLocale);

  const currencyDetails = useMemo(
    () => localeModelQueries.getCurrencyDetails(defaultCurrency),
    [defaultCurrency]
  );

  const currencySettings = useMemo(() => {
    return localeModelQueries.getCurrencySettings({
      currencyDetails,
      allowNegative,
    });
  }, [allowNegative, currencyDetails]);

  return currencySettings;
}
