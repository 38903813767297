import React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormField from 'modules/account/formField/FormField';
import { ROOT_PATH } from 'constants.js';

const CLOSE_ACCOUNT_FORM = 'closeAccountForm';

const styles = (theme) => ({
  buttons: {
    marginTop: theme.spacing(3),
  },
  cancel: {
    marginTop: theme.spacing(),
  },
});

const CloseAccountForm = ({
  onCancel,
  onCloseAccount,
  handleSubmit,
  pristine,
  submitting,
  classes,
  history,
}) => {
  const onSubmit = () => {
    onCloseAccount(() => {
      history.push(ROOT_PATH);
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography gutterBottom color="secondary">
        <Translate value="account.delete_absolutely_everything" />
      </Typography>
      <Field
        key="confirmationPhrase"
        component={FormField}
        type="text"
        name="confirmationPhrase"
        label="account.to_verify_type_phrase"
        phrase={I18n.t('account.confirmation_phrase')}
      />
      <div className={classes.buttons}>
        <Button
          type="submit"
          variant="contained"
          disabled={pristine || submitting}
          color="secondary"
          fullWidth
        >
          <Translate value="account.close_account" />
        </Button>
        <Button
          type="submit"
          className={classes.cancel}
          onClick={onCancel}
          fullWidth
        >
          <Translate value="common.cancel" />
        </Button>
      </div>
    </form>
  );
};

function validate({ confirmationPhrase }) {
  const errors = {};

  if (!confirmationPhrase) {
    errors.confirmationPhrase = <Translate value="account.enter_phrase" />;
  }

  if (confirmationPhrase !== I18n.t('account.confirmation_phrase')) {
    errors.confirmationPhrase = (
      <Translate value="account.enter_correct_phrase" />
    );
  }

  return errors;
}

export default compose(
  reduxForm({ validate, form: CLOSE_ACCOUNT_FORM }),
  withStyles(styles),
  withRouter
)(CloseAccountForm);
