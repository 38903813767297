import React from 'react';
import { Translate } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ResetPasswordForm from './resetPasswordForm/ResetPasswordForm';
import globalStyles from 'globalStyles.js';

const styles = (theme) => ({
  root: {
    padding: theme.custom.wrapper.padding,
  },
});

const ResetPassword = ({ onResetPassword, classes, match }) => {
  const globalClasses = globalStyles();

  return (
    <div className={classes.root} data-test-id="reset-password">
      <Grid container justifyContent="center">
        <Grid item className={globalClasses.containerXs}>
          <Typography className={globalClasses.pageTitle} variant="h2">
            <Translate value="auth.reset_your_password" />
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs>
              <Card>
                <CardContent>
                  <Typography>
                    <Translate value="auth.requested_reset_password_1" />
                  </Typography>
                  <Typography gutterBottom>
                    <Translate value="auth.requested_reset_password_2" />
                    <em>{match?.params?.email}</em>
                  </Typography>
                  <ResetPasswordForm onResetPassword={onResetPassword} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(ResetPassword);
