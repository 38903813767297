import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Translate } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import * as localeModelSelectors from 'models/locale/selectors';
import { BUDGET_PATH } from 'constants.js';

const styles = (theme) => ({
  root: {
    padding: theme.custom.wrapper.padding,
  },
  footer: {
    paddingTop: theme.spacing(4),
    borderTop: '1px solid #bdbcc3',
  },
  containerLeft: {
    marginTop: theme.spacing(1),
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      marginTop: 0,
      justifyContent: 'flex-end',
    },
  },
  containerRight: {
    marginTop: theme.spacing(1),
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      marginTop: 0,
      justifyContent: 'flex-start',
    },
  },
  item: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    lineHeight: '30px',
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',

    '&:hover, &:focus': {
      textDecoration: 'underline',
    },
  },
  appItem: {
    order: -1,
    [theme.breakpoints.up('lg')]: {
      order: 0,
    },
  },
  appName: {
    margin: 0,
    fontFamily: 'Galada, cursive',
    fontSize: 25,
    fontWeight: 500,
    lineHeight: '30px',
    letterSpacing: 1,
  },
  appLink: {
    color: '#bdbcc3',
    textDecoration: 'none',
  },
});

const Footer = ({ isImagesLinkVisible, classes }) => {
  const appLinks = useSelector(localeModelSelectors.getAppLinks);

  const links = [
    {
      text: <Translate value="footer.website" />,
      url: appLinks.website,
    },
    {
      text: <Translate value="footer.privacy_policy" />,
      url: appLinks.privacyPolicy,
    },
    {
      text: <Translate value="footer.terms_and_conditions" />,
      url: appLinks.termsConditions,
    },
    {
      text: <Translate value="footer.cookies" />,
      url: appLinks.cookies,
    },
  ];

  return (
    <div className={classes.root}>
      <Grid className={classes.footer} container>
        <Grid item xs={12} lg={5}>
          <Grid
            className={classes.containerLeft}
            container
            justifyContent="flex-end"
          >
            {_.map(links, (link) => (
              <Grid item className={classes.item} key={link.url}>
                <a
                  className={classes.link}
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {link.text}
                </a>
              </Grid>
            ))}
            {isImagesLinkVisible && (
              <Grid item className={classes.item}>
                <a
                  className={classes.link}
                  href="https://www.freepik.com/author/vectorjuice"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Images by vectorjuice
                </a>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} lg={2} className={classes.appItem}>
          <Grid container justifyContent="center">
            <Grid item>


              <h1 className={classes.appName}>
                <Link
                  to={BUDGET_PATH}
                  className={classes.appLink}
                  color="primary"
                >
                  <Translate value="app.title" />
                </Link>
              </h1>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={5}>
          <Grid className={classes.containerRight} container>
            <Grid item className={classes.item}>
              <Translate value="footer.contact_us" />:{' '}
              <a
                className={classes.link}
                href={`mailto:${appLinks.email}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {appLinks.email}
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Footer);
