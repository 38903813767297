import _ from 'lodash';
import { takeEvery, put, select } from 'redux-tale/lib/effects';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as selectors from './selectors';
import * as queries from './queries';
import { DIRECTION_UP, DIRECTION_DOWN } from './constants';
import { STATUS_PENDING } from 'constants.js';

export function* onTriggerFetchYear({ payload }) {
  const { activeYearId } = payload;

  if (!activeYearId) {
    return;
  }

  const yearExists = yield select(selectors.getYearById, {
    yearId: activeYearId,
  });

  if (yearExists) {
    return;
  }

  yield put(actions.fetchYear(activeYearId));
}

export function* onTriggerSetActiveBudgetId({ payload }) {
  const { budgetId } = payload;

  if (!budgetId) {
    return;
  }

  const persistedBudgetId = yield select(selectors.getPersistedBudgetId);

  if (budgetId === persistedBudgetId) {
    return;
  }

  yield put(actions.setActiveBudgetId(budgetId));
}

export function* onTriggerUpdateCell({ payload }) {
  const { rowId, data, updateSum } = payload;

  const cell = yield select(selectors.getRowCell, {
    rowId: rowId,
    value: data.value,
  });

  const row = yield select(selectors.getRowById, {
    rowId: rowId,
  });

  const table = yield select(selectors.getTableById, {
    tableId: row._table,
  });

  if (
    _.isEqual({ ...cell, ...data }, cell) ||
    table?.status === STATUS_PENDING
  ) {
    return;
  }

  yield put(actions.updateCell(rowId, table._id, data, updateSum));

  if (!updateSum) {
    return;
  }

  const sumDiff = queries.getSumDifference(cell, data);

  yield put(
    actions.updateCellState({
      ...data,
      _row: rowId,
    })
  );

  yield put(
    actions.updateRowState({
      _id: rowId,
      rowSum: row.rowSum + sumDiff,
    })
  );

  yield put(
    actions.updateTableState({
      _id: row._table,
      colsSum: {
        ...table.colsSum,
        sum: table.colsSum?.sum ? table.colsSum.sum + sumDiff : data.amount,
        [data.value]: table.colsSum[data.value]
          ? table.colsSum[data.value] + sumDiff
          : sumDiff,
      },
    })
  );
}

export function* onTriggerMoveRow({ payload }) {
  const { rowId, direction } = payload;

  const row = yield select(selectors.getRowById, {
    rowId: rowId,
  });

  const table = yield select(selectors.getTableById, {
    tableId: row._table,
  });

  const { _rows } = table;

  const rowIndex = _.indexOf(_rows, rowId);

  if (
    (direction === DIRECTION_UP && rowIndex === 0) ||
    (direction === DIRECTION_DOWN && rowIndex === _rows.length - 1)
  ) {
    return;
  }

  const swapRowIndex = direction === DIRECTION_UP ? rowIndex - 1 : rowIndex + 1;
  const updatedRows = queries.swapArrayItems(_rows, rowIndex, swapRowIndex);

  yield put(
    actions.updateTableState({
      _id: table._id,
      _rows: updatedRows,
    })
  );

  yield put(
    actions.updateTable(table._id, {
      _rows: updatedRows,
    })
  );
}

export function* onTriggerMoveTable({ payload }) {
  const { tableId, direction } = payload;

  const table = yield select(selectors.getTableById, {
    tableId: tableId,
  });

  const year = yield select(selectors.getYearById, {
    yearId: table._year,
  });

  const { _tables } = year;

  const tableIndex = _.indexOf(_tables, tableId);

  if (
    (direction === DIRECTION_UP && tableIndex === 0) ||
    (direction === DIRECTION_DOWN && tableIndex === _tables.length - 1)
  ) {
    return;
  }

  const swapTableIndex =
    direction === DIRECTION_UP ? tableIndex - 1 : tableIndex + 1;

  const updatedTables = queries.swapArrayItems(
    _tables,
    tableIndex,
    swapTableIndex
  );

  yield put(
    actions.updateYearState({
      _id: year._id,
      _tables: updatedTables,
    })
  );

  yield put(
    actions.updateYear(year._id, {
      _tables: updatedTables,
    })
  );
}

export const onTriggerFetchYearSaga = takeEvery(
  actionTypes.TRIGGER_FETCH_YEAR,
  onTriggerFetchYear
);

export const onTriggerSetActiveBudgetIdSaga = takeEvery(
  actionTypes.TRIGGER_SET_ACTIVE_BUDGET_ID,
  onTriggerSetActiveBudgetId
);

export const onTriggerUpdateCellSaga = takeEvery(
  actionTypes.TRIGGER_UPDATE_CELL,
  onTriggerUpdateCell
);

export const onTriggerMoveRowSaga = takeEvery(
  actionTypes.TRIGGER_MOVE_ROW,
  onTriggerMoveRow
);

export const onTriggerMoveTableSaga = takeEvery(
  actionTypes.TRIGGER_MOVE_TABLE,
  onTriggerMoveTable
);

export default [
  onTriggerFetchYearSaga,
  onTriggerSetActiveBudgetIdSaga,
  onTriggerUpdateCellSaga,
  onTriggerMoveRowSaga,
  onTriggerMoveTableSaga,
];
