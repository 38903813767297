import { makeStyles } from '@material-ui/core/styles';

const globalStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: '1.5rem',
    fontWeight: 400,
    marginBottom: theme.spacing(4),
  },
  containerXs: {
    width: '100%',
    [theme.breakpoints.up('480')]: {
      width: theme.breakpoints.width('480'),
    },
  },
  containerSm: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: theme.breakpoints.width('sm'),
    },
  },
  containerMd: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: theme.breakpoints.width('md'),
    },
  },
  containerLg: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: theme.breakpoints.width('lg'),
    },
  },
  dialogActions: {
    'div&': {
      marginTop: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  budgetTableCell: {
    'th&, td&': {
      padding: '6px 22px 6px 8px',
      whiteSpace: 'nowrap',
    },
  }
}));

export default globalStyles;
