import _ from 'lodash';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Translate } from 'react-redux-i18n';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { BUDGET_PATH_BASE } from 'constants.js';

const CREATE_BUDGET = 'create-budget';
const CREATE_YEAR = 'create-year';

const styles = (theme) => ({
  formControlClasses: {
    marginTop: theme.spacing(2),
    display: 'block',
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      display: 'inline-flex',
    },
  },
  select: {
    minWidth: 100,
  },
  selectBudget: {
    minWidth: '100%',
    marginRight: theme.spacing(1.5),
    [theme.breakpoints.up('md')]: {
      minWidth: 300,
    },
  },
  selectYear: {
    minWidth: '100%',
    [theme.breakpoints.up('md')]: {
      minWidth: 185,
    },
  },
});

const BudgetPicker = ({
  budgetsList,
  activeBudgetId,
  activeYearId = '',
  activeBudgetYears = [],
  setActiveYearId,
  setIsCreateBudgetDialogOpen,
  setIsCreateYearDialogOpen,
  classes,
}) => {
  let history = useHistory();

  const handleBudgetChange = useCallback(
    (event) => {
      if (event.target.value === CREATE_BUDGET) {
        return;
      }
      history.push(`${BUDGET_PATH_BASE}${event.target.value}`);
    },
    [history]
  );

  const handleBudgetYearChange = useCallback(
    (event) => {
      if (event.target.value === CREATE_YEAR) {
        return;
      }
      setActiveYearId(event.target.value);
    },
    [setActiveYearId]
  );

  const handleCreateBudgetDialogOpen = () => {
    setIsCreateBudgetDialogOpen(true);
  };

  const handleCreateYearDialogOpen = () => {
    setIsCreateYearDialogOpen(true);
  };

  const selectBudgetClass = classNames(classes.select, classes.selectBudget);
  const selectYearClass = classNames(classes.select, classes.selectYear);

  return (
    <>
      <FormControl
        className={classes.formControlClasses}
        variant="outlined"
        margin="dense"
      >
        <Select
          className={selectBudgetClass}
          id="budget-selector-budgets"
          value={activeBudgetId}
          onChange={handleBudgetChange}
        >
          {_.map(budgetsList, (budget) => (
            <MenuItem key={budget._id} value={budget._id}>
              {budget.name}
            </MenuItem>
          ))}
          <MenuItem className={classes.budgetCreate} value={CREATE_BUDGET}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={handleCreateBudgetDialogOpen}
              fullWidth
            >
              <Translate value="budget.budget_add" />
            </Button>
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl
        className={classes.formControlClasses}
        variant="outlined"
        margin="dense"
      >
        <Select
          className={selectYearClass}
          id="budget-selector-years"
          value={activeYearId}
          onChange={handleBudgetYearChange}
        >
          {_.map(activeBudgetYears, (year) => (
            <MenuItem key={year._id} value={year._id}>
              {year.year}
            </MenuItem>
          ))}
          <MenuItem className={classes.budgetCreate} value={CREATE_YEAR}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={handleCreateYearDialogOpen}
              fullWidth
            >
              <Translate value="budget.year_add" />
            </Button>
          </MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

export default withStyles(styles)(BudgetPicker);
