import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3.5),
    marginLeft: '33%',
    marginRight: '33%',
    color: theme.custom.color.primary,
  },
});

const CalculatorDivider = ({ classes }) => {
  return <Divider className={classes.root} />
};

export default withStyles(styles)(CalculatorDivider);
