import React, { useState, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'react-redux-i18n';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  dialogCloseButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  subtitle: {
    marginTop: theme.spacing(2),
    fontWeight: 500,
  },
  stepper: {
    paddingBottom: theme.spacing(6),
  }
});

const TwoFactorAuthenticationDialog = ({ isOpen, onClose, classes }) => {
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    'Set up 2FA using an app',
    'Configure your two-factor method',
    'Two-factor authentication activated',
  ];

  const handleNext = useCallback(() => {
    if (activeStep === steps.length - 1) {
      return onClose();
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, [activeStep, onClose, steps.length]);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Typography variant="body1" gutterBottom>
            Two-factor authentication (2FA) is an extra layer of security used
            when logging into websites or apps. With 2FA, you have to log in
            with your username and password and provide another form of
            authentication that only you know or have access to.
          </Typography>
        );
      case 1:
        return 'An ad group contains one or more ads which target a shared set of keywords.';
      case 2:
        return `Try out different ad text to see what brings in the most customers,
                and learn how to enhance your ads using features like ad extensions.
                If you run into any problems with your ads, find out how to tell if
                they're running and how to resolve approval issues.`;
      default:
        return 'Unknown step';
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      aria-labelledby="2fa-dialog-title"
    >
      <DialogTitle>
        <Translate value="auth.two_factor_authentication" />
        <IconButton
          className={classes.dialogCloseButton}
          aria-label="close"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stepper className={classes.stepper} activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                {getStepContent(index)}
                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      <Translate value="common.back" />
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? (
                        <Translate value="common.finish" />
                      ) : (
                        <Translate value="common.next" />
                      )}
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(TwoFactorAuthenticationDialog);
