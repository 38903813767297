export default {
  pl: {
    app: {
      title: 'wallett',
      subtitle: 'Budget Planner & Tracker',
      home_page: 'Strona główna',
      account: 'Konto',
      account_settings: 'Ustawienia konta',
      budget: 'Budżet',
      budget_new: 'Stwórz nowy budżet',
      budgets_overview: 'Moje budżety',
      calculators: 'Kalkulatory',
      profile: 'Profil',
      message: {
        cant_fetch_user_data: 'Nie można pobrać danych użytkownika',
        cant_fetch_budget: 'Nie możena pobrać budżetu',
        entered_invalid_username_password:
          'Wprowadzono nieprawidłową nazwę użytkownika lub hasło',
        account_created: 'Konto zostało utworzone',
        Auth_Email_Sent_To: 'E-mail został wysłany na adres %{email}',
        password_successfully_changed: 'Hasło zostało pomyślnie zmienione',
        email_successfully_changed: 'Adres e-mail został pomyślnie zmieniony',
        account_closed_successfully: 'Konto zostało pomyślnie zamknięte',
        budget_successfully_deleted: 'Budżet został usunięty',
        year_successfully_deleted: 'Rok został usunięty',
        year_successfully_created: 'Rok został dodany',
      },
    },
    footer: {
      website: 'Strona internetowa',
      privacy_policy: 'Polityka prywatności',
      terms_and_conditions: 'Regulamin',
      cookies: 'Ciasteczka',
      contact_us: 'Napisz do nas',
    },
    locale: {
      en: 'EN',
      pl: 'PL',
    },
    common: {
      settings: 'Ustawienia',
      confirm: 'Potwierdź',
      save: 'Zapisz',
      cancel: 'Anuluj',
      clear: 'Wyczyść',
      delete: 'Usuń',
      delete_item: 'Usuń element',
      add: 'Dodaj',
      total: 'Suma',
      balance: 'Saldo',
      net: 'Saldo',
      or: 'lub',
      price: 'Cena',
      toggle_sidebar: 'Pasek boczny',
      open_actions_menu: 'Otwórz menu',
      country: 'Kraj',
      back: 'Wstecz',
      next: 'Dalej',
      finsh: 'Koniec',
      share: 'Udostępnij',
    },
    date: {
      long: 'MMMM Do, YYYY',
      month: 'miesiąc',
      week: 'tydzień',
      day: 'dzień',
      months: {
        0: 'Styczeń',
        1: 'Luty',
        2: 'Marzec',
        3: 'Kwiecień',
        4: 'Maj',
        5: 'Czerwiec',
        6: 'Lipiec',
        7: 'Sierpień',
        8: 'Wrzesień',
        9: 'Październik',
        10: 'Listopad',
        11: 'Grudzień',
      },
      months_short: {
        0: 'Sty',
        1: 'Lut',
        2: 'Mar',
        3: 'Kwi',
        4: 'Maj',
        5: 'Cze',
        6: 'Lip',
        7: 'Sie',
        8: 'Wrz',
        9: 'Paź',
        10: 'Lis',
        11: 'Gru',
      },
      days: {
        0: 'Poniedziałek',
        1: 'Wtorek',
        2: 'Środa',
        3: 'Czwartek',
        4: 'Piątek',
        5: 'Sobota',
        6: 'Niedziela',
      },
      days_short: {
        0: 'Pn',
        1: 'Wt',
        2: 'Śr',
        3: 'Czw',
        4: 'Pt',
        5: 'Sob',
        6: 'Nd',
      },
    },
    currency: {
      USD: {
        name: 'Dolar amerykański',
        name_plural: 'Dolarów amerykańskich',
      },
      CAD: {
        name: 'Dolar kanadyjski',
        name_plural: 'Dolarów kanadyjskich',
      },
      EUR: {
        name: 'Euro',
        name_plural: 'Euro',
      },
      AED: {
        name: 'Dirham Zjednoczonych Emiratów Arabskich',
        name_plural: 'Dirham Zjednoczonych Emiratów Arabskich',
      },
      AUD: {
        name: 'Dolar australijski',
        name_plural: 'Dolarów australijskich',
      },
      BGN: {
        name: 'Lew bułgarski',
        name_plural: 'Lew bułgarski ',
      },
      CHF: {
        name: 'Frank szwajcarski',
        name_plural: 'Franków szwajcarskich',
      },
      CNY: {
        name: 'Chiński Yuan',
        name_plural: 'Chińskich Yuanów',
      },
      CZK: {
        name: 'Korona czeska',
        name_plural: 'Koron czeskich',
      },
      DKK: {
        name: 'Korona duńska',
        name_plural: 'Koron duńskich',
      },
      GBP: {
        name: 'Funt brytyjski',
        name_plural: 'Funtów brytyjskich',
      },
      HKD: {
        name: 'Dolar Hongkongu',
        name_plural: 'Dolarów Hongkongu',
      },
      HRK: {
        name: 'Kuna chorwacka',
        name_plural: 'Kun chorwackich',
      },
      HUF: {
        name: 'Forint',
        name_plural: 'Forintów',
      },
      ILS: {
        name: 'Nowy izraelski szekel',
        name_plural: 'Nowych izraelskich szekli',
      },
      JPY: {
        name: 'Jen',
        name_plural: 'Jenów',
      },
      MXN: {
        name: 'Peso meksykańskie',
        name_plural: 'Peso meksykańskich',
      },
      NOK: {
        name: 'Korona norweska',
        name_plural: 'Koron norweskich',
      },
      NZD: {
        name: 'Dolar nowozelandzki',
        name_plural: 'Dolarów nowozelandzkich',
      },
      PLN: {
        name: 'Złoty',
        name_plural: 'Złotych',
      },
      RON: {
        name: 'Lej rumuński',
        name_plural: 'Lej rumuńskich',
      },
      RSD: {
        name: 'Dinar serbski',
        name_plural: 'Dinarów serbskich',
      },
      RUB: {
        name: 'Rubel rosyjski',
        name_plural: 'Rubli rosyjskich',
      },
      SEK: {
        name: 'Korona szwedzka',
        name_plural: 'Koron szwedzkich',
      },
      SGD: {
        name: 'Dolar singapurski',
        name_plural: 'Dolarów singapurskich',
      },
      THB: {
        name: 'Bat',
        name_plural: 'Bat',
      },
      TRY: {
        name: 'Lira turecka',
        name_plural: 'Lir tureckich',
      },
      ZAR: {
        name: 'Rand',
        name_plural: 'Rand',
      },
    },
    auth: {
      sign_in: 'Zaloguj się',
      sign_in_google: 'Zaloguj się kontem Google',
      sign_in_facebook: 'Zaloguj się kontem Facebook',
      sign_up: 'Zarejestruj się',
      sign_out: 'Wyloguj się',
      create_account: 'Załóż konto',
      email: 'E-mail',
      password: 'Hasło',
      new_password: 'Nowe hasło',
      confirm_password: 'Potwierdź hasło',
      forgot_password_q: 'Zapomniałeś hasła?',
      reset_my_password: 'Zresetuj moje hasło',
      reset_your_password: 'Zresetuj hasło',
      requested_reset_password_1: 'Poprosiłeś o zresetowanie hasła',
      requested_reset_password_2: 'dla:',
      send_password_reset_email: 'Zresetuj hasło',
      enter_password: 'Wpisz hasło',
      enter_password_confirmation: 'Potwierdź hasło',
      enter_email: 'Wpisz e-mail',
      email_is_invalid: 'Nieprawidowy adres email',
      use_n_characters: 'Użyj co najmniej %{number} znaków',
      password_conditions:
        'Hasło musi zawierać składać się przynajmniej z %{number} znaków,zawierać małą i wielką literę oraz cyfrę.',
      password_must_match: 'Hasła muszą być takie same',
      password_must_not_have_whitespace: 'Hasło nie może zawierać spacji',
      password_must_have_upparcase:
        'Hasło musi mieć przynajmniej jedną wielką literę',
      password_must_have_lowercase:
        'Hasło musi mieć przynajmniej jedną małą literę',
      password_must_contain_digit: 'Hasło musi mieć przynajmniej jedną cyfrę',
      two_factor_authentication: 'Uwierzytelnianie dwuskładnikowe',
      two_factor_authentication_description:
        'Uwierzytelnianie dwuskładnikowe dodaje dodatkową warstwę bezpieczeństwa do Twojego konta, wymagając więcej niż tylko hasła do logowania.',
      enable_2fa: 'Aktywuj',
      enabled_2fa: 'Aktywne',
      disabled_2fa: 'Nieaktywne',
    },
    account: {
      plan: 'Plan',
      change_plan: 'Zmień plan',
      email: 'E-mail',
      your_email_is_identity: 'Użyj adresu e-mail aby się zalogować.',
      password: 'Hasło',
      enter_your_email: 'Wpisz adres e-mail',
      current_password: 'Aktualne hasło',
      current_password_if_set: 'Aktualne hasło',
      new_password: 'Nowe hasło',
      confim_new_password: 'Potwierdź nowe hasło',
      use_n_characters: 'Użyj co najmniej %{number} znaków',
      password_must_match: 'Hasła muszą być takie same',
      enter_new_password: 'Wpisz nowe hasło',
      enter_new_email: 'Proszę podać nowy adres e-mail',
      enter_new_password_confirmation: 'Wpisz ponownie nowe hasło',
      enter_all_to_change_password:
        'Wypełnij wszystkie pola aby zmienić hasło.',
      close_account: 'Zamknij konto',
      warning: 'Ostrzeżenie',
      to_verify_type_phrase: 'W celu potwierdzenia operacji wpisz "%{phrase}"',
      closing_your_app_irreversible: 'Zamknięcie konta nie może być cofnięte!',
      delete_absolutely_everything:
        'Zamierzasz usunąć wszystkie swoje dane w tej aplikacji. Tej operacji nie można już cofnąć.',
      confirmation_phrase: 'Zamknij konto',
      enter_phrase: 'Wpisz powyższy tekst aby kontynuować',
      enter_correct_phrase: 'Wpisz poprawny tekst aby kontynuować',
    },
    profile: {
      location: 'Adres',
      name: 'Nazwa użytkownika',
      default_currency: 'Domyślna waluta',
      city: 'Miasto',
      country: 'Kraj',
      zipcode: 'Kod pocztowy',
      details: 'Szczegóły',
      settings: 'Ustawienia',
      name_too_long: 'Nazwa użytkowniaka jest za długa',
    },
    subscription: {
      compare_plans: 'Porównaj plany',
      review_plan: 'Review your plan',
      summary: 'Podsumowanie',
      budget_customization: 'Opcje budżetu',
      income_and_expanse_tracking: 'Śledzenie dochodów i wydatków',
      no_adverts: 'Brak reklam',
      more_than_one_budget: 'Stwórz więcej niż jeden budżet',
      free_support: 'Darmowa pomoc',
      go_premium: 'Zmień na premium',
      upgrade: 'Zmień na premium',
      plan: 'Plan',
      subscription_type: {
        free: 'Darmowa',
        premium: 'Premium',
      },
    },
    budget: {
      variant: 'Wariant',
      incomes: 'Przychody',
      spendings: 'Wydatki',
      net: 'Saldo',
      summary: 'Podsumowanie',
      savings: 'Oszczędności',
      currency: 'Waluta',
      monthly: 'Miesięcznie',
      quarterly: 'Kwartalnie',
      yearly: 'Rocznie',
      custom: 'Dostosuj',
      note: 'Notatka',
      add_note: 'Dodaj notatkę',
      edit_note: 'Edytuj notatkę',
      clear: 'Wyczyść',
      toggle_complete: 'Oznacz wykonanie',
      move_up: 'Przenieś wyżej',
      move_down: 'Przenieś niżej',
      category: 'Kategoria',
      no_tables_information_title: 'Nie masz jeszcze żadnych tabeli.',
      no_tables_information_body:
        'Kliknij ikonę poniżej, aby dodać swoją pierwszą tabelę w roku budżetowym.',
      balance_tooltip: 'Saldo = Przychody - Wydatki',
      budget: 'Budżet',
      budget_create: 'Utwórz budżet',
      budget_create_new_info:
        'Skonfiguruj swój budżet, możesz go stworzyć od podstaw lub wybierz jeden z predefiniowanych szablonów.',
      budget_edit: 'Edytuj budżet',
      budget_add: 'Dodaj budżet',
      budget_update: 'Zapisz',
      budget_details: 'Szczegóły budżetu',
      budget_details_edit: 'Edytuj budżet',
      budget_name: 'Nazwa budżetu',
      budget_delete: 'Usuń budżet',
      budget_confirm_delete_title: 'Czy chcesz usunąć budżet',
      budget_confirm_delete:
        'Tej operacji nie można cofnąć. Budżet oraz wszystkie lata i tabele tego budżetu zostaną trwale usunięte.',
      budgets_overview: 'Podsumowanie budżetów',
      budget_template: 'Szablon budżetu',
      budget_template_none: 'Brak',
      budget_template_table: 'Szablon tabeli',
      budget_share: 'Udostępnij budżet',
      budget_export: 'Eksport',
      budget_export_year: 'Eksport',
      budget_export_options: 'Opcje eksportu',
      budget_export_file_type: 'Rodzaj pliku',
      overview_by_month: 'Przegląd miesięczny',
      column_name: 'Nazwa',
      full_year: 'Rok',
      row_category: 'Kategoria',
      row_more_options: 'Więcej opcji',
      row_font_color: 'Kolor tekstu',
      row_background_color: 'Kolor tła',
      row_note: 'Notatka',
      row_name: 'Nazwa',
      row_default_value: 'Domyślna wartość',
      row_default_amount: 'Domyślna kwota',
      row_custom_category: 'Kategoria',
      row_delete: 'Usuń rząd',
      row_confirm_delete: 'Czy chcesz usunąć rząd?',
      row_confirm_deletes: 'Czy chcesz usunąć rzędów: %{count}?',
      rows_selected: 'Wybranych elementów',
      table_name: 'Nazwa tabeli',
      table_delete: 'Usuń tabelę',
      table_create: 'Utwórz tabelę',
      table_edit: 'Edytuj tabelę',
      table_update: 'Zapisz',
      table_confirm_delete: 'Czy chcesz usunąć tabelę?',
      table_details: 'Szczegóły tabeli',
      table_details_edit: 'Edytuj tabelę',
      table_add: 'Dodaj tabelę',
      year: 'Rok',
      year_details: 'Szczegóły roku',
      year_summary: 'Podsumowanie roku',
      year_delete: 'Usuń rok',
      year_add: 'Dodaj rok',
      year_create: 'Utwórz rok',
      year_exists: 'Rok już istnieje',
      year_confirm_delete_title: 'Czy chcesz usunąć rok',
      year_confirm_delete:
        'Tej operacji nie można cofnąć. Rok oraz wszystkie tabele tego roku zostaną trwale usunięte.',
      year_copy_year_tables: 'Skopiuj tabele z roku',
      year_overview: 'Podsumowanie roku',
    },
    budget_category: {
      incomes: {
        incomes_salary: 'Wypłata',
        incomes_investment: 'Inwestycje',
        incomes_bonus: 'Bonus',
        incomes_interest: 'Dochód z odsetek',
        incomes_rental: 'Dochód z wynajmu',
        incomes_passive: 'Dochód pasywny',
        incomes_parent_contribution: 'Wsparcie rodziców',
        incomes_alimory: 'Alimenty',
        incomes_part_time_job: 'Praca dodatkowa',
        incomes_other: 'Inne',
      },
      savings: {
        savings_savings: 'Oszczędności',
        savings_investing: 'Inwestycje',
        // savings_debt_payments: "",
        savings_emergency_fund: 'Fundusz awaryjny',
        savings_vacations: 'Wakacje',
        savings_retirement: 'Oszczędności emerytalne',
        savings_other: 'Inne',
      },
      spendings: {
        spendings_utilities: 'Media',
        spendings_utilities_electricity: 'Prąd',
        spendings_utilities_gas: 'Gaz',
        spendings_utilities_water: 'Woda',
        spendings_utilities_garbage: 'Śmieci',
        spendings_utilities_phone: 'Telefon',
        spendings_utilities_cable: 'Telewizja',
        spendings_utilities_internet: 'Internet',
        spendings_housing: 'Mieszkanie',
        spendings_housing_mortgage_rent: 'Kredyt/Wynajem',
        spendings_housing_mortgage: 'Kredyt',
        spendings_housing_rent: 'Wynajem',
        spendings_housing_maintanenance: 'Konserwacja i naprawy',
        spendings_housing_supplies: 'Artykuły gospodarstwa domowego',
        spendings_housing_cleaning_supplies: 'Środki czystości',
        spendings_housing_insurance: 'Ubezpieczenie domu',
        spendings_housing_improvements: 'Remont',
        spendings_housing_security: 'Ochrona',
        spendings_housing_cleaner: 'Sprzątanie',
        spendings_housing_taxes: 'Podatek od nieruchomości',
        spendings_housing_other: 'Inne domowe wydatki',
        spendings_transportation: 'Transport',
        spendings_transportation_payment: 'Samochód',
        spendings_transportation_insurance: 'Ubezpieczenie samochodu',
        spendings_transportation_repairs: 'Naprawa',
        spendings_transportation_gas: 'Paliwo',
        spendings_transportation_service_parts: 'Serwis i części samochodowe',
        spendings_transportation_public_transport: 'Transport publiczny',
        spendings_transportation_taxi: 'Taxi/Uber',
        spendings_transportation_car_wash: 'Myjnia samochodowa',
        spendings_transportation_parking: 'Parking',
        spendings_transportation_traffic_fines: 'Mandat',
        spendings_food: 'Jedzenie',
        spendings_food_groceries: 'Artykuły spożywcze',
        spendings_food_coffee_shops: 'Kawiarnia',
        spendings_food_restaurants: 'Restauracja',
        spendings_food_alcohol: 'Alkohol',
        spendings_shopping: 'Zakupy',
        spendings_shopping_clothing: 'Ubrania',
        spendings_shopping_books: 'Ksiązki',
        spendings_shopping_electronics: 'Elektronika i oprogramowanie',
        spendings_shopping_hobbies: 'Hobby',
        spendings_shopping_sporting: 'Artykuły sportowe',
        spendings_shopping_cosmetics: 'Kosmetyki',
        spendings_medical: 'Medycyna/Opieka zdrowotna',
        spendings_medical_dentist: 'Dentysta',
        spendings_medical_doctor: 'Lekarz',
        spendings_medical_eye_doctor: 'Okulista',
        spendings_medical_pharmacy: 'Apteka',
        spendings_medical_health_insurance: 'Ubezpieczenie zdrowotne',
        spendings_medical_medications: 'Leki',
        spendings_insurance: 'Ubezpieczenie',
        spendings_insurance_life: 'Ubezpieczenie na życie',
        spendings_insurance_health: 'Ubezpieczenie zdrowotne',
        spendings_insurance_home: 'Ubezpieczenie domu',
        spendings_insurance_car: 'Ubezpieczenie samochodu',
        spendings_personal: 'Osobiste',
        spendings_personal_gym: 'Siłownia',
        spendings_personal_haircuts: 'Fryzjer',
        spendings_personal_nail: 'Paznokcie',
        spendings_personal_massage_spa: 'Masaż/Spa',
        spendings_personal_laundry: 'Pranie',
        spendings_debt: 'Dług',
        spendings_debt_personal: 'Pożyczki osobiste',
        spendings_debt_credit_cards: 'Karty kredytowe',
        spendings_education: 'Edukacja',
        spendings_education_tuition: 'Korepetycje',
        spendings_education_supplies: 'Przybory szkolne',
        spendings_educations_books: 'Książki',
        spendings_educations_student_loan: 'Pożyczka studencka',
        spendings_entertainment: 'Rozrywka',
        spendings_entertainment_arts: 'Sztuka',
        spendings_entertainment_music: 'Muzyka',
        spendings_entertainment_games: 'Gry',
        spendings_entertainment_movies: 'Filmy',
        spendings_entertainment_theatre: 'Teatr',
        spendings_entertainment_concerts: 'Koncerty',
        spendings_entertainment_news_subscriptions:
          'Subskrypcje wiadomości/czasopism',
        spendings_entertainment_music_subscriptions: 'Subskrypcja muzyki',
        spendings_entertainment_streaming_subscriptions: 'Serwisy stramingowe',
        spendings_travel: 'Podróże',
        spendings_travel_vacation: 'Wakacje',
        spendings_travel_flights: 'Loty',
        spendings_travel_hotel: 'Hotele',
        spendings_kids: 'Dzieci',
        spendings_kids_babysitter: 'Opiekunka/żłobek',
        spendings_kids_school_tuition: 'Czesne',
        spendings_kids_activities: 'Zajęcia dodatkowe',
        spendings_kids_sports: 'Sport',
        spendings_kids_toys: 'Zabawki',
        spendings_kids_baby_supplies: 'Artykuły dziecięce',
        spendings_kids_child_support: 'Wsparcie dzieci',
        spendings_pets: 'Zwierzęta',
        spendings_pets_food: 'Jedzenie',
        spendings_pets_veterinary: 'Opieka weterynaryjna',
        spendings_pets_medications: 'Leki',
        spendings_donations: 'Prezenty i darowizny',
        spendings_donations_gift: 'Prezenty',
        spendings_donations_charity: 'Dobroczynność',
        spendings_other: 'Inne',
      },
    },
    calculator: {
      disclaimer:
        'Kalkulator jest przeznaczony do celów edukacyjnych i nie powinien być traktowany jako porada finansowa lub inwestycyjna.',
      savings_goal_calculator: 'Kalkulator oszczędzania',
      mortgage_loan_calculator: 'Kalkulator kredytu hipotecznego',
      compound_interest_calculator: 'Kalkulator procentu składanego',
      rate_return_calculator: 'Kalkulator stopy zwrotu',
      savings_retirement_calculator: 'Kalkulator oszczędzania na emeryturę',
      loan_refinance_calculator: 'Kalkulator refinansowania kredytu',
      you_need_save: 'Powinieneś oszczędzać',
      for_n_months_to_reach_goal: 'przez %{count} miesięcy aby osiągnąć cel',
      for_1_month_to_reach_goal: 'przez 1 miesiąc aby osiągnąć cel',
      savings_goal: 'Cel oszczędnościowy',
      starting_balance: 'Bilans początkowy',
      ending_balance: 'Bilans końcowy',
      period: 'Okres',
      annual_interest_rate: 'Oprocentowanie w stosunku rocznym (%)',
      daily: 'Dziennie',
      net_savings: 'Oszczeności netto',
      interest: 'Odsetki',
      months: 'Miesięcy',
      years: 'Lat',
      monthly: 'Miesięcznie',
      annual: 'Rocznie',
      loan_amount: 'Kwota kredytu',
      loan_term_years: 'Okres kredytowania w latach',
      other: 'Inny',
      your_total_payment: 'Twoja rata wyniesie',
      for_n_months: 'przez %{count} miesięcy',
      total_payments: 'Łącznie do spłaty',
      total_interest: 'Odsetki',
      additional_monthly_contribution: 'Dodatkowe miesięczne wpłaty',
      contribution_amount: 'Kwota wpłat',
      year_contribution: 'Lata dodatkowych wpłat',
      contribution_frequency: 'Częstotliwość wpłat',
      principal: 'Wartość początkowa',
      you_will_have: 'Uzbierasz',
      after_n_months: 'Po %{count} miesiącach',
      initial_balance: 'Kwota początkowa',
      total_contributions: 'Dodatkowe wpłaty',
      interest_calculated_month: 'Miesięczna kapitalizacja odsetek',
      how_long_contributions:
        'Ile lat planujesz wpłacać dodatkowe kwoty, puste pole oznacza że kalkulacja zostanie wykonana dla całego okresu.',
      years_to_save: 'Liczba lat',
      compound_annual_growth_rate: 'Roczna stopa zwrotu',
      number_years: 'Liczba lat',
      current_loan: 'Aktualna pożyczka',
      new_loan: 'Nowa pożyczka',
      remaining_amount: 'Pozostała kwota do spłaty',
      monthly_payment: 'Miesięczna rata',
      remaining_months: 'Ilość pozostałych miesięcy',
      refinance_amount: 'Kwota nowej pożyczki',
      interest_rate: 'Oprocentowanie',
      remaining_payments: 'Raty do spłaty',
      total_cost_difference: 'Całkowita różnica kosztu',
      difference: 'Rożnica',
      current: 'Aktualna',
      new: 'Nowa',
    },
  },
};
