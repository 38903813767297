import _ from 'lodash';
import React, { useState, useMemo } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import debug from 'utils/debug';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { default as MuiTooltip } from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import HelpIcon from '@material-ui/icons/Help';
import * as budgetModulesQueries from 'modules/budget/queries';
import * as localeModelQueries from 'models/locale/queries';
import {
  SAVINGS,
  SPENDINGS,
  INCOMES,
  TABLE_VARIANTS,
} from 'modules/budget/constants';
import BudgetYearOverviewVerticalBarChart from './budgetYearOverviewCharts/BudgetYearOverviewVerticalBarChart';
import BudgetYearOverviewBarChart from './budgetYearOverviewCharts/BudgetYearOverviewBarChart';
import BudgetYearOverviewPieChart from './budgetYearOverviewCharts/BudgetYearOverviewPieChart';
import BudgetYearOverviewAccordion from './budgetYearOverviewAccordion/BudgetYearOverviewAccordion';
import BudgetYearOverviewCategoriesSum from './budgetYearOverviewCategoriesSum/BudgetYearOverviewCategoriesSum';
import * as queries from './queries';

const DEBUG_PATH = 'modules/budget/BudgetYearOverview';
const NUMBER_PLACEHOLDER = '-';

const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing(1),
    position: 'relative',
    zIndex: 2,
    '& > .MuiGrid-item': {
      position: 'relative',
      '&:nth-child(1)': {
        zIndex: 13,
      },
      '&:nth-child(2)': {
        zIndex: 12,
      },
      '&:nth-child(3)': {
        zIndex: 12,
      },
      '&:nth-child(4)': {
        zIndex: 10,
      },
    },
  },
  table: {
    '& td, & th': {
      borderColor: theme.custom.color.primaryLight,
    },
    '& tr:last-child': {
      '& th, & td': {
        border: 'none',
      },
    },
  },
  tableRowSummary: {
    '& span': {
      fontWeight: 'bold',
    },
  },
  balanceCardContent: {
    '&:last-child': {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    },
  },
  helpIcon: {
    opacity: 0.63,
    fontSize: '1rem',
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: 5,
    position: 'relative',
    top: -1,
  },
  balancePos: {
    color: theme.custom.color.primary,
  },
  balanceNeg: {
    color: theme.custom.color.secondary,
  },
  totalAmount: {
    '.Mui-expanded &': {
      visibility: 'hidden',
    },
  },
  summary: {
    paddingRight: theme.spacing(2),
  },
  summaryBalance: {
    '.Mui-expanded &': {
      visibility: 'hidden',
    },
  },
  pieChartWrapper: {
    width: '100%',
    paddingTop: '60%',
    position: 'relative',
    zIndex: 999,
    top: -theme.spacing(2),
  },
  pieChart: {
    position: 'absolute !important',
    left: 0,
    top: 0,
    zIndex: 1000,
    '& text': {
      fontSize: 13,
    },
  },
  pieChartBig: {
    '& text': {
      fontSize: 15,
    },
  },
  summaryCardContent: {
    '&, &:last-child': {
      paddingBottom: theme.spacing(1),
      paddingLeft: 0,
    },
  },
  tileTitle: {
    fontWeight: 500,
  },
  summaryTitle: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(5),
    fontWeight: 500,
  },
  overviewTitle: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    fontWeight: 500,
  },
  summarySubtitle: {
    marginTop: -theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    textAlign: 'center',
  },
  overviewSubtitle: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: 0,
    textAlign: 'center',
  },
});

const BudgetYearOverview = ({
  activeYear,
  budgetYearOverview,
  budgetCurrency,
  isVertical,
  expandedSummaryAccordions,
  setExpandedSummaryAccordions,
  classes,
}) => {
  debug(DEBUG_PATH, 'render');

  const [isSummaryMouseOver, setIsSummaryMouseOver] = useState(false);

  const colsSum = budgetYearOverview?.colsSum?.[budgetCurrency] || {};
  const categoriesSum =
    budgetYearOverview?.categoriesSum?.[budgetCurrency] || {};
  const variantsSum = budgetYearOverview?.variantsSum?.[budgetCurrency] || {};
  const balanceValue = budgetModulesQueries.getBalanceValue(
    variantsSum[INCOMES],
    variantsSum[SPENDINGS]
  );

  const isSavingsAvailable = variantsSum[SAVINGS] > 0;
  const isHorizonstalAndNoSavings = !isVertical && !isSavingsAvailable;
  const colSize = isVertical ? 12 : isSavingsAvailable ? 3 : 4;

  const currencyDetails = useMemo(
    () => localeModelQueries.getCurrencyDetails(budgetCurrency),
    [budgetCurrency]
  );

  const currencySettings = useMemo(() => {
    return localeModelQueries.getCurrencySettings({
      currencyDetails,
      allowNegative: true,
    });
  }, [currencyDetails]);

  const balanceClass = classNames(
    balanceValue > 0
      ? classes.balancePos
      : balanceValue < 0
        ? classes.balanceNeg
        : null
  );

  const pieChartClass = classNames(
    classes.pieChart,
    isHorizonstalAndNoSavings ? classes.pieChartBig : null
  );

  const renderNumber = (numberValue, numberClass) =>
    _.isNumber(numberValue) ? (
      <NumberFormat
        className={numberClass}
        value={numberValue}
        displayType="text"
        {...currencySettings}
      />
    ) : (
      NUMBER_PLACEHOLDER
    );

  const renderPieChart = (variant, totalAmount) => {
    if (!totalAmount) {
      return null;
    }

    const data = queries.getPieChartData(categoriesSum[variant]);

    return (
      <div className={classes.pieChartWrapper}>
        <BudgetYearOverviewPieChart
          data={data}
          variant={variant}
          totalAmount={totalAmount}
          pieChartClass={pieChartClass}
          currencyDetails={currencyDetails}
        />
      </div>
    );
  };

  const renderBarChart = () => {
    const data = queries.getBarChartData(colsSum, activeYear);
    return (
      <BudgetYearOverviewBarChart
        data={data}
        currencyDetails={currencyDetails}
      />
    );
  };

  const renderVerticalBarChart = () => {
    const data = queries.getVerticalBarChartData(
      variantsSum,
      balanceValue,
      currencyDetails
    );

    return (
      <BudgetYearOverviewVerticalBarChart
        data={data}
        renderNumber={renderNumber}
      />
    );
  };

  const renderSummary = () => (
    <>
      <Grid item xs={12}>
        <Card>
          <CardContent className={classes.summaryCardContent}>
            <Typography className={classes.summaryTitle} variant="body1">
              <Translate value="budget.year_summary" />
            </Typography>
            {renderVerticalBarChart()}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent className={classes.summaryCardContent}>
            <Typography className={classes.overviewTitle} variant="body1">
              <Translate value="budget.overview_by_month" />
            </Typography>
            {renderBarChart()}
          </CardContent>
        </Card>
      </Grid>
    </>
  );

  const renderTileSummary = () => {
    const accordionSummary = (
      <div
        className={classes.summary}
        onMouseEnter={() => setIsSummaryMouseOver(true)}
        onMouseLeave={() => setIsSummaryMouseOver(false)}
      >
        <Typography className={classes.tileTitle} gutterBottom>
          <Translate value="budget.summary" />
        </Typography>
        <Typography
          className={classes.summaryBalance}
          variant="body2"
          display="block"
        >
          <Translate value="common.net" />:{' '}
          {renderNumber(balanceValue, balanceClass)}
          {isSummaryMouseOver && (
            <MuiTooltip
              title={I18n.t('budget.balance_tooltip')}
              placement="top"
            >
              <HelpIcon className={classes.helpIcon} fontSize="small" />
            </MuiTooltip>
          )}
        </Typography>
      </div>
    );

    const accordionDetails = (
      <>
        <Typography className={classes.summarySubtitle} variant="h6">
          <Translate value="budget.year_summary" />
        </Typography>
        {renderVerticalBarChart()}
        <Typography className={classes.overviewSubtitle} variant="h6">
          <Translate value="budget.overview_by_month" />
        </Typography>
        {renderBarChart()}
      </>
    );

    return (
      <Grid
        key="summary"
        item
        xs={12}
        md={6}
        lg={!isVertical && isSavingsAvailable ? 6 : colSize}
        xl={colSize}
      >
        <BudgetYearOverviewAccordion
          isVertical={isVertical}
          variant="summary"
          accordionSummary={accordionSummary}
          accordionDetails={accordionDetails}
          expandedSummaryAccordions={expandedSummaryAccordions}
          setExpandedSummaryAccordions={setExpandedSummaryAccordions}
        />
      </Grid>
    );
  };

  const renderTile = (variant) => {
    if (variant === SAVINGS && !isSavingsAvailable) {
      return null;
    }

    const totalAmount = variantsSum[variant];

    const accordionSummary = (
      <>
        <Typography className={classes.tileTitle} gutterBottom>
          <Translate value={`budget.${variant}`} />
        </Typography>
        <Typography
          className={classes.totalAmount}
          variant="body2"
          display="block"
        >
          {renderNumber(totalAmount)}
        </Typography>
      </>
    );

    const accordionDetails = (
      <Table className={classes.table} size="small">
        <TableBody>
          <TableRow>
            <TableCell colSpan={3}>
              {renderPieChart(variant, totalAmount)}
            </TableCell>
          </TableRow>
          <BudgetYearOverviewCategoriesSum
            variant={variant}
            totalAmount={totalAmount}
            categoriesSum={categoriesSum}
            renderNumber={renderNumber}
          />
          <TableRow
            className={classes.tableRowSummary}
            key={`sum-row-${variant}`}
          >
            <TableCell component="th" scope="row">
              <Translate value="common.total" />
            </TableCell>
            <TableCell align="right" />
            <TableCell align="right">
              <Typography variant="subtitle2" component="span" noWrap>
                {renderNumber(totalAmount)}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );

    return (
      <Grid
        key={variant}
        item
        xs={12}
        md={6}
        lg={!isVertical && isSavingsAvailable ? 6 : colSize}
        xl={colSize}
      >
        <BudgetYearOverviewAccordion
          isVertical={isVertical}
          variant={variant}
          accordionSummary={accordionSummary}
          accordionDetails={accordionDetails}
          expandedSummaryAccordions={expandedSummaryAccordions}
          setExpandedSummaryAccordions={setExpandedSummaryAccordions}
        />
      </Grid>
    );
  };

  return (
    <Grid
      className={classes.root}
      container
      spacing={2}
      data-test-id="budget-year-overview"
    >
      {isVertical ? renderSummary() : renderTileSummary()}
      {_.map(TABLE_VARIANTS, (variant) => {
        return renderTile(variant);
      })}
    </Grid>
  );
};

export default withStyles(styles)(BudgetYearOverview);
