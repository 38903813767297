import _ from 'lodash';
import axios from 'axios';
import debug from 'utils/debug';
import { setLocale } from 'react-redux-i18n';
import { fetchMessage } from 'models/message/actions';
import { FETCH_USER } from 'models/auth/actionTypes';

const DEBUG_PATH = 'models/locale/actions';

export const updateLocale = (locale) => (dispatch) => {
  const storedLocale = localStorage.getItem('locale');

  if (!locale) {
    return;
  }

  if (locale !== storedLocale) {
    dispatch(setLocale(locale));
    localStorage.setItem('locale', locale);
  }
};

export const changeLocale = (newLocale) => async (dispatch) => {
  try {
    debug(DEBUG_PATH, 'changeLocale');

    dispatch(updateLocale(newLocale));
    const res = await axios.put('/api/locale', { locale: newLocale });
    dispatch({ type: FETCH_USER, payload: res.data });
  } catch (err) {
    dispatch(fetchMessage(_.get(err, 'response.data.message')));
    debug(DEBUG_PATH, 'changeLocale: error', err);
  }
};
