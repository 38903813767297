import _ from 'lodash';
import React from 'react';
import { Translate } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const styles = () => ({});

const BudgetTemplateSelector = ({
  label,
  templates,
  templateValue,
  handleTemplateValueChange,
}) => {
  return (
    <FormControl variant="outlined" margin="dense" fullWidth>
      <InputLabel id="select-template-label">{label}</InputLabel>
      <Select
        labelId="select-template-label"
        label={label}
        value={templateValue}
        onChange={handleTemplateValueChange}
        inputProps={{ 'aria-label': 'Budget Template' }}
      >
        <MenuItem value="">
          <Translate value={'budget.budget_template_none'} />
        </MenuItem>
        {_.map(templates, ({ id, name, variant }) => (
          <MenuItem key={id} value={id}>
            <Translate value={name ? name : `budget.${variant}`} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default withStyles(styles)(BudgetTemplateSelector);
