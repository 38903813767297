export const APP_LINKS = {
  en: {
    email: 'contact@wallettbudgetplanner.com',
    website: 'https://wallettbudgetplanner.com',
    privacyPolicy: 'https://wallettbudgetplanner.com/privacy-policy',
    termsConditions: 'https://wallettbudgetplanner.com/terms-and-conditions',
    cookies: 'https://wallettbudgetplanner.com/cookie-policy',
  },
  pl: {
    email: 'contact@wallettbudgetplanner.com',
    website: 'https://wallettbudgetplanner.com',
    privacyPolicy: 'https://wallettbudgetplanner.com/privacy-policy',
    termsConditions: 'https://wallettbudgetplanner.com/terms-and-conditions',
    cookies: 'https://wallettbudgetplanner.com/cookie-policy',
  },
};
