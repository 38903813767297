import { connect } from 'react-redux';
import * as budgetModelSelectors from 'models/budget/selectors';
import * as localeModelSelectors from 'models/locale/selectors';
import * as budgetModelActions from 'models/budget/actions';
import * as localeModelQueries from 'models/locale/queries';
import BudgetsOverview from './BudgetsOverview';

const mapStateToProps = (state) => {
  const activeLocale = localeModelSelectors.getActiveLocale(state);
  const defaultCurrency = localeModelQueries.getDefaultCurrency(activeLocale);

  return (state) => {
    return {
      defaultCurrency: defaultCurrency,
      budgetsList: budgetModelSelectors.getBudgetsList(state),
    };
  };
};

const mapDispatchToProps = {
  fetchBudgets: budgetModelActions.fetchBudgets,
  onUpdateBudget: budgetModelActions.updateBudget,
  onDeleteBudget: budgetModelActions.deleteBudget,
};

export default connect(mapStateToProps, mapDispatchToProps)(BudgetsOverview);
