import _ from 'lodash';
import React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { withStyles } from '@material-ui/core/styles';
import * as budgetModulesQueries from 'modules/budget/queries';
import * as localeModelQueries from 'models/locale/queries';
import { CHART_COLORS } from 'modules/budget/constants';

// const data = [
//   {
//     name: key,
//     value: value,
//   },
// ];

const styles = (theme) => ({
  root: {
    '& .recharts-tooltip-wrapper': {
      outline: 'none',
    },
  },
  tooltip: {
    background: '#ffffffc4',
    border: '1px solid #ccc',
    outline: 'none',
    borderRadius: 3,
    padding: theme.spacing(1),
    margin: 5,
  },
  row: {
    minWidth: 130,
    marginTop: theme.spacing(0.25),
    marginBottom: 0,
    '&:first-child': {
      marginTop: 0,
    },
  },
});

const BudgetYearOverviewPieChart = ({
  data,
  variant,
  totalAmount,
  pieChartClass,
  currencyDetails,
  classes,
}) => {
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, value } = payload[0];
      const percentage = budgetModulesQueries.getPercentage(
        value,
        totalAmount,
        0
      );

      return (
        <div className={classes.tooltip}>
          <p className={classes.row}>
            <strong>
              <Translate value={`budget_category.${variant}.${name}`} />
            </strong>
          </p>
          <p className={classes.row}>
            {`${localeModelQueries.getFormattedAmount(value, currencyDetails)}`}{' '}
            ({percentage}%)
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer
      className={classes.root}
      width="99.9%" // hack to make responsiveness working
      height="99.9%" // hack to make responsiveness working
      aspect={1.75}
      debounce={1}
    >
      <PieChart
        className={pieChartClass}
        width={500}
        height={500}
        isAnimationActive={false}
      >
        <Pie
          data={data}
          dataKey="value"
          cx="50%"
          cy="50%"
          labelLine={true}
          isAnimationActive={false}
          animationDuration={0}
          innerRadius="40%"
          outerRadius="65%"
          label={({ name }) => {
            return _.truncate(
              `${I18n.t(`budget_category.${variant}.${name}`)}`,
              {
                length: 20,
              }
            );
          }}
        >
          {_.map(data, (entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={CHART_COLORS[index % CHART_COLORS.length]}
            />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default withStyles(styles)(BudgetYearOverviewPieChart);
