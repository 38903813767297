import _ from 'lodash';
import axios from 'axios';
import { I18n } from 'react-redux-i18n';
import debug from 'utils/debug';
import { fetchMessage } from 'models/message/actions';
import {
  ADD_ROW,
  FETCH_YEAR,
  UPDATE_ROW,
  UPDATE_ROWS,
  UPDATE_YEAR,
  TRIGGER_MOVE_ROW,
  UPDATE_TABLE,
} from './actionTypes';

const DEBUG_PATH = 'models/budget/actionsRow';

export const updateRowState = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_ROW,
    payload: data,
  });
};

export const addRow =
  (tableId, data = {}) =>
    async (dispatch) => {
      debug(DEBUG_PATH, 'addRow', {
        tableId,
        data,
      });

      try {
        const res = await axios.post('/api/budget/row', {
          tableId,
          data,
        });

        debug(DEBUG_PATH, 'addRow: added', {
          data: res.data,
        });

        dispatch({
          type: UPDATE_TABLE,
          payload: res.data?.table,
        });

        dispatch({
          type: ADD_ROW,
          payload: res.data?.row,
        });
      } catch (err) {
        debug(DEBUG_PATH, 'addRow: error', {
          tableId,
          data,
        });

        dispatch(fetchMessage(I18n.t('app.message.cant_fetch_budget')));
      }
    };

export const triggerMoveRow = (rowId, direction) => async (dispatch) => {
  debug(DEBUG_PATH, 'moveRow', {
    rowId,
    direction,
  });

  dispatch({
    type: TRIGGER_MOVE_ROW,
    payload: {
      rowId,
      direction,
    },
  });
};

export const updateRow =
  (rowId, data = {}) =>
    async (dispatch) => {
      debug(DEBUG_PATH, 'updateRow', {
        rowId,
        data,
      });

      dispatch({
        type: UPDATE_ROW,
        payload: {
          _id: rowId,
          ...data,
        },
      });

      try {
        const res = await axios.patch('/api/budget/row', {
          rowId,
          data,
        });

        debug(DEBUG_PATH, 'updateRow: updated', {
          data: res.data,
        });

        dispatch({
          type: UPDATE_ROW,
          payload: res.data?.row,
        });

        dispatch({
          type: UPDATE_YEAR,
          payload: res.data?.year,
        });
      } catch (err) {
        debug(DEBUG_PATH, 'updateRow: error', {
          rowId,
          data,
        });

        dispatch(fetchMessage(err));
      }
    };

export const updateRows =
  (rowIds, data = {}) =>
    async (dispatch) => {
      debug(DEBUG_PATH, 'updateRows', {
        rowIds,
        data,
      });

      dispatch({
        type: UPDATE_ROWS,
        payload: {
          rows: _.reduce(rowIds, (rowsArr, rowId) => {
            return [
              ...rowsArr,
              {
                _id: rowId,
                ...data
              }
            ];
          }, [])
        },
      });

      try {
        const res = await axios.patch('/api/budget/rows', {
          rowIds,
          data,
        });

        debug(DEBUG_PATH, 'updateRows: updated', {
          data: res.data,
        });

        dispatch({
          type: UPDATE_ROWS,
          payload: res.data?.rows,
        });

        dispatch({
          type: UPDATE_YEAR,
          payload: res.data?.year,
        });
      } catch (err) {
        debug(DEBUG_PATH, 'updateRows: error', {
          rowIds,
          data,
        });

        dispatch(fetchMessage(err));
      }
    };

export const deleteRows =
  (rowIds = []) =>
    async (dispatch) => {
      debug(DEBUG_PATH, 'deleteRows', {
        rowIds,
      });

      try {
        const res = await axios.delete(
          `/api/budget/rows?ids=${_.join(rowIds, ',')}`
        );

        debug(DEBUG_PATH, 'deleteRows: deleted', {
          rowIds,
        });

        dispatch({ type: FETCH_YEAR, payload: res.data });
      } catch (err) {
        debug(DEBUG_PATH, 'deleteRows: error', {
          rowIds,
        });

        dispatch(fetchMessage(I18n.t('app.message.cant_fetch_budget')));
      }
    };
