import React from 'react';
import classNames from 'classnames';
import { I18n } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { PERIOD_MONTHLY, PERIOD_YEARLY } from 'modules/calculator/constants';

const styles = (theme) => ({
  radioGroupWrapper: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
  },
  radioGroup: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.75),
    marginLeft: theme.spacing(1.25),
  },
});

const CalculatorPeriodType = ({
  value,
  onChange,
  label,
  monthLabel,
  yearLabel,
  classes,
}) => {
  const radioGroupWrapperClass = classNames(
    label ? classes.radioGroupWrapper : null
  );
  const radioGroupClass = classNames(label ? null : classes.radioGroup);

  return (
    <div className={radioGroupWrapperClass}>
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <RadioGroup
        className={radioGroupClass}
        aria-label="period"
        name="period"
        value={value}
        onChange={(event) => onChange(event.target.value)}
      >
        <Grid container>
          <Grid item>
            <FormControlLabel
              value={PERIOD_MONTHLY}
              control={<Radio size="small" />}
              label={monthLabel || I18n.t('calculator.months')}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              value={PERIOD_YEARLY}
              control={<Radio size="small" />}
              label={yearLabel || I18n.t('calculator.years')}
            />
          </Grid>
        </Grid>
      </RadioGroup>
    </div>
  );
};

export default withStyles(styles)(CalculatorPeriodType);
