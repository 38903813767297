import React from 'react';
import { withStyles, useTheme } from '@material-ui/core/styles';
import { I18n, Translate } from 'react-redux-i18n';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ChromeReaderModeOutlinedIcon from '@material-ui/icons/ChromeReaderModeOutlined';
import Avatar from '@material-ui/core/Avatar';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import BudgetPicker from 'modules/budget/budgetPicker/BudgetPicker';
import BudgetToolbarActionsMenu from './budgetToolbarActionsMenu/BudgetToolbarActionsMenu';
import config from 'config.js';

const styles = (theme) => ({
  root: {
    width: '100%',
  },
  toolbar: {
    marginBottom: theme.spacing(1.5),
    alignItems: 'flex-start',
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      alignItems: 'center',
    },
  },
  actionsMenuWrapper: {
    textAlign: 'right',
  },
  actionsMenu: {
    marginRight: theme.spacing(0.25),
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(0.75),
      alignItems: 'center',
    },
  },
  share: {
    height: 40,
    padding: '0 25px 0 55px',
    lineHeight: '40px',
    borderRadius: 20,
    backgroundColor: theme.custom.color.primaryLight,
    position: 'relative',
    '&:hover': {
      backgroundColor: theme.custom.color.primaryLight,
    },
  },
  shareAvatar: {
    backgroundColor: theme.custom.color.primary,
    position: 'absolute',
    left: 0,
    top: 0,
  },
  user: {
    minWidth: 0,
    padding: 0,
    margin: 0,
    borderRadius: 20,
  },
  userAvatar: {
    backgroundColor: theme.custom.color.secondary,
  },
});

const BudgetToolbar = ({
  budgetsList,
  activeBudget,
  activeBudgetId,
  activeYearId,
  activeYear,
  activeBudgetYears,
  defaultCurrency,
  isSidebarVisible,
  setActiveYearId,
  setIsCreateBudgetDialogOpen,
  setIsCreateYearDialogOpen,
  onUpdateBudget,
  onDeleteBudget,
  onDeleteYear,
  onSetIsSidebarVisible,
  classes,
}) => {
  const theme = useTheme();
  const breakpointMd = useMediaQuery(theme.breakpoints.up('md'));
  const breakpointLg = useMediaQuery(theme.breakpoints.up('lg'));
  const isToggleSidebarButtonVisible =
    breakpointLg && activeYear._tables.length > 0;

  const renderToolbar = () => (
    <Grid container justifyContent="flex-end" alignItems="center" data-test-id="budget-toolbar">
      {isToggleSidebarButtonVisible && (
        <Grid item>
          <IconButton
            onClick={onSetIsSidebarVisible}
            title={I18n.t('common.toggle_sidebar')}
            color={isSidebarVisible ? 'primary' : 'default'}
          >
            <ChromeReaderModeOutlinedIcon fontSize="small" />
          </IconButton>
        </Grid>
      )}
      <Grid item className={classes.actionsMenu}>
        <BudgetToolbarActionsMenu
          activeBudgetId={activeBudgetId}
          activeBudget={activeBudget}
          activeBudgetYears={activeBudgetYears}
          activeYearId={activeYearId}
          activeYear={activeYear}
          defaultCurrency={defaultCurrency}
          onUpdateBudget={onUpdateBudget}
          onDeleteBudget={onDeleteBudget}
          onDeleteYear={onDeleteYear}
          isDeleteYearButtonAvailable
        />
      </Grid>
    </Grid>
  );

  return (
    <Toolbar className={classes.root} disableGutters>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        className={classes.toolbar}
      >
        {!breakpointMd && (
          <Grid item xs={12} className={classes.actionsMenuWrapper}>
            {renderToolbar()}
          </Grid>
        )}
        <Grid item xs={breakpointMd ? 8 : 12}>
          <Grid container spacing={2}>
            <Grid item>
              <BudgetPicker
                budgetsList={budgetsList}
                activeBudgetId={activeBudgetId}
                activeYearId={activeYearId}
                activeBudgetYears={activeBudgetYears}
                setActiveYearId={setActiveYearId}
                setIsCreateBudgetDialogOpen={setIsCreateBudgetDialogOpen}
                setIsCreateYearDialogOpen={setIsCreateYearDialogOpen}
              />
            </Grid>
            {config.appFeatures.isBudgetSharingEnabled && (
              <Grid item>
                <Grid container spacing={1}>
                  {false && (
                    <Grid item>
                      <Button className={classes.user}>
                        <Avatar className={classes.userAvatar}>M</Avatar>
                      </Button>
                    </Grid>
                  )}
                  <Grid item>
                    <Button className={classes.share}>
                      <Avatar className={classes.shareAvatar}>
                        <PersonAddIcon fontSize="small" />
                      </Avatar>
                      <Translate value="budget.budget_share" />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        {breakpointMd && (
          <Grid item xs={4} className={classes.actionsMenuWrapper}>
            {renderToolbar()}
          </Grid>
        )}
      </Grid>
    </Toolbar>
  );
};

export default withStyles(styles)(BudgetToolbar);
