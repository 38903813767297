import React from 'react';
import { useHistory } from 'react-router-dom';
import { Translate } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AuthExternalButtons from 'components/authExternalButtons/AuthExternalButtons';
import LineSeparator from 'components/lineSeparator/LineSeparator';
import SignupForm from './signupForm/SignupForm';
import { BUDGET_PATH } from 'constants.js';
import globalStyles from 'globalStyles.js';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(4),
    padding: theme.custom.wrapper.padding,
  },
});

const Signup = ({ isAuthenticated, onSignup, classes }) => {
  let history = useHistory();

  const globalClasses = globalStyles();

  if (isAuthenticated === null) {
    return null;
  }

  if (isAuthenticated) {
    history.push(BUDGET_PATH);
  }

  return (
    <div className={classes.root} data-test-id="signup">
      <Grid container justifyContent="center">
        <Grid item className={globalClasses.containerXs}>
          <Grid container spacing={4}>
            <Grid item xs>
              <Card className={classes.paper}>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    <Translate value="auth.sign_up" />
                  </Typography>
                  <SignupForm onSignup={onSignup} />
                  <LineSeparator />
                  <AuthExternalButtons />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Signup);
