export default {
  en: {
    app: {
      title: 'wallett',
      subtitle: 'Budget Planner & Tracker',
      home_page: 'Home Page',
      account: 'Account',
      account_settings: 'Account settings',
      budget: 'Budget',
      budget_new: 'Create a new budget',
      budgets_overview: 'Budgets overview',
      calculators: 'Calculators',
      profile: 'Profile',
      message: {
        cant_fetch_user_data: "We can't fetch user data",
        cant_fetch_budget: "We can't fetch budget",
        entered_invalid_username_password:
          'You have entered either invalid username or password',
        account_created: 'Your account has been created',
        Auth_Email_Sent_To: 'Email has been sent to %{email}',
        password_successfully_changed:
          'Your password has been successfully changed',
        email_successfully_changed: 'Your email has been successfully changed',
        account_closed_successfully:
          'Your account has been closed successfully',
        budget_successfully_deleted: 'Budget has been deleted',
        year_successfully_deleted: 'Year has been deleted',
        year_successfully_created: 'Year has been created',
      },
    },
    footer: {
      website: 'Website',
      privacy_policy: 'Privacy Policy',
      terms_and_conditions: 'Terms & Conditions',
      cookies: 'Cookies',
      contact_us: 'Contact us',
    },
    locale: {
      en: 'EN',
      pl: 'PL',
    },
    common: {
      settings: 'Settings',
      confirm: 'Confirm',
      save: 'Save',
      cancel: 'Cancel',
      clear: 'Clear',
      delete: 'Delete',
      delete_item: 'Delete item',
      add: 'Add',
      total: 'Total',
      balance: 'Balance',
      net: 'Net',
      or: 'or',
      price: 'Price',
      toggle_sidebar: 'Toggle sidebar',
      open_actions_menu: 'Open actions menu',
      country: 'Country',
      back: 'Back',
      next: 'Next',
      finsh: 'Finish',
      share: 'Share',
    },
    date: {
      long: 'MMMM Do, YYYY',
      month: 'month',
      week: 'week',
      day: 'day',
      months: {
        0: 'January',
        1: 'February',
        2: 'March',
        3: 'April',
        4: 'May',
        5: 'June',
        6: 'July',
        7: 'August',
        8: 'September',
        9: 'October',
        10: 'November',
        11: 'December',
      },
      months_short: {
        0: 'Jan',
        1: 'Feb',
        2: 'Mar',
        3: 'Apr',
        4: 'May',
        5: 'Jun',
        6: 'Jul',
        7: 'Aug',
        8: 'Sep',
        9: 'Oct',
        10: 'Nov',
        11: 'Dec',
      },
      days: {
        0: 'Monday',
        1: 'Tuesday',
        2: 'Wednesday',
        3: 'Thursday',
        4: 'Friday',
        5: 'Saturday',
        6: 'Sunday',
      },
      days_short: {
        0: 'Mon',
        1: 'Tue',
        2: 'Wedn',
        3: 'Thu',
        4: 'Fri',
        5: 'Sat',
        6: 'Sun',
      },
    },
    currency: {
      USD: {
        name: 'US Dollar',
        name_plural: 'US dollars',
      },
      CAD: {
        name: 'Canadian Dollar',
        name_plural: 'Canadian dollars',
      },
      EUR: {
        name: 'Euro',
        name_plural: 'Euros',
      },
      AED: {
        name: 'United Arab Emirates Dirham',
        name_plural: 'UAE dirhams',
      },
      AUD: {
        name: 'Australian Dollar',
        name_plural: 'Australian dollars',
      },
      BGN: {
        name: 'Bulgarian Lev',
        name_plural: 'Bulgarian leva',
      },
      CHF: {
        name: 'Swiss Franc',
        name_plural: 'Swiss francs',
      },
      CNY: {
        name: 'Chinese Yuan',
        name_plural: 'Chinese yuan',
      },
      CZK: {
        name: 'Czech Republic Koruna',
        name_plural: 'Czech Republic korunas',
      },
      DKK: {
        name: 'Danish Krone',
        name_plural: 'Danish kroner',
      },
      GBP: {
        name: 'British Pound Sterling',
        name_plural: 'British pounds sterling',
      },
      HKD: {
        name: 'Hong Kong Dollar',
        name_plural: 'Hong Kong dollars',
      },
      HRK: {
        name: 'Croatian Kuna',
        name_plural: 'Croatian kunas',
      },
      HUF: {
        name: 'Hungarian Forint',
        name_plural: 'Hungarian forints',
      },
      ILS: {
        name: 'Israeli New Sheqel',
        name_plural: 'Israeli new sheqels',
      },
      JPY: {
        name: 'Japanese Yen',
        name_plural: 'Japanese yen',
      },
      MXN: {
        name: 'Mexican Peso',
        name_plural: 'Mexican pesos',
      },
      NOK: {
        name: 'Norwegian Krone',
        name_plural: 'Norwegian kroner',
      },
      NZD: {
        name: 'New Zealand Dollar',
        name_plural: 'New Zealand dollars',
      },
      PLN: {
        name: 'Polish Zloty',
        name_plural: 'Polish zlotys',
      },
      RON: {
        name: 'Romanian Leu',
        name_plural: 'Romanian lei',
      },
      RSD: {
        name: 'Serbian Dinar',
        name_plural: 'Serbian dinars',
      },
      RUB: {
        name: 'Russian Ruble',
        name_plural: 'Russian rubles',
      },
      SEK: {
        name: 'Swedish Krona',
        name_plural: 'Swedish kronor',
      },
      SGD: {
        name: 'Singapore Dollar',
        name_plural: 'Singapore dollars',
      },
      THB: {
        name: 'Thai Baht',
        name_plural: 'Thai baht',
      },
      TRY: {
        name: 'Turkish Lira',
        name_plural: 'Turkish Lira',
      },
      ZAR: {
        name: 'South African Rand',
        name_plural: 'South African rand',
      },
    },
    auth: {
      sign_in: 'Sign In',
      sign_in_google: 'Continue with Google',
      sign_in_facebook: 'Continue with Facebook',
      sign_up: 'Sign Up',
      sign_out: 'Sign Out',
      create_account: 'Create an account',
      email: 'Email',
      password: 'Password',
      new_password: 'New Password',
      confirm_password: 'Confirm Password',
      forgot_password_q: 'Forgot Password?',
      reset_my_password: 'Reset my password',
      reset_your_password: 'Reset your password',
      requested_reset_password_1: 'You have requested to reset the password',
      requested_reset_password_2: 'for:',
      send_password_reset_email: 'Reset password',
      enter_password: 'Enter your password',
      enter_password_confirmation: 'Enter password confirmation',
      enter_email: 'Enter your email',
      email_is_invalid: 'This emails address is invalid',
      use_n_characters: 'Use at least %{number} Characters',
      password_must_match: 'Password must match',
      password_conditions:
        'The password must be at least %{number} characters long, with uppercase and lowercase letters and at least one number',
      password_must_not_have_whitespace: 'The password cannot contain spaces',
      password_must_have_upparcase:
        'Password must contain at least one uppercase letter',
      password_must_have_lowercase:
        'Password must contain at least one lowercase letter',
      password_must_contain_digit: 'Password must contain at least one number',
      two_factor_authentication: 'Two-factor authentication',
      two_factor_authentication_description:
        'Two-factor authentication adds an additional layer of security to your account by requiring more than just a password to sign in.',
      enable_2fa: 'Enable',
      enabled_2fa: 'Enabled',
      disabled_2fa: 'Disabled',
    },
    account: {
      plan: 'Plan',
      change_plan: 'Change plan',
      email: 'Email',
      your_email_is_identity:
        'Your email address is your identity on Wallet and is used to log in.',
      password: 'Password',
      enter_your_email: 'Enter your email address',
      current_password: 'Current Password',
      current_password_if_set: 'Current Password',
      new_password: 'New Password',
      confim_new_password: 'Confirm New Password',
      use_n_characters: 'Use at least %{number} characters',
      password_must_match: 'Password must match',
      enter_new_password: 'Please enter new password',
      enter_new_email: 'Please enter new email address',
      enter_new_password_confirmation: 'Please enter new password confirmation',
      enter_all_to_change_password: 'Enter all fields to change your password.',
      close_account: 'Close Account',
      warning: 'Warning',
      to_verify_type_phrase: "To verify, type '%{phrase}' here",
      closing_your_app_irreversible: 'Closing your account is irreversible!',
      delete_absolutely_everything:
        'You are going to delete absolutely everything you have ever done here. This cannot be undone, so be very sure.',
      confirmation_phrase: 'close my account',
      enter_phrase: 'Please enter phrase above to continue',
      enter_correct_phrase: 'Please enter correct phrase to continue',
    },
    profile: {
      location: 'Location',
      name: 'Name',
      default_currency: 'Default Currency',
      city: 'City',
      country: 'Country',
      zipcode: 'Zip Code',
      details: 'Details',
      settings: 'Settings',
      name_too_long: 'Name is too long',
    },
    subscription: {
      compare_plans: 'Compare our plans',
      review_plan: 'Review your plan',
      summary: 'Summary',
      budget_customization: 'Budget Customization',
      income_and_expanse_tracking: 'Income and Expense Tracking',
      no_adverts: 'No advertisements',
      more_than_one_budget: 'Create more than one budget',
      free_support: 'Free Support',
      go_premium: 'Go Premium',
      upgrade: 'Upgrade',
      plan: 'Plan',
      subscription_type: {
        free: 'Free',
        premium: 'Premium',
      },
    },
    budget: {
      variant: 'Variant',
      incomes: 'Income',
      spendings: 'Spending',
      net: 'Net',
      summary: 'Summary',
      savings: 'Savings',
      currency: 'Currency',
      monthly: 'Monthlhy',
      quarterly: 'Quarterly',
      yearly: 'Yearly',
      custom: 'Custom',
      note: 'Note',
      add_note: 'Add Note',
      edit_note: 'Edit Note',
      clear: 'Clear',
      toggle_complete: 'Toggle Complete',
      move_up: 'Move Up',
      move_down: 'Move Down',
      category: 'Category',
      no_tables_information_title: "You don't have any tables yet.",
      no_tables_information_body:
        'Click icon below to add your first table in the budget year.',
      balance_tooltip: 'Net = Income - Spending',
      budget: 'Budget',
      budget_create: 'Create Budget',
      budget_create_new_info:
        'Configure your budget, you can create it from scratch or choose one of the predefined templates.',
      budget_edit: 'Edit Budget',
      budget_add: 'Add Budget',
      budget_update: 'Update Budget',
      budget_details: 'Budget Details',
      budget_details_edit: 'Edit Budget Details',
      budget_name: 'Budget Name',
      budget_delete: 'Delete Budget',
      budget_confirm_delete_title: 'Do you want to delete budget',
      budget_confirm_delete:
        'This operation cannot be undone. Each year, and table related to this budget will be permanently removed.',
      budgets_overview: 'Budgets Overview',
      budget_template: 'Budget Template',
      budget_template_none: 'None',
      budget_template_table: 'Table Template',
      budget_share: 'Share Budget',
      budget_export: 'Export',
      budget_export_year: 'Export Year Data',
      budget_export_options: 'Export options',
      budget_export_file_type: 'File type',
      overview_by_month: 'Overview by month',
      column_name: 'Name',
      full_year: 'Full Year',
      row_category: 'Category',
      row_more_options: 'More options',
      row_font_color: 'Font Color',
      row_background_color: 'Background Color',
      row_note: 'Note',
      row_name: 'Name',
      row_default_value: 'Default value',
      row_default_amount: 'Default amount',
      row_custom_category: 'Custom Category',
      row_delete: 'Delete Row',
      row_confirm_delete: 'Do you want to delete a row?',
      row_confirm_deletes: 'Do you want to delete %{count} rows?',
      rows_selected: 'Selected items',
      table_name: 'Table name',
      table_delete: 'Delete Table',
      table_create: 'Create Table',
      table_edit: 'Edit Table',
      table_update: 'Update Table',
      table_confirm_delete: 'Do you want to delete a table?',
      table_details: 'Table Details',
      table_details_edit: 'Edit Table Details',
      table_add: 'Add Table',
      year: 'Year',
      year_details: 'Year Details',
      year_summary: 'Year summary',
      year_delete: 'Delete Year',
      year_add: 'Add Year',
      year_create: 'Create Year',
      year_exists: 'Year already exists',
      year_confirm_delete_title: 'Do you want to delete budget year',
      year_confirm_delete:
        'This operation cannot be undone. Each table related to this budget year will be permanently removed.',
      year_copy_year_tables: 'Copy year tables',
      year_overview: 'Year overview',
    },
    budget_category: {
      incomes: {
        incomes_salary: 'Salary',
        incomes_investment: 'Investment',
        incomes_bonus: 'Bonus',
        incomes_interest: 'Interest Income',
        incomes_rental: 'Rental Income',
        incomes_passive: 'Passive Income',
        incomes_parent_contribution: 'Parent contribution',
        incomes_alimory: 'Alimory',
        incomes_part_time_job: 'Part-time job',
        incomes_other: 'Other Income',
      },
      savings: {
        savings_savings: 'Savings',
        savings_copays: 'Copays',
        savings_investing: 'Investing',
        savings_debt_payments: 'Debt Payments',
        savings_emergency_fund: 'Emergency Fund',
        savings_vacations: 'Vacations',
        savings_retirement: 'Retirement Savings',
        savings_other: 'Other Savings',
      },
      spendings: {
        spendings_utilities: 'Utilities',
        spendings_utilities_electricity: 'Electricity',
        spendings_utilities_gas: 'Gas',
        spendings_utilities_water: 'Water',
        spendings_utilities_garbage: 'Garbage',
        spendings_utilities_phone: 'Phone',
        spendings_utilities_cable: 'Cable',
        spendings_utilities_internet: 'Internet',
        spendings_housing: 'Housing',
        spendings_housing_mortgage_rent: 'Mortgage/Rent',
        spendings_housing_mortgage: 'Mortgage',
        spendings_housing_rent: 'Rent',
        spendings_housing_maintanenance: 'Maintenance & Repairs',
        spendings_housing_supplies: 'Household supplies',
        spendings_housing_cleaning_supplies: 'Cleaning supplies',
        spendings_housing_insurance: 'Home insurance',
        spendings_housing_improvements: 'Home improvements',
        spendings_housing_security: 'Home security',
        spendings_housing_cleaner: 'Cleaner',
        spendings_housing_taxes: 'Property taxes',
        spendings_housing_other: 'Other home expenses',
        spendings_transportation: 'Transportation',
        spendings_transportation_payment: 'Auto payment',
        spendings_transportation_insurance: 'Auto insurance',
        spendings_transportation_repairs: 'Repairs',
        spendings_transportation_gas: 'Gas & Fuel',
        spendings_transportation_service_parts: 'Service & Auto Parts',
        spendings_transportation_public_transport: 'Public transport',
        spendings_transportation_taxi: 'Taxi/Uber',
        spendings_transportation_car_wash: 'Car wash',
        spendings_transportation_parking: 'Parking',
        spendings_transportation_traffic_fines: 'Traffic fines',
        spendings_food: 'Food',
        spendings_food_groceries: 'Groceries',
        spendings_food_coffee_shops: 'Coffee shops',
        spendings_food_restaurants: 'Restaurants',
        spendings_food_alcohol: 'Alcohol',
        spendings_shopping: 'Shopping',
        spendings_shopping_clothing: 'Clothing',
        spendings_shopping_books: 'Books',
        spendings_shopping_electronics: 'Electronics & Software',
        spendings_shopping_hobbies: 'Hobbies',
        spendings_shopping_sporting: 'Sporting goods',
        spendings_shopping_cosmetics: 'Cosmetics',
        spendings_medical: 'Medical/Healthcare',
        spendings_medical_dentist: 'Dentist',
        spendings_medical_doctor: 'Doctor',
        spendings_medical_eye_doctor: 'Eye care',
        spendings_medical_pharmacy: 'Pharmacy',
        spendings_medical_health_insurance: 'Health insurance',
        spendings_medical_medications: 'Medications',
        spendings_insurance: 'Insurance',
        spendings_insurance_life: 'Life insurance',
        spendings_insurance_health: 'Health insurance',
        spendings_insurance_home: 'Home insurance',
        spendings_insurance_car: 'Car insurance',
        spendings_personal: 'Personal',
        spendings_personal_gym: 'Gym memberships',
        spendings_personal_haircuts: 'Haircuts',
        spendings_personal_nail: 'Nail salon',
        spendings_personal_massage_spa: 'Massage/Spa',
        spendings_personal_laundry: 'Laundry',
        spendings_debt: 'Debt',
        spendings_debt_personal: 'Personal loans',
        spendings_debt_credit_cards: 'Credit cards',
        spendings_education: 'Education',
        spendings_education_tuition: 'Tuition',
        spendings_education_supplies: 'School supplies',
        spendings_educations_books: 'Books',
        spendings_educations_student_loan: 'Student Loan',
        spendings_entertainment: 'Entertainment',
        spendings_entertainment_arts: 'Arts',
        spendings_entertainment_music: 'Music',
        spendings_entertainment_games: 'Games',
        spendings_entertainment_movies: 'Movies',
        spendings_entertainment_theatre: 'Theatre',
        spendings_entertainment_concerts: 'Concerts',
        spendings_entertainment_news_subscriptions:
          'News/Magazine Subscriptions',
        spendings_entertainment_music_subscriptions: 'Music Subscriptions',
        spendings_entertainment_streaming_subscriptions:
          'Streaming Subscriptions',
        spendings_travel: 'Travel',
        spendings_travel_vacation: 'Vacation',
        spendings_travel_flights: 'Flights',
        spendings_travel_hotel: 'Hotel',
        spendings_kids: 'Kids',
        spendings_kids_babysitter: 'Babysitter & Daycare',
        spendings_kids_school_tuition: 'Shool tuition',
        spendings_kids_activities: 'Activities',
        spendings_kids_sports: 'Sports',
        spendings_kids_toys: 'Toys',
        spendings_kids_baby_supplies: 'Baby Supplies',
        spendings_kids_child_support: 'Child Support',
        spendings_pets: 'Pets',
        spendings_pets_food: 'Food',
        spendings_pets_veterinary: 'Veterinary care',
        spendings_pets_medications: 'Medications',
        spendings_donations: 'Gifts & Donations',
        spendings_donations_gift: 'Gift',
        spendings_donations_charity: 'Charity',
        spendings_other: 'Other Spendings',
      },
    },
    calculator: {
      optional: 'Optional',
      disclaimer:
        'This calculator is intended for educational purposes and should not be considered as financial or investment advice.',
      savings_goal_calculator: 'Savings Goal Calculator',
      mortgage_loan_calculator: 'Mortgage Loan Calculator',
      compound_interest_calculator: 'Compound Interest Calculator',
      rate_return_calculator: 'Rate of Return Calculator',
      savings_retirement_calculator: 'Saving for Retirement Calculator',
      loan_refinance_calculator: 'Loan Refinance Calculator',
      you_need_save: 'You need to save',
      for_n_months_to_reach_goal: 'for %{count} months to reach your goal',
      for_1_month_to_reach_goal: 'for 1 month to reach your goal',
      savings_goal: 'Savings Goal',
      starting_balance: 'Starting Balance',
      ending_balance: 'Ending Balance',
      period: 'Period',
      annual_interest_rate: 'Annual Interest Rate (%)',
      daily: 'Daily',
      net_savings: 'Net Savings',
      interest: 'Interest',
      months: 'Months',
      years: 'Years',
      monthly: 'Monthly',
      annual: 'Annual',
      loan_amount: 'Loan Amount',
      loan_term_years: 'Loan Term in Years',
      other: 'Other',
      your_total_payment: 'Your payment will be',
      for_n_months: 'for %{count} months',
      total_payments: 'Total Payments',
      total_interest: 'Total Interest',
      additional_monthly_contribution: 'Additional Monthly Contribution',
      contribution_amount: 'Contribution Amount',
      year_contribution: 'Years of Contribution',
      contribution_frequency: 'Contribution Frequency',
      principal: 'Principal',
      you_will_have: 'You will have',
      after_n_months: 'After %{count} months',
      initial_balance: 'Initial Balance',
      total_contributions: 'Total Contributions',
      interest_calculated_month: 'Interest calculated per month',
      how_long_contributions:
        'How long you plan to make contributions, if empty calculation will done for the entire period.',
      years_to_save: 'Years to Save',
      compound_annual_growth_rate: 'Compound annual growth rate',
      balance_greater_than: 'Balance must be greater than or equal to %{count}',
      number_years: 'Number of Years',
      current_loan: 'Current Loan',
      new_loan: 'New Loan',
      remaining_amount: 'Remaining Amount',
      monthly_payment: 'Monthly Payment',
      remaining_months: 'Remaining Months',
      refinance_amount: 'Refinance Amount',
      interest_rate: 'Interest rate',
      remaining_payments: 'Remaining payments',
      total_cost_difference: 'Total Cost Difference',
      difference: 'Difference',
      current: 'Current',
      new: 'New',
    },
  },
};
