import React from 'react';
import { Translate } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

const styles = (theme) => ({
  stepper: {
    background: 'none',
    marginBottom: theme.spacing(4),
  },
});

const SubscriptionStepper = ({ activeStep, classes }) => {
  return (
    <Stepper
      className={classes.stepper}
      activeStep={activeStep}
      alternativeLabel
    >
      <Step>
        <StepLabel>
          <Translate value="subscription.compare_plans" />
        </StepLabel>
      </Step>
      <Step>
        <StepLabel>
          <Translate value="subscription.review_plan" />
        </StepLabel>
      </Step>
      <Step>
        <StepLabel>
          <Translate value="subscription.summary" />
        </StepLabel>
      </Step>
    </Stepper>
  );
};

export default withStyles(styles)(SubscriptionStepper);
