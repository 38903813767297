import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { I18n } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import useCurrencySettings from 'hooks/useCurrencySettings';
import * as calculatorQueries from 'modules/calculator/queries';
import CalculatorDivider from 'modules/calculator/components/CalculatorDivider';
import CalculatorTextField from 'modules/calculator/components/CalculatorTextField';
import CalculatorGrid from 'modules/calculator/components/CalculatorGrid';
import CalculatorResult from 'modules/calculator/components/CalculatorResult';
import CalculatorResultDetails from 'modules/calculator/components/CalculatorResultDetails';
import CalculatorPeriodLength from 'modules/calculator/components/CalculatorPeriodLength';
import {
  YEARS_DEFAULT,
  YEARS_CUSTOM_DEFAULT,
  OTHER,
} from 'modules/calculator/constants';
import { INTEREST_RATE_DEFAULT, LOAN_AMOUNT_DEFAULT } from './constants';

const styles = () => ({});

const MortgageLoanCalculator = () => {
  const [loanAmountValue, setLoanAmountValue] = useState(LOAN_AMOUNT_DEFAULT);
  const [interestRateValue, setInterestRateValue] = useState(
    INTEREST_RATE_DEFAULT
  );
  const [yearsValue, setYearsValue] = useState(YEARS_DEFAULT);
  const [yearsCustomValue, setYearsCustomValue] =
    useState(YEARS_CUSTOM_DEFAULT);
  const [result, setResult] = useState(0);

  const loanAmount = _.toNumber(loanAmountValue);
  const interestRate = _.toNumber(interestRateValue);
  const years =
    yearsValue === OTHER
      ? _.toNumber(yearsCustomValue)
      : _.toNumber(yearsValue);
  const yearsCustom = _.toNumber(yearsCustomValue);

  const currencySettings = useCurrencySettings();

  useEffect(() => {
    const result = calculatorQueries.getLoanResult({
      loanAmount,
      interestRate: interestRate / 100,
      years,
    });

    setResult(result);
  }, [loanAmount, interestRate, years, yearsCustom]);

  const renderForm = () => (
    <>
      <CalculatorTextField
        label={I18n.t('calculator.loan_amount')}
        value={loanAmountValue}
        defaultValue={0}
        minValue={0}
        onChange={setLoanAmountValue}
      />
      <CalculatorPeriodLength
        yearsValue={yearsValue}
        yearsOnChange={setYearsValue}
        yearsCustomValue={yearsCustomValue}
        yearsCustomOnChange={setYearsCustomValue}
      />
      <CalculatorTextField
        label={I18n.t('calculator.annual_interest_rate')}
        value={interestRateValue}
        defaultValue={0}
        minValue={0}
        onChange={setInterestRateValue}
      />
    </>
  );

  const renderResult = () => {
    const totalPayments = Math.round(result * years * 12);
    const totalInterest = loanAmount - totalPayments;

    return (
      <>
        <CalculatorResult
          value={
            <NumberFormat
              value={_.round(result, 2)}
              displayType="text"
              {...currencySettings}
            />
          }
          text1={I18n.t('calculator.your_total_payment')}
          text2={I18n.t('calculator.for_n_months', { count: years * 12 })}
        />
        <CalculatorDivider />
        <CalculatorResultDetails
          label={I18n.t('calculator.total_payments')}
          value={totalPayments}
        />
        <CalculatorResultDetails
          label={I18n.t('calculator.total_interest')}
          value={totalInterest}
        />
      </>
    );
  };

  return (
    <CalculatorGrid leftColumn={renderForm()} rightColumn={renderResult()} />
  );
};

export default withStyles(styles)(MortgageLoanCalculator);
