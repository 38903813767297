import _ from 'lodash';
import { createSelector } from 'reselect';
import * as queries from './queries';
import { APP_LINKS } from 'lang/links';
import { SUBSCRIPTIONS_PRICE } from 'constants.js';

export const geti18n = (state) => state?.i18n;

export const getActiveLocale = createSelector([geti18n], (i18n) => {
  return i18n?.locale;
});

export const getActiveLocaleTranslations = createSelector(
  [geti18n, getActiveLocale],
  (i18n, activeLocale) => {
    return i18n?.translations?.[activeLocale];
  }
);

export const getBudgetCategories = createSelector(
  [getActiveLocaleTranslations],
  (translations) => {
    return translations?.budget_category;
  }
);

export const getBudgetVariantCategories = createSelector(
  [(_, props) => props.variant, getBudgetCategories],
  (variant, categories) => {
    return categories?.[variant];
  }
);

export const getSubscriptionsPrice = createSelector(
  [getActiveLocale],
  (activeLocale) => {
    const defaultCurrency = queries.getDefaultCurrency(activeLocale);
    return SUBSCRIPTIONS_PRICE[defaultCurrency] || {};
  }
);

export const getAppLinks = createSelector([getActiveLocale], (activeLocale) => {
  return _.get(APP_LINKS, activeLocale) || {};
});
