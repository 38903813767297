const config = {
  appFeatures: {
    isFontColorPickerEnabled: false,
    isFacebookAuthEnabled: false,
    isSubscriptionPlanEnabled: false,
    isMonthViewEnabled: false,
    isBudgetSharingEnabled: false,
    isBudgetOverviewTableEnabled: false,
    is2FaEnabled: false,
  },
};

export default config;
