import _ from 'lodash';
import React, { useCallback } from 'react';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { Translate } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import validateEmail from 'utils/validateEmail';
import FormField from 'components/formField/FormField';
import { FORM_FIELDS } from 'modules/signin/constants';
import { BUDGET_PATH } from 'constants.js';

const SIGNIN_FORM_NAME = 'signinForm';

const styles = (theme) => ({
  button: {
    marginTop: theme.spacing(3),
  },
});

const SigninForm = ({
  onSignin,
  classes,
  handleSubmit,
  submitting,
  history,
}) => {
  const onSubmit = useCallback(
    ({ email, password }) => {
      onSignin({ email, password }, () => {
        history.push(BUDGET_PATH);
      });
    },
    [onSignin, history]
  );

  const renderFields = () => {
    return _.map(FORM_FIELDS, (field) => {
      return <Field key={field.name} component={FormField} {...field} />;
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {renderFields()}
        <Button
          type="submit"
          className={classes.button}
          disabled={submitting}
          variant="contained"
          color="primary"
          fullWidth
        >
          <Translate value="auth.sign_in" />
        </Button>
      </form>
    </div>
  );
};

function validate({ email, password }) {
  const errors = {};

  errors.email = validateEmail(email);

  if (!password) {
    errors.password = <Translate value="auth.enter_password" />;
  }

  return errors;
}

export default compose(
  reduxForm({ validate, form: SIGNIN_FORM_NAME }),
  withStyles(styles),
  withRouter
)(SigninForm);
