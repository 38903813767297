import _ from 'lodash';

export const getBalanceValue = (incomes, spendings) => {
  if (!_.isNumber(incomes) && !_.isNumber(spendings)) {
    return 0;
  }

  const value1 = incomes || 0;
  const value2 = spendings || 0;

  return value1 - value2;
};

export const getPercentage = (value, total, digits = 1) => {
  if (!_.isNumber(value) || !_.isNumber(total) || total === 0) {
    return null;
  }

  const percentage = (value / total) * 100;

  if (percentage === 100) {
    return percentage.toFixed(0);
  }

  return percentage.toFixed(digits);
};
