import _ from 'lodash';
import {
  ADD_BUDGET,
  FETCH_BUDGETS,
  FETCH_BUDGET_TEMPLATES,
  FETCH_TABLE_TEMPLATES,
  UPDATE_BUDGET,
  FETCH_YEAR,
  UPDATE_CELL,
  ADD_ROW,
  ADD_ROWS,
  UPDATE_ROW,
  UPDATE_ROWS,
  ADD_TABLE,
  UPDATE_TABLE,
  ADD_YEAR,
  UPDATE_YEAR,
  SET_IS_ROW_SELECTION_ENABLED,
  ADD_SELECTED_ROW,
  REMOVE_SELECTED_ROW,
} from './actionTypes';

export default function (state = [], action) {
  switch (action.type) {
    case FETCH_BUDGETS:
      return {
        ...state,
        list: action.payload,
      };
    case FETCH_BUDGET_TEMPLATES:
      return {
        ...state,
        budgetTemplates: action.payload,
      };
    case FETCH_TABLE_TEMPLATES:
      return {
        ...state,
        tableTemplates: action.payload,
      };
    case ADD_BUDGET:
      return {
        ...state,
        list: {
          ...state.list,
          [action.payload._id]: action.payload,
        },
      };
    case UPDATE_BUDGET:
      return {
        ...state,
        list: {
          ...state.list,
          [action.payload._id]: {
            ...state.list[action.payload._id],
            ...action.payload,
          },
        },
      };
    case FETCH_YEAR:
      return {
        ...state,
        years: action.payload.year,
        tables: action.payload.tables,
        rows: action.payload.rows,
      };
    case ADD_YEAR:
      return {
        ...state,
        years: {
          ...state.years,
          [action.payload._id]: action.payload,
        },
      };
    case UPDATE_CELL:
      return {
        ...state,
        rows: {
          ...state.rows,
          [action.payload._row]: {
            ...state.rows[action.payload._row],
            _cells: {
              ...state.rows[action.payload._row]._cells,
              [action.payload.value]: {
                ...state.rows[action.payload._row]._cells[action.payload.value],
                ...action.payload,
              },
            },
          },
        },
      };
    case ADD_ROW:
      return {
        ...state,
        rows: {
          ...state.rows,
          [action.payload._id]: action.payload,
        },
      };
    case ADD_ROWS: {
      const newRows = _.reduce(
        action.payload,
        (rowsObj, row) => {
          return {
            ...rowsObj,
            [row._id]: row,
          };
        },
        {}
      );
      return {
        ...state,
        rows: {
          ...state.rows,
          ...newRows,
        },
      };
    }
    case UPDATE_ROW:
      return {
        ...state,
        rows: {
          ...state.rows,
          [action.payload._id]: {
            ...state.rows[action.payload._id],
            ...action.payload,
          },
        },
      };
    case UPDATE_ROWS: {
      const updatedRows = _.reduce(
        action.payload.rows,
        (rowsObj, row) => {
          return {
            ...rowsObj,
            [row._id]: {
              ...state.rows[row._id],
              ...row,
            },
          };
        },
        {}
      );
      return {
        ...state,
        rows: {
          ...state.rows,
          ...updatedRows,
        },
      };
    }
    case ADD_TABLE:
      return {
        ...state,
        tables: {
          ...state.tables,
          [action.payload._id]: action.payload,
        },
      };
    case UPDATE_TABLE:
      return {
        ...state,
        tables: {
          ...state.tables,
          [action.payload._id]: {
            ...state.tables[action.payload._id],
            ...action.payload,
          },
        },
      };
    case UPDATE_YEAR:
      return {
        ...state,
        years: {
          ...state.years,
          [action.payload._id]: {
            ...state.years[action.payload._id],
            ...action.payload,
          },
        },
      };
    case SET_IS_ROW_SELECTION_ENABLED:
      return {
        ...state,
        tables: {
          ...state.tables,
          [action.payload.tableId]: {
            ...state.tables[action.payload.tableId],
            isRowSelectionEnabled: action.payload.value,
            selectedRows: [],
          },
        },
      };
    case ADD_SELECTED_ROW:
      return {
        ...state,
        tables: {
          ...state.tables,
          [action.payload.tableId]: {
            ...state.tables[action.payload.tableId],
            selectedRows: _.concat(
              state.tables[action.payload.tableId].selectedRows,
              action.payload.rowId
            ),
          },
        },
      };
    case REMOVE_SELECTED_ROW:
      return {
        ...state,
        tables: {
          ...state.tables,
          [action.payload.tableId]: {
            ...state.tables[action.payload.tableId],
            selectedRows: _.filter(
              state.tables[action.payload.tableId].selectedRows,
              (id) => id !== action.payload.rowId
            ),
          },
        },
      };
    default:
      return state;
  }
}
