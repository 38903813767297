export const CURRENCIES = {
  USD: {
    symbol: '$',
    name: 'US Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'USD',
    name_plural: 'US dollars',
    symbol_position: 'prefix',
    thousand_separator: ',',
    decimal_separator: '.'
  },
  CAD: {
    symbol: 'CA$',
    name: 'Canadian Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'CAD',
    name_plural: 'Canadian dollars',
    symbol_position: 'prefix',
    thousand_separator: ',',
    decimal_separator: '.'
  },
  EUR: {
    symbol: '€',
    name: 'Euro',
    symbol_native: '€',
    decimal_digits: 2,
    rounding: 0,
    code: 'EUR',
    name_plural: 'euros',
    thousand_separator: '.',
    decimal_separator: ','
  },
  AED: {
    symbol: 'AED',
    name: 'United Arab Emirates Dirham',
    symbol_native: 'د.إ.\u200f',
    decimal_digits: 2,
    rounding: 0,
    code: 'AED',
    name_plural: 'UAE dirhams',
    thousand_separator: ',',
    decimal_separator: '.'
  },
  AUD: {
    symbol: 'AU$',
    name: 'Australian Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'AUD',
    name_plural: 'Australian dollars',
    thousand_separator: ',',
    decimal_separator: '.'
  },
  BGN: {
    symbol: 'BGN',
    name: 'Bulgarian Lev',
    symbol_native: 'лв.',
    decimal_digits: 2,
    rounding: 0,
    code: 'BGN',
    name_plural: 'Bulgarian leva',
    thousand_separator: ' ',
    decimal_separator: ','
  },
  CHF: {
    symbol: 'CHF',
    name: 'Swiss Franc',
    symbol_native: 'CHF',
    decimal_digits: 2,
    rounding: 0.05,
    code: 'CHF',
    name_plural: 'Swiss francs',
    thousand_separator: ' ',
    decimal_separator: '.'
  },
  CNY: {
    symbol: 'CN¥',
    name: 'Chinese Yuan',
    symbol_native: 'CN¥',
    decimal_digits: 2,
    rounding: 0,
    code: 'CNY',
    name_plural: 'Chinese yuan',
    thousand_separator: ',',
    decimal_separator: '.'
  },
  CZK: {
    symbol: 'Kč',
    name: 'Czech Republic Koruna',
    symbol_native: 'Kč',
    decimal_digits: 2,
    rounding: 0,
    code: 'CZK',
    name_plural: 'Czech Republic korunas',
    thousand_separator: ' ',
    decimal_separator: ','
  },
  DKK: {
    symbol: 'Dkr',
    name: 'Danish Krone',
    symbol_native: 'kr',
    decimal_digits: 2,
    rounding: 0,
    code: 'DKK',
    name_plural: 'Danish kroner',
    thousand_separator: '.',
    decimal_separator: ','
  },
  GBP: {
    symbol: '£',
    name: 'British Pound Sterling',
    symbol_native: '£',
    decimal_digits: 2,
    rounding: 0,
    code: 'GBP',
    name_plural: 'British pounds sterling',
    thousand_separator: ',',
    decimal_separator: '.'
  },
  HKD: {
    symbol: 'HK$',
    name: 'Hong Kong Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'HKD',
    name_plural: 'Hong Kong dollars',
    thousand_separator: ',',
    decimal_separator: '.'
  },
  HRK: {
    symbol: 'kn',
    name: 'Croatian Kuna',
    symbol_native: 'kn',
    decimal_digits: 2,
    rounding: 0,
    code: 'HRK',
    name_plural: 'Croatian kunas',
    thousand_separator: ' ',
    decimal_separator: ','
  },
  HUF: {
    symbol: 'Ft',
    name: 'Hungarian Forint',
    symbol_native: 'Ft',
    decimal_digits: 0,
    rounding: 0,
    code: 'HUF',
    name_plural: 'Hungarian forints',
    thousand_separator: ' ',
    decimal_separator: ','
  },
  ILS: {
    symbol: '₪',
    name: 'Israeli New Sheqel',
    symbol_native: '₪',
    decimal_digits: 2,
    rounding: 0,
    code: 'ILS',
    name_plural: 'Israeli new sheqels',
    thousand_separator: ',',
    decimal_separator: '.'
  },
  JPY: {
    symbol: '¥',
    name: 'Japanese Yen',
    symbol_native: '￥',
    decimal_digits: 0,
    rounding: 0,
    code: 'JPY',
    name_plural: 'Japanese yen',
    thousand_separator: ',',
    decimal_separator: '.'
  },
  MXN: {
    symbol: 'MX$',
    name: 'Mexican Peso',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'MXN',
    name_plural: 'Mexican pesos',
    thousand_separator: ',',
    decimal_separator: '.'
  },
  NOK: {
    symbol: 'Nkr',
    name: 'Norwegian Krone',
    symbol_native: 'kr',
    decimal_digits: 2,
    rounding: 0,
    code: 'NOK',
    name_plural: 'Norwegian kroner',
    thousand_separator: ' ',
    decimal_separator: ','
  },
  NZD: {
    symbol: 'NZ$',
    name: 'New Zealand Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'NZD',
    name_plural: 'New Zealand dollars',
    thousand_separator: ',',
    decimal_separator: '.'
  },
  PLN: {
    symbol: 'zł',
    name: 'Polish Zloty',
    symbol_native: 'zł',
    decimal_digits: 2,
    rounding: 0,
    code: 'PLN',
    name_plural: 'Polish zlotys',
    thousand_separator: ' ',
    decimal_separator: ','
  },
  RON: {
    symbol: 'RON',
    name: 'Romanian Leu',
    symbol_native: 'RON',
    decimal_digits: 2,
    rounding: 0,
    code: 'RON',
    name_plural: 'Romanian lei',
    thousand_separator: '.',
    decimal_separator: ','
  },
  RSD: {
    symbol: 'din.',
    name: 'Serbian Dinar',
    symbol_native: 'дин.',
    decimal_digits: 0,
    rounding: 0,
    code: 'RSD',
    name_plural: 'Serbian dinars',
    thousand_separator: '.',
    decimal_separator: ','
  },
  RUB: {
    symbol: 'RUB',
    name: 'Russian Ruble',
    symbol_native: '₽.',
    decimal_digits: 2,
    rounding: 0,
    code: 'RUB',
    name_plural: 'Russian rubles',
    thousand_separator: ' ',
    decimal_separator: ','
  },
  SEK: {
    symbol: 'Skr',
    name: 'Swedish Krona',
    symbol_native: 'kr',
    decimal_digits: 2,
    rounding: 0,
    code: 'SEK',
    name_plural: 'Swedish kronor',
    thousand_separator: ' ',
    decimal_separator: ','
  },
  SGD: {
    symbol: 'S$',
    name: 'Singapore Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'SGD',
    name_plural: 'Singapore dollars',
    thousand_separator: ',',
    decimal_separator: '.'
  },
  THB: {
    symbol: '฿',
    name: 'Thai Baht',
    symbol_native: '฿',
    decimal_digits: 2,
    rounding: 0,
    code: 'THB',
    name_plural: 'Thai baht',
    thousand_separator: ',',
    decimal_separator: '.'
  },
  TRY: {
    symbol: 'TL',
    name: 'Turkish Lira',
    symbol_native: 'TL',
    decimal_digits: 2,
    rounding: 0,
    code: 'TRY',
    name_plural: 'Turkish Lira',
    thousand_separator: '.',
    decimal_separator: ','
  },
  ZAR: {
    symbol: 'R',
    name: 'South African Rand',
    symbol_native: 'R',
    decimal_digits: 2,
    rounding: 0,
    code: 'ZAR',
    name_plural: 'South African rand',
    thousand_separator: ',',
    decimal_separator: '.'
  },
};

export const LANGUAGE_CURRENCY_MAP = {
  en: 'USD',
  pl: 'PLN',
};
