export const INCOMES = 'incomes';
export const SPENDINGS = 'spendings';
export const SAVINGS = 'savings';

export const TABLE_VARIANTS = [SPENDINGS, INCOMES, SAVINGS];
export const TABLE_DEFAULT_VARIANT = SPENDINGS;

export const MONTHLY = 'monthly';
export const QUARTERLY = 'quarterly';
export const YEARLY = 'yearly';
export const CUSTOM = 'custom';

export const MONTHLY_TRANSLATION_KEY = 'budget.monthly';
export const QUARTERLY_TRANSLATION_KEY = 'budget.quarterly';
export const YEARLY_TRANSLATION_KEY = 'budget.yearly';
export const CUSTOM_TRANSLATION_KEY = 'budget.custom';

export const ROW_BACKGROUND_COLORS = [
  '#FFF',
  '#ffdedc',
  '#ffc3c0',
  '#dfd5fa',
  '#c3b1fa',
  '#e2e5f9',
  '#cad2f9',
  '#f8ecd0',
  '#fbe5ac',
  '#d8f5dd',
  '#bbf6c6'
];

export const CHART_COLORS = [
  '#4a4bf6',
  '#7968f3',
  '#9a86f0',
  '#b5a4ec',
  '#fcbeb7',
  '#fca39a',
  '#fa877d',
  '#f56962',
  '#769af6',
  '#8fa8f6',
  '#a6b7f6',
  '#bbc5f6',
];

export const VARIANTS_COLOR = {
  incomes: '#4a4bf6',
  spendings: '#F56962',
  savings: '#769af6',
  net: '#91b3f9',
};

export const ROW_FONT_COLORS = ['#272727'];
