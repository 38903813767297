import _ from 'lodash';
import React, { useState, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import BudgetMonthDetails from 'modules/budget/budgetMonthDetails';

const styles = () => ({
  openButton: {
    cursor: 'pointer',
  },
});

const BudgetMonthDetailsDialog = ({
  children,
  value,
  onOpen,
  onCancel,
  isEnabled = true,
  classes,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    if (!isEnabled) {
      return;
    }
    if (_.isFunction(onOpen)) {
      onOpen();
    }
    setOpen(true);
  }, [isEnabled, onOpen, setOpen]);

  const handleClose = useCallback(() => {
    if (_.isFunction(onCancel)) {
      onCancel();
    }
    setOpen(false);
  }, [setOpen, onCancel]);

  return (
    <>
      <Box component="span" className={classes.openButton} onClick={handleOpen}>
        {children}
      </Box>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        aria-labelledby="budget-month-details-dialog-description"
        data-test-id="budget-month-details-dialog"
      >
        <DialogTitle id="budget-month-details-dialog-description">
          {value}
        </DialogTitle>
        <DialogContent>
          <BudgetMonthDetails />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(BudgetMonthDetailsDialog);
