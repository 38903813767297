import _ from 'lodash';
import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Translate, I18n } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import Spinner from 'components/spinner/spinner';
import Footer from 'components/footer/Footer';
import BudgetToolbarActionsMenu from 'modules/budget/budgetToolbar/budgetToolbarActionsMenu/BudgetToolbarActionsMenu';
import globalStyles from 'globalStyles.js';
import { BUDGET_PATH_BASE, BUDGET_CREATE_PATH } from 'constants.js';

const styles = (theme) => ({
  root: {
    padding: theme.custom.wrapper.padding,
    marginBottom: theme.custom.global.marginBottom,
    minHeight: theme.custom.global.minHeightWithFooter,
  },
  budgetTitleLink: {
    color: 'inherit',
    cursor: 'pointer',
    textDecoration: 'none !important',
  },
  budgetTitle: {
    fontWeight: 500,
  },
  moreActions: {
    marginLeft: 'auto',
  },
  card: {
    height: '100%',
  },
  cardNewBudget: {
    height: '100%',
    minHeight: 108,
    background: 'none',
    boxShadow: 'none',
    border: '1px solid #ddd',
    textAlign: 'center',
  },
  newBudgetIcon: {
    color: '#ccc',
    position: 'relative',
    top: 3,
  },
  cardActionArea: {
    height: '100%',
  },
  cardContent: {
    '&, &:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  addBudgetCardContent: {
    height: '100%',
  },
  addBudgetContainer: {
    height: '100%',
  },
  years: {
    marginTop: theme.spacing(1.5),
  },
  year: {
    backgroundColor: theme.custom.color.primaryLight
  },
});

const BudgetsOverview = ({
  budgetsList,
  defaultCurrency,
  fetchBudgets,
  onUpdateBudget,
  onDeleteBudget,
  classes,
}) => {
  const globalClasses = globalStyles();
  let history = useHistory();

  useEffect(() => {
    fetchBudgets();
  }, [fetchBudgets]);

  const handleClickNewBudget = () => {
    history.push(BUDGET_CREATE_PATH);
  };

  if (_.isUndefined(budgetsList)) {
    return <Spinner />;
  }

  return (
    <>
      <div className={classes.root} data-test-id="budget-overview">
        <Grid container justifyContent="center">
          <Grid item className={globalClasses.containerLg}>
            <Typography className={globalClasses.pageTitle} variant="h2">
              <Translate value="app.budgets_overview" />
            </Typography>
            <Grid container spacing={3} alignItems="stretch">
              {_.map(budgetsList, (budget) => {
                return (
                  <Grid key={budget._id} item xs={12} sm={6} md={4}>
                    <Card className={classes.card}>
                      <CardContent className={classes.cardContent}>
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item xs>
                            <Link
                              className={classes.budgetTitleLink}
                              to={`${BUDGET_PATH_BASE}${budget._id}`}
                            >
                              <Typography variant="h6">
                                {budget.name}
                              </Typography>
                            </Link>
                          </Grid>
                          <Grid item>
                            <BudgetToolbarActionsMenu
                              activeBudgetId={budget._id}
                              activeBudget={budget}
                              defaultCurrency={defaultCurrency}
                              onUpdateBudget={onUpdateBudget}
                              onDeleteBudget={onDeleteBudget}
                              buttonSize="small"
                            />
                          </Grid>
                        </Grid>
                        {budget._years?.length > 0 && (
                          <div className={classes.years}>
                            <Grid container spacing={1}>
                              {_.map(budget._years, ({ year }) => (
                                <Grid key={year} item>
                                  <Chip className={classes.year} label={year} />
                                </Grid>
                              ))}
                            </Grid>
                          </div>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
              <Grid item xs={12} sm={6} md={4}>
                <CardActionArea
                  className={classes.cardActionArea}
                  onClick={handleClickNewBudget}
                >
                  <Card className={classes.cardNewBudget}>
                    <Tooltip title={I18n.t('app.budget_new')} placement="top">
                      <CardContent className={classes.addBudgetCardContent}>
                        <Grid
                          className={classes.addBudgetContainer}
                          container
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item>
                            <AddIcon
                              className={classes.newBudgetIcon}
                              fontSize="small"
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Tooltip>
                  </Card>
                </CardActionArea>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </>
  );
};

export default withStyles(styles)(BudgetsOverview);
