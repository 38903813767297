import _ from 'lodash';
import { TABLE_TYPE_YEAR } from './constants';
import { MONTHS_ID } from 'constants.js';

export const getCellKeys = (type) => {
  switch (type) {
    case TABLE_TYPE_YEAR:
      return MONTHS_ID;
    default:
      return [];
  }
};

export const getDefaultRowCells = (type = TABLE_TYPE_YEAR) => {
  const cellKeys = getCellKeys(type);

  return _.reduce(
    cellKeys,
    (result, value) => {
      result.push({
        value: value,
      });
      return result;
    },
    []
  );
};

export const getSummaryCells = (defaultRowCells, cells = {}) => {
  const summaryCells = _.map(defaultRowCells, (cell) => {
    return {
      ...cell,
      amount: cells[cell.value] || 0,
    };
  });

  return {
    ...summaryCells,
    sum: {
      value: 'sum',
      amount: cells.sum || 0,
    },
  };
};
