import axios from 'axios';
import debug from 'utils/debug';
import { fetchMessage } from 'models/message/actions';
import {
  ADD_TABLE,
  UPDATE_TABLE,
  TRIGGER_MOVE_TABLE,
  ADD_ROWS,
  ADD_SELECTED_ROW,
  REMOVE_SELECTED_ROW,
  SET_IS_ROW_SELECTION_ENABLED,
  UPDATE_YEAR,
} from './actionTypes';

const DEBUG_PATH = 'models/budget/actionsTable';

export const updateTableState = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_TABLE,
    payload: data,
  });
};

export const addTable = (yearId, data = {}) => async (dispatch) => {
  debug(DEBUG_PATH, 'addTable', {
    yearId,
    data,
  });

  try {
    const res = await axios.post('/api/budget/table', {
      yearId,
      data,
    });

    debug(DEBUG_PATH, 'addTable: added', {
      data: res.data,
    });

    dispatch({
      type: ADD_ROWS,
      payload: res.data?.rows,
    });

    dispatch({
      type: ADD_TABLE,
      payload: res.data?.table,
    });

    dispatch({
      type: UPDATE_YEAR,
      payload: res.data?.year,
    });
  } catch (err) {
    debug(DEBUG_PATH, 'addTable: error', {
      yearId,
      data,
    });
  }
};

export const updateTable = (tableId, data) => async (dispatch) => {
  debug(DEBUG_PATH, 'updateTable', {
    tableId,
    data,
  });

  try {
    const res = await axios.patch('/api/budget/table', {
      tableId,
      data,
    });

    debug(DEBUG_PATH, 'updateTable: updated', {
      data: res.data,
    });

    dispatch({
      type: UPDATE_TABLE,
      payload: res.data,
    });
  } catch (err) {
    debug(DEBUG_PATH, 'updateTable: error', {
      tableId,
      data,
    });

    dispatch(fetchMessage(err));
  }
};

export const triggerMoveTable = (tableId, direction) => async (dispatch) => {
  debug(DEBUG_PATH, 'moveTable', {
    tableId,
    direction,
  });

  dispatch({
    type: TRIGGER_MOVE_TABLE,
    payload: {
      tableId,
      direction,
    },
  });
};

export const addSelectedRow = (tableId, rowId) => (dispatch) => {
  dispatch({
    type: ADD_SELECTED_ROW,
    payload: { tableId, rowId },
  });
};

export const removeSelectedRow = (tableId, rowId) => (dispatch) => {
  dispatch({
    type: REMOVE_SELECTED_ROW,
    payload: { tableId, rowId },
  });
};

export const setIsRowSelectionEnabled = (tableId, value) => (dispatch) => {
  dispatch({
    type: SET_IS_ROW_SELECTION_ENABLED,
    payload: { tableId, value },
  });
};

export const deleteTable = (tableId) => async (dispatch) => {
  debug(DEBUG_PATH, 'deleteTable', {
    tableId,
  });

  try {
    const res = await axios.delete(`/api/budget/table/${tableId}`);

    debug(DEBUG_PATH, 'deleteTable: deleted', {
      tableId,
    });

    dispatch({
      type: UPDATE_YEAR,
      payload: res.data?.year,
    });
  } catch (err) {
    debug(DEBUG_PATH, 'deleteTable: error', {
      tableId,
    });
  }
};
