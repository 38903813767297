import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const styles = () => ({
  resultContainer: {
    textAlign: 'center',
  },
});

const CalculatorGrid = ({ leftColumn, rightColumn, classes }) => {
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="stretch"
      spacing={3}
    >
      <Grid item sm={6}>
        {leftColumn}
      </Grid>
      <Grid item sm={6}>
        <div className={classes.resultContainer}>{rightColumn}</div>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(CalculatorGrid);
