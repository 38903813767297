// Budget
export const ADD_BUDGET = 'budget/add_budget';
export const FETCH_BUDGETS = 'budget/fetch_budgets';
export const FETCH_BUDGET_TEMPLATES = 'budget/fetch_budget_templates';
export const FETCH_TABLE_TEMPLATES = 'budget/fetch_tablet_templates';
export const UPDATE_BUDGET = 'budget/update_budget';
export const TRIGGER_SET_ACTIVE_BUDGET_ID =
  'budget/trigger_set_active_budget_id';

// Year
export const ADD_YEAR = 'budget/add_year';
export const FETCH_YEAR = 'budget/fetch_year';
export const UPDATE_YEAR = 'budget/update_year';
export const TRIGGER_FETCH_YEAR = 'budget/trigger_fetch_year';

// Table
export const ADD_TABLE = 'budget/add_table';
export const UPDATE_TABLE = 'budget/update_table';
export const TRIGGER_MOVE_TABLE = 'budget/trigger_move_table';
export const SET_IS_ROW_SELECTION_ENABLED =
  'budget/set_is_row_selection_enabled';

// Row
export const ADD_ROW = 'budget/add_row';
export const ADD_ROWS = 'budget/add_rows';
export const UPDATE_ROW = 'budget/update_row';
export const UPDATE_ROWS = 'budget/update_rows';
export const TRIGGER_MOVE_ROW = 'budget/trigger_move_row';
export const ADD_SELECTED_ROW = 'budget/add_selected_row';
export const REMOVE_SELECTED_ROW = 'budget/remove_selected_row';

// Cell
export const UPDATE_CELL = 'budget/update_cell';
export const TRIGGER_UPDATE_CELL = 'budget/trigger_update_cell';
