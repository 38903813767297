import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import Masthead from 'components/masthead/Masthead';
import Message from 'modules/message';
import * as authModelActions from 'models/auth/actions';
import Routes from './routes';

class App extends React.PureComponent {
  componentDidMount() {
    this.props.onFetchUser();
  }

  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <BrowserRouter>
          <Masthead />
          <Message />
          <Routes />
        </BrowserRouter>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  onFetchUser: authModelActions.fetchUser,
};

export default connect(null, mapDispatchToProps)(App);
