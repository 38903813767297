import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CurrencySelector from 'components/currencySelector/CurrencySelector';
import CountrySelector from 'components/countrySelector/CountrySelector';
import FormField from 'components/formField/FormField';
import * as authModelActions from 'models/auth/actions';
import * as authModelSelectors from 'models/auth/selectors';

const FORM_PROFILE = 'profile-form';
const FORM_FIELD_NAME = 'name';
const FORM_FIELD_CITY = 'city';
const FORM_FIELD_ZIPCODE = 'zipcode';

const styles = (theme) => ({
  formGroupTitle: {
    marginTop: theme.spacing(4),
  },
  formField: {
    marginBottom: theme.spacing(1),
  },
  submitButton: {
    marginTop: theme.spacing(4),
  },
  cancelButton: {
    marginTop: theme.spacing(1.5),
  },
});

const ProfileForm = ({
  classes,
  // reduxForm props:
  handleSubmit,
  pristine,
  submitting,
  initialize,
  reset,
}) => {
  const dispatch = useDispatch();

  const profile = useSelector(authModelSelectors.getUserProfile) || {};
  const { userName, currency, location } = profile;

  const [currencyValue, setCurrencyValue] = useState(currency);
  const [countryValue, setCountryValue] = useState(location?.countryCode);

  const initializeForm = useCallback(
    (data) => {
      initialize(data);
    },
    [initialize]
  );

  useEffect(
    () =>
      initializeForm({
        [FORM_FIELD_NAME]: userName,
        [FORM_FIELD_CITY]: location?.city,
        [FORM_FIELD_ZIPCODE]: location?.zipCode,
      }),
    [initializeForm, userName, location]
  );

  const handleCurrencyChange = useCallback(
    (value) => {
      setCurrencyValue(value);
    },
    [setCurrencyValue]
  );

  const handleCountryChange = useCallback(
    (value) => {
      setCountryValue(value);
    },
    [setCountryValue]
  );

  const handleCancel = () => {
    reset(FORM_PROFILE);
    setCurrencyValue(currency);
    setCountryValue(location?.countryCode);
  };

  const submit = useCallback(
    ({ name, city, zipcode }) => {
      dispatch(
        authModelActions.updateUserProfile({
          userName: name,
          currency: currencyValue,
          location: {
            city,
            zipCode: zipcode,
            countryCode: countryValue,
          },
        })
      );
    },
    [dispatch, countryValue, currencyValue]
  );

  const isSubmitButtonDisabled =
    (pristine &&
      currency == currencyValue &&
      location?.countryCode == countryValue) ||
    submitting;

  return (
    <>
      <form className={classes.root} onSubmit={handleSubmit(submit)}>
        <Typography variant="h6">
          <Translate value="profile.details" />
        </Typography>
        <div className={classes.formField}>
          <Field
            key={FORM_FIELD_NAME}
            name={FORM_FIELD_NAME}
            type="text"
            component={FormField}
            label={I18n.t('profile.name')}
          />
        </div>
        <Typography variant="h6" className={classes.formGroupTitle}>
          <Translate value="profile.location" />
        </Typography>
        <div className={classes.formField}>
          <Field
            className={classes.formField}
            key={FORM_FIELD_CITY}
            name={FORM_FIELD_CITY}
            type="text"
            component={FormField}
            label={I18n.t('profile.city')}
          />
        </div>
        <div className={classes.formField}>
          <Field
            className={classes.formField}
            key={FORM_FIELD_ZIPCODE}
            name={FORM_FIELD_ZIPCODE}
            type="text"
            component={FormField}
            label={I18n.t('profile.zipcode')}
          />
        </div>
        <div className={classes.formField}>
          <CountrySelector
            selectedCountry={countryValue}
            onCountryChange={handleCountryChange}
            inputVariant="standard"
          />
        </div>
        <Typography variant="h6" className={classes.formGroupTitle}>
          <Translate value="profile.settings" />
        </Typography>
        <div className={classes.formField}>
          <CurrencySelector
            selectedCurrency={currencyValue}
            onCurrencyChange={handleCurrencyChange}
            inputVariant="standard"
            inputLabel={I18n.t('profile.default_currency')}
          />
        </div>
        <Button
          className={classes.submitButton}
          type="submit"
          disabled={isSubmitButtonDisabled}
          variant="contained"
          color="primary"
          fullWidth
        >
          <Translate value="common.save" />
        </Button>
        {!isSubmitButtonDisabled && (
          <Button
            className={classes.cancelButton}
            variant="contained"
            onClick={handleCancel}
            fullWidth
          >
            <Translate value="common.cancel" />
          </Button>
        )}
      </form>
    </>
  );
};

function validate({ name }) {
  const errors = {};

  if (name?.length > 100) {
    errors.name = <Translate value="profile.name_too_long" />;
  }

  return errors;
}

export default compose(
  reduxForm({ validate, form: FORM_PROFILE }),
  withStyles(styles)
)(ProfileForm);
