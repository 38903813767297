import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { i18nReducer } from 'react-redux-i18n';
import authReducer from 'models/auth/reducer';
import messageReducer from 'models/message/reducer';
import budgetReducer from 'models/budget/reducer';

export default combineReducers({
  i18n: i18nReducer,
  form: reduxFormReducer,
  auth: authReducer,
  messages: messageReducer,
  budgets: budgetReducer,
});
