import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

const styles = {
  spinnerWrapper: {
    marginTop: '-64px',
    paddingTop: '50vh',
  },
};

const Spinner = ({ classes }) => {
  return (
    <Grid direction="row" justifyContent="center" container>
      <Grid item className={classes.spinnerWrapper}>
        <CircularProgress />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Spinner);
