import React from 'react';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import useCurrencySettings from 'hooks/useCurrencySettings';

const styles = (theme) => ({
  details: {
    marginBottom: theme.spacing(0.75),
  },
  detailsLabel: {
    textAlign: 'right',
  },
  detailsValue: {
    textAlign: 'left',
  },
});

const CalculatorResultDetails = ({ label, value, isCurrency = true, classes }) => {
  const currencySettings = useCurrencySettings();

  return (
    <Grid className={classes.details} container spacing={1}>
      <Grid item xs={6} className={classes.detailsLabel}>
        {label}:
      </Grid>
      <Grid item xs={6} className={classes.detailsValue}>
        {isCurrency ? (
          <NumberFormat value={value} displayType="text" {...currencySettings} />
        ) : value}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(CalculatorResultDetails);
