import _ from 'lodash';
import { FETCH_MESSAGE, CLEAR_MESSAGE, CLEAR_MESSAGES } from './actionTypes';

export default function (state = [], action) {
  switch (action.type) {
    case FETCH_MESSAGE:
      return [
        ...state,
        {
          ...action.payload,
        },
      ];
    case CLEAR_MESSAGE:
      return _.filter(
        state,
        ({ timestamp }) => timestamp !== action.payload.timestamp
      );
    case CLEAR_MESSAGES:
      return [];
    default:
      return state;
  }
}
