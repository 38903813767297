import _ from 'lodash';
import React from 'react';
import classNames from 'classnames';
import { Translate } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import BudgetMonthDetailsDialog from 'modules/budget/budgetMonthDetailsDialog/BudgetMonthDetailsDialog';
import config from 'config.js';
import globalStyles from 'globalStyles.js';

const styles = (theme) => ({
  row: {
    '& > th, & > td': {
      borderColor: theme.custom.color.primaryLighter,
    },
  },
  rowNoBorder: {
    '& > th, & > td': {
      border: 'none',
    },
  },
  sumCell: {
    lineHeight: 2,
    '&:last-child': {
      paddingRight: theme.custom.budget.lastCellPaddingRight,
    },
  },
  labelCell: {
    paddingLeft: theme.spacing(2.75),
  },
  expandCol: {
    width: 47,
    paddingRight: 0,
  },
  checkbox: {
    padding: 5,
  },
});

const BudgetTableHeaderRow = ({
  headerCells,
  label,
  noBorder,
  isRowSelectionAvailable = true,
  isRowSelectionEnabled,
  onSetIsRowSelectionEnabled,
  classes,
}) => {
  const globalClasses = globalStyles();

  const sumCellClass = classNames(
    globalClasses.budgetTableCell,
    classes.sumCell
  );

  const rowClass = classNames(
    classes.row,
    noBorder ? classes.rowNoBorder : null
  );

  const handleChange = (event) => {
    onSetIsRowSelectionEnabled(event.target.checked);
  };

  return (
    <TableHead>
      <TableRow className={rowClass}>
        {isRowSelectionAvailable && (
          <TableCell className={classes.expandCol}>
            <Checkbox
              className={classes.checkbox}
              checked={Boolean(isRowSelectionEnabled)}
              onChange={handleChange}
              color="primary"
              size="small"
            />
          </TableCell>
        )}
        <TableCell
          className={!isRowSelectionAvailable ? classes.labelCell : null}
          colSpan={!isRowSelectionAvailable ? 2 : null}
        >
          {label ?? <Translate value="budget.column_name" />}
        </TableCell>
        {_.map(headerCells, (cell) => (
          <TableCell
            className={globalClasses.budgetTableCell}
            key={cell.key}
            align="right"
          >
            {config.isMonthViewEnabled ? (
              <BudgetMonthDetailsDialog value={cell.key}>
                <Translate value={cell.value} />
              </BudgetMonthDetailsDialog>
            ) : (
              <Translate value={cell.value} />
            )}
          </TableCell>
        ))}
        <TableCell className={sumCellClass} align="right">
          <Translate value="budget.full_year" />
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default withStyles(styles)(BudgetTableHeaderRow);
