import React, { useState, useCallback, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Translate, I18n } from 'react-redux-i18n';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ColorPicker from 'components/colorPicker/ColorPicker';
import ConfirmDialog from 'components/confirmDialog/ConfirmDialog';
import { ROW_BACKGROUND_COLORS } from 'modules/budget/constants';

const styles = () => ({
  buttonIcon: {
    marginTop: -1,
  },
  colorPickerWrapper: {
    minWidth: 200,
  },
  label: {
    marginTop: 2,
    display: 'inline-block',
  },
});

const BudgetRowGroupActionMenu = ({
  selectedRows,
  onDeleteRows,
  onUpdateRows,
  classes,
}) => {
  const [selectedBackgroundColor, onSelectBackgroundColor] = useState('');
  const selectedRowsLenght = selectedRows?.length;

  useEffect(() => {
    onSelectBackgroundColor('');
  }, [selectedRows]);

  const handleDelete = useCallback(() => {
    onDeleteRows(selectedRows);
  }, [selectedRows, onDeleteRows]);

  const handleBackgroundColorSelect = useCallback(
    (value) => {
      onSelectBackgroundColor(value);
      onUpdateRows(selectedRows, {
        backgroundColor: value,
      });
    },
    [selectedRows, onUpdateRows]
  );

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        <Translate className={classes.label} value="budget.rows_selected" />:{' '}
        {selectedRowsLenght}
      </Grid>
      <Grid item>
        <div className={classes.colorPickerWrapper}>
          <ColorPicker
            id="rows-group-background-color"
            labelKey="budget.row_background_color"
            options={ROW_BACKGROUND_COLORS}
            selectedOption={selectedBackgroundColor}
            onSelect={handleBackgroundColorSelect}
            isDisabled={!selectedRowsLenght}
            isDense
          />
        </div>
      </Grid>
      <Grid item>
        <ConfirmDialog
          description={I18n.t('budget.row_confirm_deletes', {
            count: selectedRowsLenght,
          })}
          isEnabled={Boolean(selectedRowsLenght)}
          onConfirm={handleDelete}
        >
          <Button
            variant="contained"
            color="secondary"
            size="small"
            disabled={!selectedRowsLenght}
            startIcon={
              <DeleteIcon className={classes.buttonIcon} fontSize="small" />
            }
          >
            <Translate value="common.delete" />
          </Button>
        </ConfirmDialog>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(BudgetRowGroupActionMenu);
