import React, { useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { I18n, Translate } from 'react-redux-i18n';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { DIRECTION_UP, DIRECTION_DOWN } from 'models/budget/constants';
import BudgetTableDetailsDialog from 'modules/budget/budgetTable/budgetTableDetailsDialog/BudgetTableDetailsDialog';
import ConfirmDialog from 'components/confirmDialog/ConfirmDialog';

const styles = (theme) => ({
  noBackground: {
    backgroundColor: 'transparent !important',
  },
  menuItemIcon: {
    minWidth: theme.spacing(4),
    verticalAlign: 'middle',
    position: 'relative',
    top: -2,
  },
});

const BudgetTableActionsMenu = ({
  name,
  variant,
  currency,
  onDeleteTable,
  onMoveTable,
  onUpdateTable,
  classes,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteTable = useCallback(() => {
    onDeleteTable();
    handleClose();
  }, [onDeleteTable]);

  const handleMoveTableUp = useCallback(() => {
    onMoveTable(DIRECTION_UP);
    handleClose();
  }, [onMoveTable]);

  const handleMoveTableDown = useCallback(() => {
    onMoveTable(DIRECTION_DOWN);
    handleClose();
  }, [onMoveTable]);

  const handleUpdateTable = useCallback(
    (data) => {
      onUpdateTable(data);
      handleClose();
    },
    [onUpdateTable]
  );

  return (
    <>
      <IconButton
        aria-label={I18n.t('common.settings')}
        size="small"
        aria-controls="table-header-actions-menu"
        aria-haspopup="true"
        onClick={handleOpen}
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        id="table-header-actions-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onKeyDown={(e) => e.stopPropagation()}
          focusVisibleClassName={classes.noBackground}
        >
          <BudgetTableDetailsDialog
            name={name}
            variant={variant}
            currency={currency}
            onCancel={handleClose}
            onConfirm={handleUpdateTable}
            confirmButtonText={I18n.t('budget.table_update')}
            description={I18n.t('budget.table_details')}
          >
            <ListItemIcon className={classes.menuItemIcon}>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <Translate value="budget.table_details" />
          </BudgetTableDetailsDialog>
        </MenuItem>
        <MenuItem onClick={handleMoveTableUp}>
          <div>
            <ListItemIcon className={classes.menuItemIcon}>
              <ArrowUpwardIcon fontSize="small" />
            </ListItemIcon>
            <Translate value="budget.move_up" />
          </div>
        </MenuItem>
        <MenuItem onClick={handleMoveTableDown}>
          <div>
            <ListItemIcon className={classes.menuItemIcon}>
              <ArrowDownwardIcon fontSize="small" />
            </ListItemIcon>
            <Translate value="budget.move_down" />
          </div>
        </MenuItem>
        <MenuItem>
          <ConfirmDialog
            onConfirm={handleDeleteTable}
            onCancel={handleClose}
            description={I18n.t('budget.table_confirm_delete')}
          >
            <ListItemIcon className={classes.menuItemIcon}>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <Translate value="budget.table_delete" />
          </ConfirmDialog>
        </MenuItem>
      </Menu>
    </>
  );
};

export default withStyles(styles)(BudgetTableActionsMenu);
