import React from 'react';
import classNames from 'classnames';
import { Translate } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { getBudgetVariantTranslationKey } from 'modules/budget/utils';
import BudgetTableActionsMenu from 'modules/budget/budgetTable/budgetTableActionsMenu/BudgetTableActionsMenu';
import { INCOMES, SPENDINGS, SAVINGS, VARIANTS_COLOR } from '../../constants';

const styles = (theme) => ({
  toolbar: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderTopLeftRadius: 4,
    borderWidth: 0,
    borderLeftWidth: 5,
    borderStyle: 'solid',
  },
  toolbarSpendings: {
    borderColor: VARIANTS_COLOR.spendings,
  },
  toolbarSavings: {
    borderColor: VARIANTS_COLOR.savings,
  },
  toolbarIncomes: {
    borderColor: VARIANTS_COLOR.incomes,
  },
  titleWrapper: {
    marginTop: theme.spacing(1),
  },
  title: {
    lineHeight: 1,
    marginLeft: 1,
  },
  actionMenu: {
    marginTop: -theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
});

const BudgetTableHeader = ({
  name,
  variant,
  currency,
  onMoveTable,
  onDeleteTable,
  onUpdateTable,
  classes,
}) => {
  const budgetVariantTranslationKey = getBudgetVariantTranslationKey(variant);

  const toolbarClass = classNames(
    classes.toolbar,
    variant === SPENDINGS ? classes.toolbarSpendings : null,
    variant === SAVINGS ? classes.toolbarSavings : null,
    variant === INCOMES ? classes.toolbarIncomes : null
  );

  return (
    <Toolbar className={toolbarClass}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          {name ? (
            <div className={classes.titleWrapper}>
              <Typography className={classes.title} variant="h6">
                {name}
              </Typography>
              <Typography variant="caption">
                <Translate value={budgetVariantTranslationKey} />
              </Typography>
            </div>
          ) : (
            <>
              <Typography className={classes.title} variant="h6">
                <Translate value={budgetVariantTranslationKey} />
              </Typography>
            </>
          )}
        </Grid>
        <Grid item>
          <div className={classes.actionMenu}>
            <BudgetTableActionsMenu
              name={name}
              variant={variant}
              currency={currency}
              onMoveTable={onMoveTable}
              onDeleteTable={onDeleteTable}
              onUpdateTable={onUpdateTable}
            />
          </div>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export default withStyles(styles)(BudgetTableHeader);
