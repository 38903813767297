import _ from 'lodash';
import React, { useState, useEffect, useCallback } from 'react';
import { I18n } from 'react-redux-i18n';
import { useParams, useHistory } from 'react-router-dom';
import { withStyles, useTheme } from '@material-ui/core/styles';
import { Translate } from 'react-redux-i18n';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import debug from 'utils/debug';
import Footer from 'components/footer/Footer';
import Spinner from 'components/spinner/spinner';
import BudgetTable from 'modules/budget/budgetTable/';
import BudgetToolbar from 'modules/budget/budgetToolbar/BudgetToolbar';
import BudgetSidebar from 'modules/budget/budgetSidebar/BudgetSidebar';
import BudgetYearOverview from 'modules/budget/budgetYearOverview/BudgetYearOverview';
import BudgetOverviewTable from 'modules/budget/budgetOverviewTable/BudgetOverviewTable';
import BudgetDetailsDialog from 'modules/budget/budgetDetailsDialog/BudgetDetailsDialog';
import BudgetYearDetailsDialog from 'modules/budget/budgetYearDetailsDialog/BudgetYearDetailsDialog';
import BudgetTableDetailsDialog from 'modules/budget/budgetTable/budgetTableDetailsDialog/BudgetTableDetailsDialog';
import { BUDGET_PATH_BASE, BUDGET_CREATE_PATH } from 'constants.js';
import config from 'config.js';

const DEBUG_PATH = 'modules/budget/Budget';

const styles = (theme) => ({
  root: {
    padding: theme.custom.wrapper.padding,
    marginBottom: theme.custom.global.marginBottom,
    minHeight: theme.custom.global.minHeightWithFooter,
  },
  wrapper: {
    width: '100%',
  },
  noTables: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  createTableButton: {
    marginTop: theme.spacing(1),
  },
});

const Budget = ({
  budgetsList,
  budgetTemplates,
  tableTemplates,
  activeBudget,
  activeBudgetId,
  userDefaultCurrency,
  defaultCurrency,
  activeYearId,
  activeYear,
  activeYearOverview,
  activeYearTableIds,
  activeYearTableVariants,
  activeBudgetYears,
  headerCells,
  isSidebarVisible,
  expandedSummaryAccordions,
  fetchBudgets,
  fetchBudgetTemplates,
  fetchTableTemplates,
  triggerFetchYear,
  triggerSetActiveBudgetId,
  setActiveYearId,
  setIsSidebarVisible,
  setExpandedSummaryAccordions,
  onAddBudget,
  onAddTable,
  onAddYear,
  onUpdateBudget,
  onDeleteBudget,
  onDeleteYear,
  classes,
}) => {
  debug(DEBUG_PATH, 'render');

  const [yearId, setYearId] = useState(activeYearId);
  const [tableIds, setTableIds] = useState(null);
  const [newTableIds, setNewTableIds] = useState(null);
  const [isCreateBudgetDialogOpen, setIsCreateBudgetDialogOpen] =
    useState(false);
  const [isCreateYearDialogOpen, setIsCreateYearDialogOpen] = useState(false);

  let history = useHistory();
  const { id } = useParams();
  const theme = useTheme();
  const breakpointLg = useMediaQuery(theme.breakpoints.up('lg'));

  const isEmptyBudgetsList = _.isObject(budgetsList) && _.isEmpty(budgetsList);
  const hasTables = activeYearTableIds?.length;

  if (isEmptyBudgetsList) {
    history.push(BUDGET_CREATE_PATH);
  }

  useEffect(() => {
    if (!budgetsList) {
      debug(DEBUG_PATH, 'fetchBudgets');
      fetchBudgets();
    }
  }, [fetchBudgets, budgetsList]);

  useEffect(() => {
    if (activeBudgetId) {
      debug(DEBUG_PATH, 'triggerSetActiveBudgetId', {
        activeBudgetId,
      });

      triggerSetActiveBudgetId(activeBudgetId);
    }
  }, [activeBudgetId, triggerSetActiveBudgetId]);

  useEffect(() => {
    if (activeBudgetId && activeBudgetId !== id) {
      debug(DEBUG_PATH, 'history.push', {
        activeBudgetId,
      });

      history.push(`${BUDGET_PATH_BASE}${activeBudgetId}`);
    }
  }, [id, activeBudgetId, history]);

  useEffect(() => {
    if (activeYearId) {
      debug(DEBUG_PATH, 'triggerFetchYear', {
        activeYearId,
      });

      setYearId(activeYearId);
      setTableIds(null);
      setNewTableIds(null);
      triggerFetchYear(activeYearId);
    }
  }, [triggerFetchYear, activeYearId]);

  useEffect(() => {
    if (yearId !== activeYearId) {
      return;
    }

    if (tableIds && tableIds !== activeYearTableIds) {
      setNewTableIds(
        _.concat(newTableIds, _.differenceBy(activeYearTableIds, tableIds))
      );
    }

    setTableIds(activeYearTableIds);
  }, [yearId, tableIds, newTableIds, activeYearId, activeYearTableIds]);

  const handleAddNewTable = useCallback(
    (data) => {
      onAddTable(activeYearId, data);
    },
    [activeYearId, onAddTable]
  );

  const handleAddYear = useCallback(
    (data) => {
      onAddYear(activeBudgetId, data);
    },
    [activeBudgetId, onAddYear]
  );

  const handleAddBudget = useCallback(
    (data) => {
      onAddBudget(data, (path) => {
        history.push(path);
      });
    },
    [history, onAddBudget]
  );

  const handleSetIsSidebarVisible = useCallback(() => {
    setIsSidebarVisible(!isSidebarVisible);
  }, [setIsSidebarVisible, isSidebarVisible]);

  if (!activeYear) {
    return <Spinner />;
  }

  const renderBudgetYearOverview = (isVertical) => (
    <BudgetYearOverview
      isVertical={isVertical}
      activeYear={activeYear}
      budgetYearOverview={activeYearOverview}
      budgetCurrency={defaultCurrency}
      expandedSummaryAccordions={expandedSummaryAccordions}
      setExpandedSummaryAccordions={setExpandedSummaryAccordions}
    />
  );

  const isTopSectionVisible = !breakpointLg || !isSidebarVisible;
  const isSidebarSectionVisible = breakpointLg && isSidebarVisible;

  return (
    <>
      <div className={classes.root}>
        <Grid container direction="column" alignItems="center">
          <Grid item className={classes.wrapper}>
            <BudgetToolbar
              budgetsList={budgetsList}
              activeBudget={activeBudget}
              activeBudgetId={activeBudgetId}
              activeYearId={activeYearId}
              activeYear={activeYear}
              activeBudgetYears={activeBudgetYears}
              defaultCurrency={defaultCurrency}
              isSidebarVisible={isSidebarVisible}
              setActiveYearId={setActiveYearId}
              setIsCreateBudgetDialogOpen={setIsCreateBudgetDialogOpen}
              setIsCreateYearDialogOpen={setIsCreateYearDialogOpen}
              onUpdateBudget={onUpdateBudget}
              onDeleteBudget={onDeleteBudget}
              onDeleteYear={onDeleteYear}
              onSetIsSidebarVisible={handleSetIsSidebarVisible}
            />
            {hasTables ? (
              <Grid
                container
                direction="row"
                spacing={isSidebarVisible ? 2 : 0}
              >
                <Grid
                  item
                  xs
                  lg={isSidebarVisible ? 8 : 12}
                  xl={isSidebarVisible ? 9 : 12}
                >
                  {config.appFeatures.isBudgetOverviewTableEnabled && (
                    <BudgetOverviewTable
                      headerCells={headerCells}
                      budgetYearOverview={activeYearOverview}
                      budgetCurrency={defaultCurrency}
                      budgetTableVariants={activeYearTableVariants}
                    />
                  )}
                  {isTopSectionVisible && renderBudgetYearOverview()}
                  {_.map(activeYearTableIds, (tableId) => (
                    <BudgetTable
                      key={tableId}
                      tableId={tableId}
                      headerCells={headerCells}
                      isNew={_.includes(newTableIds, tableId)}
                    />
                  ))}
                </Grid>
                {isSidebarSectionVisible && (
                  <Grid item xs lg={4} xl={3} className={classes.sidebar}>
                    <BudgetSidebar>
                      {renderBudgetYearOverview(true)}
                    </BudgetSidebar>
                  </Grid>
                )}
              </Grid>
            ) : (
              <div className={classes.noTables}>
                <Typography
                  variant="h5"
                  align="center"
                  color="textSecondary"
                  gutterBottom
                >
                  <Translate value="budget.no_tables_information_title" />
                </Typography>
                <Typography
                  variant="body1"
                  align="center"
                  color="textSecondary"
                >
                  <Translate value="budget.no_tables_information_body" />
                </Typography>
              </div>
            )}
          </Grid>
          <Grid item className={classes.createTableButton}>
            <BudgetTableDetailsDialog
              onConfirm={handleAddNewTable}
              confirmButtonText={I18n.t('budget.table_create')}
              description={I18n.t('budget.table_details')}
              currency={defaultCurrency}
              defaultCurrency={defaultCurrency}
              tableTemplates={tableTemplates}
              fetchTableTemplates={fetchTableTemplates}
              isTableTemplatesVisible
              isVariantSelectorVisible
            >
              <Tooltip title={I18n.t('budget.table_add')} placement="top">
                <Fab
                  color="primary"
                  size="small"
                  aria-label={I18n.t('budget.table_create')}
                >
                  <AddIcon />
                </Fab>
              </Tooltip>
            </BudgetTableDetailsDialog>
          </Grid>
          <BudgetDetailsDialog
            isOpen={isCreateBudgetDialogOpen}
            setIsOpen={setIsCreateBudgetDialogOpen}
            defaultCurrency={userDefaultCurrency || defaultCurrency}
            description={I18n.t('budget.budget_details')}
            confirmButtonText={I18n.t('budget.budget_create')}
            budgetsList={budgetsList}
            isBudgetTemplatesVisible
            budgetTemplates={budgetTemplates}
            fetchBudgetTemplates={fetchBudgetTemplates}
            onConfirm={handleAddBudget}
          />
          <BudgetYearDetailsDialog
            isOpen={isCreateYearDialogOpen}
            setIsOpen={setIsCreateYearDialogOpen}
            currency={activeBudget?.currency}
            activeBudgetId={activeBudgetId}
            activeBudgetYears={activeBudgetYears}
            confirmButtonText={I18n.t('budget.year_create')}
            onConfirm={handleAddYear}
          />
        </Grid>
      </div>
      <Footer />
    </>
  );
};

export default withStyles(styles)(Budget);
