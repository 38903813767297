import React from 'react';
import { Translate } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import BackButton from 'components/backButton/BackButton';
import globalStyles from 'globalStyles.js';

const styles = (theme) => ({
  root: {
    padding: theme.custom.wrapper.padding,
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      width: theme.breakpoints.width('lg'),
    },
  },
});

const SubscriptionPlans = ({ classes }) => {
  const globalClasses = globalStyles();

  return (
    <div className={classes.root}>
      <BackButton />
      <Grid container justifyContent="center">
        <Grid item className={globalClasses.containerLg}>
          <Typography className={globalClasses.pageTitle} variant="h2">
            <Translate value="subscription.review_plan" />
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(SubscriptionPlans);
