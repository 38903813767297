import { connect } from 'react-redux';
import * as authModelSelectors from 'models/auth/selectors';
import MenuMain from './MenuMain';

const mapStateToProps = () => {
  return (state) => {
    return {
      isAuthenticated: authModelSelectors.getIsAuthenticated(state),
      subscriptionType: authModelSelectors.getUserSubscriptionType(state),
    };
  };
};

export default connect(mapStateToProps)(MenuMain);
