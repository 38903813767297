import React, { useState, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'react-redux-i18n';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const styles = () => ({
  textField: {
    minWidth: 300,
  },
  content: {
    paddingTop: 1,
  },
});

const BudgetCellNoteDialog = ({
  isOpen,
  onClose,
  note = '',
  onNoteSave,
  classes,
}) => {
  const [value, setValue] = useState(note);

  const handleValueChange = (event) => {
    setValue(event.target.value);
  };

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSave = useCallback(() => {
    onNoteSave(value);
    handleClose();
  }, [value, handleClose, onNoteSave]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="text-field-dialog-title"
      data-test-id="budget-cell-note-dialog"
    >
      <DialogTitle id="text-field-dialog-title">
        <Translate value="budget.note" />
      </DialogTitle>
      <DialogContent className={classes.content}>
        <TextField
          className={classes.textField}
          value={value}
          multiline
          rows={4}
          variant="outlined"
          onChange={handleValueChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} size="small">
          <Translate value="common.cancel" />
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
          size="small"
          autoFocus
        >
          <Translate value="common.save" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(BudgetCellNoteDialog);
