import _ from 'lodash';
import React from 'react';
import {
  Cell,
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  LabelList,
} from 'recharts';
import { withStyles } from '@material-ui/core/styles';
import { VARIANTS_COLOR } from 'modules/budget/constants';

const styles = () => ({});

const BudgetOverviewVerticalBarChart = ({ data }) => {
  const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;
    const isNegative = _.includes(value, '-');

    return (
      <text
        x={x + (isNegative ? 0 : width) + 5}
        y={y + height - 6}
        fill="#000"
        textAnchor="start"
      >
        {value}
      </text>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%" minHeight={120}>
      <BarChart
        layout="vertical"
        width={500}
        height={200}
        data={data}
        margin={{
          top: 0,
          right: 75,
          left: 25,
          bottom: 35,
        }}
      >
        <XAxis type="number" hide />
        <YAxis dataKey="label" type="category" stroke="#272727" />
        <Bar dataKey="value" fill="#ccc">
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={VARIANTS_COLOR[entry?.name]} />
          ))}
          <LabelList
            dataKey="valueLabel"
            content={renderCustomizedLabel}
            position="right"
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default withStyles(styles)(BudgetOverviewVerticalBarChart);
