import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = () => ({
  info: {
    fontStyle: 'italic',
  },
});

const CalculatorCaption = ({ children, classes }) => {
  return (
    <Typography className={classes.info} variant="caption" paragraph>
      * {children}
    </Typography>
  );
};

export default withStyles(styles)(CalculatorCaption);
