import _ from 'lodash';
import axios from 'axios';
import { I18n } from 'react-redux-i18n';
import debug from 'utils/debug';
import { fetchMessage } from 'models/message/actions';
import * as authModelActions from 'models/auth/actions';
import {
  ACTIVE_BUDGET_ID_KEY,
  IS_SIDEBAR_VISIBLE_KEY,
  EXPANDED_SUMMARY_ACCORDIONS_KEY,
} from './constants';
import { BUDGET_PATH_BASE } from 'constants.js';
import {
  ADD_BUDGET,
  FETCH_BUDGETS,
  FETCH_BUDGET_TEMPLATES,
  FETCH_TABLE_TEMPLATES,
  UPDATE_BUDGET,
  TRIGGER_SET_ACTIVE_BUDGET_ID,
  ADD_YEAR,
} from './actionTypes';
import { COMPONENT_ID_BUDGET } from 'constants.js';

const DEBUG_PATH = 'models/budget/actionsBudget';

export const triggerSetActiveBudgetId = (budgetId) => (dispatch) => {
  dispatch({ type: TRIGGER_SET_ACTIVE_BUDGET_ID, payload: { budgetId } });
};

export const setActiveBudgetId = (budgetId) => (dispatch) => {
  debug(DEBUG_PATH, 'setActiveBudgetId', { budgetId });

  dispatch(
    authModelActions.updateComponentUserSettings({
      componentId: COMPONENT_ID_BUDGET,
      data: { [ACTIVE_BUDGET_ID_KEY]: budgetId },
    })
  );
};

export const setIsSidebarVisible =
  (value = '') =>
    (dispatch) => {
      debug(DEBUG_PATH, 'setIsSidebarVisible', { value });

      dispatch(
        authModelActions.updateComponentUserSettings({
          componentId: COMPONENT_ID_BUDGET,
          data: { [IS_SIDEBAR_VISIBLE_KEY]: value },
        })
      );
    };

export const setExpandedSummaryAccordions =
  (value = []) =>
    (dispatch) => {
      debug(DEBUG_PATH, 'setExpandedSummaryAccordions', { value });

      dispatch(
        authModelActions.updateComponentUserSettings({
          componentId: COMPONENT_ID_BUDGET,
          data: { [EXPANDED_SUMMARY_ACCORDIONS_KEY]: value },
        })
      );
    };

export const addBudget =
  (data = {}, callback) =>
    async (dispatch) => {
      debug(DEBUG_PATH, 'addBudget', {
        data,
      });

      try {
        const res = await axios.post('/api/budget', {
          data,
        });

        debug(DEBUG_PATH, 'addBudget: added', {
          data: res.data,
        });

        dispatch({
          type: ADD_BUDGET,
          payload: res.data?.budget,
        });

        dispatch({
          type: ADD_YEAR,
          payload: res.data?.year,
        });

        if (_.isFunction(callback)) {
          callback(`${BUDGET_PATH_BASE}${res.data?.budget?._id}`);
        }
      } catch (err) {
        debug(DEBUG_PATH, 'addBudget: error', {
          data,
        });

        dispatch(
          fetchMessage(
            err.response?.data?.message,
            err.response?.data?.messageType
          )
        );
      }
    };

export const fetchBudgets = () => async (dispatch) => {
  debug(DEBUG_PATH, 'fetchBudgets');

  try {
    const res = await axios.get('/api/budget/all');

    debug(DEBUG_PATH, 'fetchBudgets: fetched', { data: res.data });
    dispatch({ type: FETCH_BUDGETS, payload: res.data });
  } catch (err) {
    debug(DEBUG_PATH, 'fetchBudgets: error');
    dispatch(fetchMessage(I18n.t('app.message.cant_fetch_budget')));
  }
};

export const updateBudget = (budgetId, data) => async (dispatch) => {
  debug(DEBUG_PATH, 'updateBudget', {
    budgetId,
    data,
  });

  try {
    const res = await axios.patch('/api/budget', {
      budgetId,
      data,
    });

    debug(DEBUG_PATH, 'updateBudget: updated', {
      data: res.data,
    });

    dispatch({
      type: UPDATE_BUDGET,
      payload: res.data,
    });
  } catch (err) {
    debug(DEBUG_PATH, 'updateBudget: error', {
      budgetId,
      data,
    });

    dispatch(fetchMessage(err));
  }
};

export const deleteBudget = (budgetId) => async (dispatch) => {
  debug(DEBUG_PATH, 'deleteBudget', {
    budgetId,
  });

  try {
    const res = await axios.delete(`/api/budget/${budgetId}`);

    debug(DEBUG_PATH, 'deleteBudget: deleted', {
      budgetId,
    });

    dispatch({ type: FETCH_BUDGETS, payload: res.data });
    dispatch(
      fetchMessage(I18n.t('app.message.budget_successfully_deleted'), 'success')
    );
  } catch (err) {
    debug(DEBUG_PATH, 'deleteBudget: error', {
      budgetId,
    });
  }
};

export const fetchBudgetTemplates = () => async (dispatch) => {
  debug(DEBUG_PATH, 'fetchBudgetTemplates');

  try {
    const res = await axios.get('/api/budget/templates');

    debug(DEBUG_PATH, 'fetchBudgetTemplates: fetched', { data: res.data });
    dispatch({ type: FETCH_BUDGET_TEMPLATES, payload: res.data });
  } catch (err) {
    debug(DEBUG_PATH, 'fetchBudgetTemplates: error');
  }
};

export const fetchTableTemplates = () => async (dispatch) => {
  debug(DEBUG_PATH, 'fetchTableTemplates');

  try {
    const res = await axios.get('/api/budget/templates/tables');

    debug(DEBUG_PATH, 'fetchTableTemplates: fetched', { data: res.data });
    dispatch({ type: FETCH_TABLE_TEMPLATES, payload: res.data });
  } catch (err) {
    debug(DEBUG_PATH, 'fetchTableTemplates: error');
  }
};
