import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  result: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0.75),
    color: theme.custom.color.primary,
  },
  secondary: {
    color: theme.custom.color.secondary,
  },
});

const CalculatorResult = ({
  value,
  text1,
  text2,
  isSecondaryColor,
  classes,
}) => {
  const resultClass = classNames(
    classes.result,
    isSecondaryColor ? classes.secondary : null
  );

  return (
    <>
      <Typography variant="subtitle1">{text1}</Typography>
      <Typography variant="h3" className={resultClass}>
        {value}
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        {text2}
      </Typography>
    </>
  );
};

export default withStyles(styles)(CalculatorResult);
