import React from 'react';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'react-redux-i18n';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { SUBSCRIPTION_PLANS_PATH } from 'constants.js';

const styles = (theme) => ({
  button: {
    marginTop: theme.spacing(3),
  },
});

const SubscriptionPlan = ({ user, classes }) => {
  const { subscriptionType } = user;

  let history = useHistory();

  const handleClick = () => {
    history.push(SUBSCRIPTION_PLANS_PATH);
  };

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        <Translate value="account.plan" />
      </Typography>
      <Typography gutterBottom>
        <Translate
          value={`subscription.subscription_type.${subscriptionType}`}
        />
      </Typography>
      <Button
        className={classes.button}
        type="submit"
        variant="contained"
        onClick={handleClick}
        color="primary"
        fullWidth
      >
        <Translate value="account.change_plan" />
      </Button>
    </div>
  );
};

export default withStyles(styles)(SubscriptionPlan);
