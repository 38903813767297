import _ from 'lodash';
import React from 'react';
import { Translate } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import * as budgetModulesQueries from 'modules/budget/queries';
import { CHART_COLORS } from 'modules/budget/constants';

const styles = (theme) => ({
  labelCell: {
    width: '100%',
  },
  labelCellIcon: {
    marginRight: theme.spacing(1),
    width: theme.spacing(1.25),
    height: theme.spacing(1.25),
    display: 'inline-block',
    verticalAlign: 'middle',
    borderRadius: 10,
    position: 'relative',
    top: -1,
  },
});

const BudgetYearOverviewCategoriesSum = ({
  variant,
  totalAmount,
  categoriesSum,
  renderNumber,
  classes,
}) => {
  const variantKeyOther = `${variant}_other`;

  let lastItem;
  let index = 0;

  const itemsList = _.map(categoriesSum[variant], (value, key) => {
    if (!value) {
      return null;
    }

    const percentage = budgetModulesQueries.getPercentage(value, totalAmount);

    const item = (
      <TableRow key={key}>
        <TableCell className={classes.labelCell} component="th" scope="row">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item>
              <span
                className={classes.labelCellIcon}
                style={{
                  backgroundColor: `${
                    CHART_COLORS[index % CHART_COLORS.length]
                  }`,
                }}
              ></span>
            </Grid>
            <Grid item xs>
              <Translate value={`budget_category.${variant}.${key}`} />
            </Grid>
          </Grid>
        </TableCell>
        <TableCell align="right">{percentage && `${percentage}%`}</TableCell>
        <TableCell align="right">
          <Typography variant="subtitle2" component="span" noWrap>
            {renderNumber(value)}
          </Typography>
        </TableCell>
      </TableRow>
    );

    if (key === variantKeyOther) {
      lastItem = item;
      return;
    }

    index++;

    return item;
  });

  return [itemsList, lastItem];
};

export default withStyles(styles)(BudgetYearOverviewCategoriesSum);
