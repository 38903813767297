import { DEFAULT_BUDGET_NAME } from './constants';

export const getSumDifference = (cell, updatedCell) => {
  if ((!cell || !cell.isComplete) && updatedCell.isComplete) {
    return updatedCell.amount;
  }

  if (cell.isComplete && !updatedCell.isComplete) {
    return -1 * cell.amount;
  }

  if (!cell.isComplete) {
    return 0;
  }

  return -1 * cell.amount + updatedCell.amount;
};

export const swapArrayItems = (arr, a, b) => {
  let newArr = [...arr];
  newArr[a] = arr[b];
  newArr[b] = arr[a];

  return newArr;
};

export const getNewBudgetName = (budgetName, budgetListSize) => {
  return (
    budgetName ||
    `${DEFAULT_BUDGET_NAME}${budgetListSize ? budgetListSize + 1 : ''}`
  );
};
