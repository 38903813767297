import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { Translate, I18n } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import useCurrencySettings from 'hooks/useCurrencySettings';
import * as calculatorQueries from 'modules/calculator/queries';
import CalculatorDivider from 'modules/calculator/components/CalculatorDivider';
import CalculatorTextField from 'modules/calculator/components/CalculatorTextField';
import CalculatorGrid from 'modules/calculator/components/CalculatorGrid';
import CalculatorResult from 'modules/calculator/components/CalculatorResult';
import CalculatorResultDetails from 'modules/calculator/components/CalculatorResultDetails';
import CalculatorPeriodType from 'modules/calculator/components/CalculatorPeriodType';
import CalculatorCaption from 'modules/calculator/components/CalculatorCaption';
import { PERIOD_MONTHLY } from 'modules/calculator/constants';
import {
  STARTING_BALANCE_DEFAULT,
  INTEREST_RATE_DEFAULT,
  PERIOD_TYPE_DEFAULT,
  PERIOD_DEFAULT,
  MONTHLY_CONTRIBUTION_DEFAULT,
} from './constants';

const styles = () => ({});

const CompoundInterestCalculator = () => {
  const [startingBalanceValue, setStartingBalanceValue] = useState(
    STARTING_BALANCE_DEFAULT
  );
  const [interestRateValue, setInterestRateValue] = useState(
    INTEREST_RATE_DEFAULT
  );
  const [monthlyContributionValue, setMonthlyContributionValue] = useState(
    MONTHLY_CONTRIBUTION_DEFAULT
  );
  const [periodTypeValue, setPeriodTypeValue] = useState(PERIOD_TYPE_DEFAULT);
  const [periodValue, setPeriodValue] = useState(PERIOD_DEFAULT);
  const [result, setResult] = useState(0);

  const startingBalance = _.toNumber(startingBalanceValue);
  const interestRate = _.toNumber(interestRateValue);
  const monthlyContribution = _.toNumber(monthlyContributionValue);
  const months =
    _.toNumber(periodValue) * (periodTypeValue === PERIOD_MONTHLY ? 1 : 12);

  const currencySettings = useCurrencySettings();

  useEffect(() => {
    const result = calculatorQueries.getCompoundInterestResult({
      startingBalance,
      months,
      interestRate: interestRate / 100,
      monthlyContribution,
    });

    setResult(result);
  }, [startingBalance, interestRate, months, monthlyContribution]);

  const renderForm = () => (
    <>
      <CalculatorTextField
        label={I18n.t('calculator.principal')}
        value={startingBalanceValue}
        defaultValue={0}
        minValue={0}
        onChange={setStartingBalanceValue}
      />
      <CalculatorTextField
        label={I18n.t('calculator.period')}
        value={periodValue}
        defaultValue={1}
        minValue={1}
        onChange={setPeriodValue}
      />
      <CalculatorPeriodType
        value={periodTypeValue}
        onChange={setPeriodTypeValue}
      />
      <CalculatorTextField
        label={`${I18n.t('calculator.annual_interest_rate')} *`}
        value={interestRateValue}
        defaultValue={0}
        minValue={0}
        onChange={setInterestRateValue}
      />
      <CalculatorTextField
        label={I18n.t('calculator.additional_monthly_contribution')}
        value={monthlyContributionValue}
        defaultValue={0}
        minValue={0}
        onChange={setMonthlyContributionValue}
      />
      <CalculatorCaption>
        <Translate value="calculator.interest_calculated_month" />
      </CalculatorCaption>
    </>
  );

  const renderResult = () => {
    const additionalDeposits = monthlyContribution * months;
    const interest = result - startingBalance - additionalDeposits;

    return (
      <>
        <CalculatorResult
          value={
            <NumberFormat
              value={_.round(result, 2)}
              displayType="text"
              {...currencySettings}
            />
          }
          text1={I18n.t('calculator.you_will_have')}
          text2={I18n.t('calculator.after_n_months', { count: months })}
        />
        <CalculatorDivider />
        <CalculatorResultDetails
          label={I18n.t('calculator.initial_balance')}
          value={startingBalance}
        />
        <CalculatorResultDetails
          label={I18n.t('calculator.total_contributions')}
          value={additionalDeposits}
        />
        <CalculatorResultDetails
          label={I18n.t('calculator.total_interest')}
          value={Math.round(interest)}
        />
      </>
    );
  };

  return (
    <CalculatorGrid leftColumn={renderForm()} rightColumn={renderResult()} />
  );
};

export default withStyles(styles)(CompoundInterestCalculator);
