import React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';

const FormField = ({
  input,
  label,
  neme,
  type,
  placeholder,
  autocomplete = 'on',
  endAdornmentIcon,
  endAdornmentTooltip,
  meta: { error, touched },
}) => {
  const endAdornment =
    endAdornmentIcon && endAdornmentTooltip ? (
      <InputAdornment position="start" style={{ position: 'relative', top: -2 }}>
        <Tooltip
          title={I18n.t(endAdornmentTooltip.translationKey, {
            ...endAdornmentTooltip.translationValue,
          })}
          placement="top"
        >
          {endAdornmentIcon}
        </Tooltip>
      </InputAdornment>
    ) : null;

  return (
    <FormControl fullWidth margin="dense">
      {label && (
        <InputLabel htmlFor={neme} disableAnimation={Boolean(input.value)}>
          <Translate value={label} />
        </InputLabel>
      )}
      <Input
        id={neme}
        type={type}
        placeholder={placeholder}
        autoComplete={autocomplete}
        endAdornment={endAdornment}
        {...input}
      />
      {touched && error && (
        <FormHelperText id={neme} error={Boolean(error && touched)}>
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default FormField;
