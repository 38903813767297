import _ from 'lodash';
import React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import CalculatorTextField from 'modules/calculator/components/CalculatorTextField';
import {
  YEARS_CUSTOM_DEFAULT,
  YEARS,
  OTHER,
} from 'modules/calculator/constants';

const styles = (theme) => ({
  yearsRadioGroup: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
  },
  yearsTextField: {
    marginTop: theme.spacing(0.5),
  },
});

const CalculatorPeriodLength = ({
  yearsValue,
  yearsOnChange,
  yearsCustomValue,
  yearsCustomOnChange,
  classes,
}) => {
  return (
    <div className={classes.yearsRadioGroup}>
      <FormLabel component="legend">
        <Translate value="calculator.loan_term_years" />
      </FormLabel>
      <RadioGroup
        className={classes.radioGroup}
        aria-label="period"
        name="period"
        value={yearsValue}
        onChange={(event) => yearsOnChange(event.target.value)}
      >
        <Grid container>
          {_.map(YEARS, (value) => (
            <Grid item key={value}>
              <FormControlLabel
                value={value}
                control={<Radio size="small" />}
                label={value}
              />
            </Grid>
          ))}
          <Grid item>
            <FormControlLabel
              value={OTHER}
              control={<Radio size="small" />}
              label={I18n.t('calculator.other')}
            />
          </Grid>
        </Grid>
      </RadioGroup>
      {yearsValue === OTHER && (
        <div className={classes.yearsTextField}>
          <CalculatorTextField
            label={I18n.t('calculator.years')}
            value={yearsCustomValue}
            defaultValue={YEARS_CUSTOM_DEFAULT}
            minValue={1}
            maxValue={100}
            onChange={yearsCustomOnChange}
          />
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(CalculatorPeriodLength);
