import { delay } from 'redux-tale';
import { takeEvery, put } from 'redux-tale/lib/effects';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import { DEFAULT_MESSAGE_DELAY } from 'constants.js';

export function* onFetchMessage({ payload }) {
  const { timestamp } = payload;

  yield delay(DEFAULT_MESSAGE_DELAY);
  yield put(actions.clearMessage(timestamp));
}

export const onFetchMessageSaga = takeEvery(
  actionTypes.FETCH_MESSAGE,
  onFetchMessage
);

export default [onFetchMessageSaga];
