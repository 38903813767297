export const getSavingsGoalResult = ({
  savingsGoal,
  startingBalance,
  months,
  interestRate,
}) => {
  if (!interestRate) {
    return (savingsGoal - startingBalance) / months;
  }

  const interestRateByMonth = interestRate / 12;

  return (
    (interestRateByMonth *
      (savingsGoal -
        startingBalance * Math.pow(1 + interestRateByMonth, months))) /
    (Math.pow(1 + interestRateByMonth, months) - 1)
  );
};

export const getLoanResult = ({ loanAmount, interestRate, years }) => {
  if (!interestRate) {
    return loanAmount / (years * 12);
  }

  return (
    (loanAmount * interestRate) /
    (12 * (1 - Math.pow(12 / (12 + interestRate), years * 12)))
  );
};

// Compound interest for principal: P(1+r/n)(nt)
// Future value of a series: PMT × {[(1 + r/n)(nt) - 1] / (r/n)}
//
// A = the future value of the investment/loan, including interest
// P = the principal investment amount (the initial deposit or loan amount)
// PMT = the monthly payment
// r = the annual interest rate (decimal)
// n = the number of times that interest is compounded per unit t
// t = the time (months, years, etc) the money is invested or borrowed for

export const getCompoundInterestResult = ({
  startingBalance,
  months,
  interestRate,
  monthlyContribution,
}) => {
  if (!interestRate) {
    return startingBalance + monthlyContribution * months;
  }

  const interestRateByMonth = interestRate / 12;
  const startingBalanceCompoundInterest =
    startingBalance * Math.pow(1 + interestRateByMonth, months);

  if (!monthlyContribution) {
    return startingBalanceCompoundInterest;
  }

  const contributionFutureValue =
    monthlyContribution *
    ((Math.pow(1 + interestRateByMonth, months) - 1) / interestRateByMonth);

  return startingBalanceCompoundInterest + contributionFutureValue;
};

export const getSavingRetirementResult = ({
  months,
  startingBalance,
  interestRate,
  isMonthlyContribution,
  contribution,
  contributionMonths,
}) => {
  if (!interestRate) {
    return (
      startingBalance +
      contribution *
      (isMonthlyContribution ? contributionMonths : contributionMonths / 12)
    );
  }

  const interestRateByMonth = interestRate / 12;
  const startingBalanceCompoundInterest =
    startingBalance * Math.pow(1 + interestRateByMonth, months);

  if (!contribution) {
    return startingBalanceCompoundInterest;
  }

  const contributionFutureValue =
    contribution *
    (isMonthlyContribution ? 1 : 1 / 12) *
    ((Math.pow(1 + interestRateByMonth, contributionMonths) - 1) /
      interestRateByMonth);

  return startingBalanceCompoundInterest + contributionFutureValue;
};

export const getCompoundAnnualGrowthRate = ({
  startingBalance,
  endingBalance,
  years,
}) => {
  if (!startingBalance || !years) {
    return 0;
  }

  const NOT_ZERO = 0.00000000001;

  return (
    Math.pow(
      (endingBalance === 0 ? NOT_ZERO : endingBalance) / startingBalance,
      1 / years
    ) - 1
  );
};
