import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import createTaleMiddleware from 'redux-tale';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  loadTranslations,
  setLocale,
  syncTranslationWithStore,
} from 'react-redux-i18n';
import { ThemeProvider } from '@material-ui/core/styles';
import lang from 'lang';
import reducers from 'reducers';
import theme from 'theme';
import App from 'App';
import { runAllSagas } from 'sagas';
import { DEFAULT_LOCALE } from './constants';

const taleMiddleware = createTaleMiddleware();
const middlewares = [taleMiddleware, reduxThunk];

const store = createStore(
  reducers,
  {},
  composeWithDevTools(applyMiddleware(...middlewares))
);

syncTranslationWithStore(store);
store.dispatch(loadTranslations(lang));
store.dispatch(setLocale(localStorage.getItem('locale') || DEFAULT_LOCALE));

runAllSagas(taleMiddleware);

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Provider>,
  document.querySelector('#root')
);