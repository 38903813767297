import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'components/loading/Loading';
import { ROOT_PATH } from 'constants.js';

export default (ChildComponent) => {
  class ComposedComponent extends Component {
    componentDidMount() {
      this.shouldNavigateAway();
    }

    componentDidUpdate() {
      this.shouldNavigateAway();
    }

    shouldNavigateAway() {
      if (this.props.isAuthenticated === false) {
        this.props.history.push(ROOT_PATH);
      }
    }

    render() {
      if (!this.props.isAuthenticated) {
        return <Loading />;
      }

      return <ChildComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return { isAuthenticated: state?.auth?.isAuthenticated };
  }

  return connect(mapStateToProps)(ComposedComponent);
};
