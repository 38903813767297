import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import * as calculatorQueries from 'modules/calculator/queries';
import CalculatorTextField from 'modules/calculator/components/CalculatorTextField';
import CalculatorGrid from 'modules/calculator/components/CalculatorGrid';
import CalculatorResult from 'modules/calculator/components/CalculatorResult';
import CalculatorCaption from 'modules/calculator/components/CalculatorCaption';
import {
  STARTING_BALANCE_DEFAULT,
  ENDING_BALANCE_DEFAULT,
  YEARS_DEFAULT,
} from './constants';

const styles = () => ({});

const SavingsGoalCalculator = () => {
  const [startingBalanceValue, setStartingBalanceValue] = useState(
    STARTING_BALANCE_DEFAULT
  );
  const [endingBalanceValue, setEndingBalanceValue] = useState(
    ENDING_BALANCE_DEFAULT
  );
  const [yearsValue, setYearsValue] = useState(YEARS_DEFAULT);
  const [result, setResult] = useState(0);

  const startingBalance = _.toNumber(startingBalanceValue);
  const endingBalance = _.toNumber(endingBalanceValue);
  const years = _.toNumber(yearsValue);

  useEffect(() => {
    const result = calculatorQueries.getCompoundAnnualGrowthRate({
      startingBalance,
      endingBalance,
      years,
    });

    setResult(result);
  }, [startingBalance, years, endingBalance]);

  const renderForm = () => (
    <>
      <CalculatorTextField
        label={`${I18n.t('calculator.starting_balance')} *`}
        value={startingBalanceValue}
        defaultValue={1}
        minValue={1}
        onChange={setStartingBalanceValue}
      />
      <CalculatorTextField
        label={I18n.t('calculator.ending_balance')}
        value={endingBalanceValue}
        defaultValue={0}
        minValue={0}
        onChange={setEndingBalanceValue}
      />
      <CalculatorTextField
        label={I18n.t('calculator.number_years')}
        value={yearsValue}
        defaultValue={1}
        minValue={1}
        onChange={setYearsValue}
      />
      <CalculatorCaption>
        <Translate value="calculator.balance_greater_than" count={1} />
      </CalculatorCaption>
    </>
  );

  const renderResult = () => (
    <>
      <CalculatorResult
        value={`${_.round(result * 100, 2)}%`}
        text1={`${I18n.t('calculator.compound_annual_growth_rate')}:`}
      />
    </>
  );

  return (
    <CalculatorGrid leftColumn={renderForm()} rightColumn={renderResult()} />
  );
};

export default withStyles(styles)(SavingsGoalCalculator);
