import mergeArrays from './utils/mergeArrays';

export const DEFAULT_MESSAGE_DELAY = 7000;

export const DEFAULT_AMOUNT = 0;

export const PASSWORD_MIN_LENGTH = 8;

// Routes
export const ROOT_PATH = '/';
export const ACCOUNT_PATH = '/account';
export const SUBSCRIPTION_PLAN_PREMIUM_PATH = '/subscriptions/plans/premium';
export const SUBSCRIPTION_PLANS_PATH = '/subscriptions/plans';
export const SUBSCRIPTION_SUMMARY_PATH = '/subscriptions/summary';
export const SIGNIN_PATH = '/signin';
export const SIGNUP_PATH = '/signup';
export const PROFILE_PATH = '/profile';
export const BUDGET_PATH = '/budget/:id';
export const BUDGET_PATH_BASE = '/budget/';
export const BUDGET_CREATE_PATH = '/budget-create';
export const BUDGETS_PATH = '/budgets';
export const CALCULATORS_PATH = '/calculators';
export const PASSWORD_RESET_PATH = '/password-reset/:token/:email';
export const LOGOUT_PATH = '/api/auth/logout';

// Status
export const STATUS_PENDING = 'pending';
export const STATUS_SUCCES = 'sucess';
export const STATUS_ERROR = 'error';

// Components
export const COMPONENT_ID_BUDGET = 'budget';

// Translations
export const DEFAULT_LOCALE = 'en';
export const LOCALES = [
  { label: 'locale.en', name: 'en' },
  { label: 'locale.pl', name: 'pl' },
];

export const MONTHS_ID = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec',
];

export const MONTHS_TRANSLATION_KEYS = [
  'date.months.0',
  'date.months.1',
  'date.months.2',
  'date.months.3',
  'date.months.4',
  'date.months.5',
  'date.months.6',
  'date.months.7',
  'date.months.8',
  'date.months.9',
  'date.months.10',
  'date.months.11',
];

export const MONTH_ID_TO_TRANSLATION_KEY = {
  'jan': 'date.months.0',
  'feb': 'date.months.1',
  'mar': 'date.months.2',
  'apr': 'date.months.3',
  'may': 'date.months.4',
  'jun': 'date.months.5',
  'jul': 'date.months.6',
  'aug': 'date.months.7',
  'sep': 'date.months.8',
  'oct': 'date.months.9',
  'nov': 'date.months.10',
  'dec': 'date.months.11',
};

export const MONTHS_SHORT_TRANSLATION_KEYS = [
  'date.months_short.0',
  'date.months_short.1',
  'date.months_short.2',
  'date.months_short.3',
  'date.months_short.4',
  'date.months_short.5',
  'date.months_short.6',
  'date.months_short.7',
  'date.months_short.8',
  'date.months_short.9',
  'date.months_short.10',
  'date.months_short.11',
];

export const DAYS_TRANSLATION_KEYS = [
  'date.days.0',
  'date.days.1',
  'date.days.2',
  'date.days.3',
  'date.days.4',
  'date.days.5',
];

export const DAYS_SHORT_TRANSLATION_KEYS = [
  'date.days_short.0',
  'date.days_short.1',
  'date.days_short.2',
  'date.days_short.3',
  'date.days_short.4',
  'date.days_short.5',
];

export const MONTHS = mergeArrays(MONTHS_ID, MONTHS_TRANSLATION_KEYS);

export const MONTHS_SHORT = mergeArrays(
  MONTHS_ID,
  MONTHS_SHORT_TRANSLATION_KEYS
);

export const DEFAULT_CURRENCY_CODE = 'USD';

export const CURRENCIES_MAIN = ['PLN', 'EUR', 'USD', 'GBP', 'CHF'];

export const SUBSCRIPTIONS_PRICE = {
  USD: {
    free: '$0',
    monthly: '$2 / mo',
    yearly: '$20 / yr',
  },
};
