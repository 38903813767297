import { createTheme } from '@material-ui/core/styles';

const colors = {
  primaryColor: '#5b4dee',
  primaryColorMedium: '#dcd9fc',
  primaryColorLight: '#e8e6fd',
  primaryColorLighter: '#f6f5fe',
  primaryColorLightest: '#faf9fe',
  primaryColorDarken: '#5044D4',
  secondaryColor: '#e5524c',
  success: '#43a047',
  error: '#d32f2f',
  warning: '#ED594C',
  info: '#8BA123',
};

const breakpoints = {
  values: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 1140,
    xl: 1280,
    480: 480,
  },
};

const theme = createTheme({
  custom: {
    color: {
      success: colors.success,
      error: colors.error,
      warning: colors.warning,
      info: colors.info,
      primary: colors.primaryColor,
      primaryMedium: colors.primaryColorMedium,
      primaryLight: colors.primaryColorLight,
      primaryLighter: colors.primaryColorLighter,
      primaryDarken: colors.primaryColorDarken,
      secondary: colors.secondaryColor,
    },
    global: {
      marginBottom: '24px',
      minHeightWithFooter: 'calc(100vh - 215px)',
    },
    wrapper: {
      padding: '32px 16px',
    },
    container: {
      narrow: {
        padding: '0 80px',
      },
    },
    budget: {
      lastCellPaddingRight: 22,
    }
  },
  breakpoints: breakpoints,
  palette: {
    primary: {
      main: colors.primaryColor,
    },
    secondary: {
      main: colors.secondaryColor,
    },
    background: {
      default: colors.primaryColorLighter,
    },
  },
  overrides: {
    MuiAutocomplete: {
      option: {
        '&:hover': {
          backgroundColor: colors.primaryColorLighter,
        },
        '&[aria-selected="true"]': {
          backgroundColor: colors.primaryColorLight,
          '&:hover': {
            backgroundColor: colors.primaryColorLight,
          },
          '&[data-focus="true"]': {
            backgroundColor: colors.primaryColorLight,
          },
        },
        '&[data-focus="true"]': {
          backgroundColor: colors.primaryColorLighter,
          '&:hover': {
            backgroundColor: colors.primaryColorLighter,
          },
        },
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: colors.primaryColorLighter,
        },
      },
      root: {
        '&$selected': {
          backgroundColor: colors.primaryColorLight,
          '&:hover': {
            backgroundColor: colors.primaryColorLight,
          },
        },
      },
    },
  },
});

export default theme;
