import React from 'react';
import { Translate } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

const styles = (theme) => ({
  separator: {
    marginTop: theme.spacing(3),
  },
  separatorText: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  dividerWrapper: {
    flexGrow: 1,
  },
  divider: {
    margin: '0 1px',
  },
});

const LineSeparator = ({ classes }) => {
  return (
    <Grid
      className={classes.separator}
      container
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid className={classes.dividerWrapper} item xs>
        <Divider className={classes.divider} />
      </Grid>
      <Grid item>
        <span className={classes.separatorText}>
          <Translate value="common.or" />
        </span>
      </Grid>
      <Grid className={classes.dividerWrapper} item xs>
        <Divider className={classes.divider} />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(LineSeparator);
