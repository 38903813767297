import React, { useState, useCallback } from 'react';
import { Translate } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CloseAccountForm from './closeAccountForm/CloseAccountForm';

const styles = (theme) => ({
  content: {
    marginTop: theme.spacing(3),
  },
});

const CloseAccount = ({ classes, onCloseAccount }) => {
  const [showCloseForm, setShowCloseForm] = useState(false);

  const handleCancel = useCallback(() => {
    setShowCloseForm(false);
  }, [setShowCloseForm]);

  const handleClick = useCallback(() => {
    setShowCloseForm(true);
  }, [setShowCloseForm]);

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        <Translate value="account.close_account" />
      </Typography>
      <Typography gutterBottom>
        <em>
          <Translate value="account.warning" />
        </em>
        {': '}
        <Translate value="account.closing_your_app_irreversible" />
      </Typography>
      <div className={classes.content}>
        {showCloseForm ? (
          <CloseAccountForm
            onCancel={handleCancel}
            onCloseAccount={onCloseAccount}
          />
        ) : (
          <Button
            type="submit"
            variant="contained"
            onClick={handleClick}
            color="secondary"
            fullWidth
          >
            <Translate value="account.close_account" />
          </Button>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(CloseAccount);
