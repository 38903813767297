import _ from 'lodash';
import axios from 'axios';
import { I18n } from 'react-redux-i18n';
import debug from 'utils/debug';
import { updateLocale } from 'models/locale/actions';
import { fetchMessage } from 'models/message/actions';
import { FETCH_USER } from './actionTypes';

const DEBUG_PATH = 'models/auth/actionsAuth';

export const fetchUser = () => async (dispatch) => {
  debug(DEBUG_PATH, 'fetchUser');

  try {
    const res = await axios.get('/api/auth/active-user', {
      params: {
        locale: localStorage.getItem('locale'),
      },
    });

    debug(DEBUG_PATH, 'fetchUser: fetched', { data: res.data });

    dispatch({ type: FETCH_USER, payload: res.data });
    dispatch(updateLocale(res.data?.details?.locale));
  } catch (err) {
    debug(DEBUG_PATH, 'fetchUser: error');
    dispatch(fetchMessage(I18n.t('app.message.cant_fetch_user_data')));
  }
};

export const signin =
  ({ email, password }, callback) =>
  async (dispatch) => {
    try {
      const res = await axios.post('/api/auth/signin', { email, password });

      dispatch({ type: FETCH_USER, payload: res.data });
      dispatch(updateLocale(res.data?.details?.locale));

      if (_.isFunction(callback)) {
        callback();
      }
    } catch (err) {
      const errorMessage = _.get(err, 'response.data.message');

      dispatch(
        fetchMessage(
          errorMessage
            ? errorMessage
            : I18n.t('app.message.entered_invalid_username_password')
        )
      );
    }
  };

export const signup =
  ({ email, password }, callback) =>
  async (dispatch) => {
    try {
      const res = await axios.post('/api/auth/signup', { email, password });

      dispatch({ type: FETCH_USER, payload: res.data });

      if (_.isFunction(callback)) {
        callback();
      }

      dispatch(fetchMessage(I18n.t('app.message.account_created'), 'success'));
    } catch (err) {
      dispatch(fetchMessage(_.get(err, 'response.data.message')));
      debug(DEBUG_PATH, 'signup: error', err);
    }
  };

export const forgotPassword =
  ({ email }) =>
  async (dispatch) => {
    try {
      await axios.post('/api/auth/forgot-password', { email });

      dispatch(
        fetchMessage(
          I18n.t('app.message.Auth_Email_Sent_To', { email: email }),
          'success'
        )
      );
    } catch (err) {
      dispatch(fetchMessage(_.get(err, 'response.data.message')));
      debug(DEBUG_PATH, 'forgotPassword: error', err);
    }
  };

export const resetPassword =
  ({ email, password, token }, callback) =>
  async (dispatch) => {
    try {
      const res = await axios.post('/api/auth/reset-password', {
        email,
        password,
        token,
      });

      dispatch({ type: FETCH_USER, payload: res.data });

      if (_.isFunction(callback)) {
        callback();
      }

      dispatch(
        fetchMessage(
          I18n.t('app.message.password_successfully_changed'),
          'success'
        )
      );
    } catch (err) {
      dispatch(fetchMessage(_.get(err, 'response.data.message')));
      debug(DEBUG_PATH, 'resetPassword: error', err);
    }
  };
