import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing(1),
  },
});

const CalculatorTextField = ({
  label,
  value,
  defaultValue,
  minValue,
  maxValue,
  onChange,
  classes,
}) => {
  return (
    <TextField
      className={classes.root}
      type="number"
      label={label}
      value={value}
      margin="dense"
      variant="outlined"
      fullWidth
      onChange={(event) => onChange(event.target.value)}
      onBlur={() => {
        if (value === '') {
          onChange(defaultValue);
        }
      }}
      inputProps={{
        min: minValue,
        max: maxValue,
        maxLength: 100,
        step: 1,
      }}
    />
  );
};

export default withStyles(styles)(CalculatorTextField);
