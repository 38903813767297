import { createSelector } from 'reselect';

export const getAuth = (state) => state?.auth;

export const getIsAuthenticated = createSelector([getAuth], (auth) => {
  return auth?.isAuthenticated;
});

export const getUser = createSelector([getAuth], (auth) => {
  return auth?.user;
});

export const getUserEmail = createSelector([getUser], (user) => {
  return user?.email;
});

export const getUserSettings = createSelector([getUser], (user) => {
  return user?.settings;
});

export const getUserSettingsComponents = createSelector(
  [getUserSettings],
  (settings) => {
    return settings?.components;
  }
);

export const getUserProfile = createSelector([getUser], (user) => {
  return user?.profile;
});

export const getUserProfileCurrency = createSelector(
  [getUserProfile],
  (profile) => {
    return profile?.currency;
  }
);

export const getUserSubscriptionType = createSelector([getUser], (user) => {
  return user?.subscriptionType;
});
