import { INCOMES, SPENDINGS, SAVINGS } from './constants';

export const getBudgetVariantTranslationKey = (variant) => {
  switch (variant) {
    case INCOMES:
      return 'budget.incomes';
    case SPENDINGS:
      return 'budget.spendings';
    case SAVINGS:
      return 'budget.savings';
    default:
      return null;
  }
};
