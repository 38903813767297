import _ from 'lodash';
import axios from 'axios';
import debug from 'utils/debug';
import { fetchMessage } from 'models/message/actions';
import { FETCH_USER } from './actionTypes';

const DEBUG_PATH = 'models/auth/actionsProfile';

export const updateUserProfile =
  ({ userName, currency, location }) =>
    async (dispatch) => {
      try {
        const res = await axios.put('/api/user/profile', {
          userName,
          currency,
          location,
        });

        dispatch({ type: FETCH_USER, payload: res.data });
      } catch (err) {
        dispatch(fetchMessage(_.get(err, 'response.data.message')));
        debug(DEBUG_PATH, 'updateUserProfile: error', err);
      }
    };
