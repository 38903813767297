import _ from 'lodash';
import axios from 'axios';
import debug from 'utils/debug';
import { fetchMessage } from 'models/message/actions';
import { UPDATE_COMPONENT_USER_SETTINGS } from './actionTypes';

const DEBUG_PATH = 'models/user/actionSettings';

export const updateComponentUserSettings =
  ({ componentId, data }) =>
    async (dispatch) => {
      debug(DEBUG_PATH, 'updateComponentUserSettings', { componentId, data });

      try {
        dispatch({
          type: UPDATE_COMPONENT_USER_SETTINGS,
          payload: { componentId, data },
        });

        const res = await axios.patch('/api/user/settings/components', {
          [componentId]: data,
        });

        if (
          !_.isEqual(
            _.merge({}, res.data[componentId], data),
            res.data[componentId]
          )
        ) {
          debug(
            DEBUG_PATH,
            'updateComponentUserSettings: settings were not saved correctly'
          );
        }
      } catch (err) {
        dispatch(fetchMessage(_.get(err, 'response.data.message')));
        debug(DEBUG_PATH, 'updateComponentUserSettings: error', err);
      }
    };
