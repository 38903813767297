import _ from 'lodash';
import React, { useState, useCallback } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import objectToArray from 'utils/objectToArray';
import BudgetRowActionsMenu from 'modules/budget/budgetRow/budgetRowActionsMenu/BudgetRowActionsMenu';
import ColorPicker from 'components/colorPicker/ColorPicker';
import ShowMoreButton from 'components/showMoreButton/ShowMoreButton';
import {
  ROW_BACKGROUND_COLORS,
  ROW_FONT_COLORS,
} from 'modules/budget/constants';
import config from 'config.js';

const FONT_SIZE = 14;

const styles = () => ({
  root: {
    marginRight: 0,
  },
  button: {
    marginRight: 10,
  },
  footer: {
    marginTop: 20,
    paddingBottom: 5,
  },
  form: {
    marginRight: 0,
    '& input, & label, & option': {
      fontSize: FONT_SIZE,
    },
  },
  optionsGroupHeader: {
    fontWeight: 500,
    marginLeft: '-5px',
  },
});

const BudgetRowDetails = ({
  row,
  currencySettings,
  budgetCategories,
  isNew,
  closeDetails,
  onUpdateRow,
  onDeleteRow,
  onMoveRow,
  classes,
}) => {
  const { name, defaultAmount, note, category, textColor, backgroundColor } =
    row;
  const categoryName = budgetCategories[category];
  const selectedCategory = categoryName
    ? {
      key: category,
      value: categoryName,
    }
    : null;
  const selectedCategoryName = categoryName || '';

  const [moreOpen, setMoreOpen] = useState(
    note || textColor || backgroundColor
  );
  const [nameValue, setNameValue] = useState(name);
  const [noteValue, setNoteValue] = useState(note);
  const [amountValue, setAmountValue] = useState(defaultAmount);
  const [categoryValue, setCategoryValue] = useState(selectedCategory);
  const [categoryInputValue, setCategoryInputValue] =
    useState(selectedCategoryName);
  const [textColorValue, setTextColorValue] = useState(textColor);
  const [backgroundColorValue, setBackgroundColorValue] =
    useState(backgroundColor);

  const handleClickMore = () => {
    setMoreOpen(!moreOpen);
  };

  const handleCancel = () => {
    closeDetails();
  };

  const handleSave = useCallback(() => {
    onUpdateRow(
      _.omitBy(
        {
          name: nameValue,
          category: categoryValue?.key || null,
          note: noteValue,
          defaultAmount: amountValue,
          textColor: textColorValue,
          backgroundColor: backgroundColorValue,
        },
        _.isUndefined
      )
    );
    closeDetails();
  }, [
    nameValue,
    categoryValue,
    noteValue,
    amountValue,
    textColorValue,
    backgroundColorValue,
    onUpdateRow,
    closeDetails,
  ]);

  const handleNameChange = (event) => {
    setNameValue(event.target.value);
  };

  const handleNoteChange = (event) => {
    setNoteValue(event.target.value);
  };

  const handleAmountChange = (values) => {
    setAmountValue(values.value);
  };

  const handleCategoryChange = (event, newValue) => {
    setCategoryValue(newValue);
  };

  const handleCategoryInutChange = (event, newValue) => {
    setCategoryInputValue(newValue);
  };

  return (
    <Box className={classes.root} margin={1}>
      <form className={classes.form} noValidate autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <TextField
              id="row-details-name"
              defaultValue={nameValue}
              onChange={handleNameChange}
              label={I18n.t('budget.row_name')}
              variant="outlined"
              margin="dense"
              inputProps={{ maxLength: 100 }}
              fullWidth
              autoFocus={isNew && !name}
              InputLabelProps={{ disableAnimation: Boolean(nameValue) }}
            />
          </Grid>
          {budgetCategories && (
            <Grid item xs={3}>
              <Autocomplete
                id="row-details-category"
                size="small"
                value={categoryValue}
                onChange={handleCategoryChange}
                inputValue={categoryInputValue}
                onInputChange={handleCategoryInutChange}
                options={objectToArray(budgetCategories)}
                getOptionLabel={(option) =>
                  _.isObject(option) ? option.value : option
                }
                getOptionSelected={(option) => {
                  return (
                    (_.isObject(option) ? option.value : option) ===
                    categoryValue.value
                  );
                }}
                renderOption={(option) => {
                  const optionClass =
                    _.split(option.key, '_').length === 2
                      ? classes.optionsGroupHeader
                      : null;
                  return (
                    <Typography className={optionClass} noWrap>
                      {option.value}
                    </Typography>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={I18n.t('budget.row_category')}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    InputLabelProps={{ disableAnimation: Boolean(categoryValue) }}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={2}>
            <NumberFormat
              id="row-details-default-amount"
              value={amountValue}
              onValueChange={handleAmountChange}
              label={I18n.t('budget.row_default_amount')}
              customInput={TextField}
              variant="outlined"
              margin="dense"
              fullWidth
              {...currencySettings}
            />
          </Grid>
          <Grid item xs={2}>
            <ShowMoreButton isOpen={moreOpen} onClick={handleClickMore} />
          </Grid>
        </Grid>
        {moreOpen && (
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <TextField
                id="row-details-note"
                value={noteValue}
                onChange={handleNoteChange}
                label={I18n.t('budget.row_note')}
                multiline
                minRows={4}
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <ColorPicker
                id="row-details-background-color"
                labelKey="budget.row_background_color"
                options={ROW_BACKGROUND_COLORS}
                onSelect={setBackgroundColorValue}
                selectedOption={backgroundColorValue}
              />
            </Grid>
            {config.appFeatures.isFontColorPickerEnabled && (
              <Grid item xs={3}>
                <ColorPicker
                  id="row-details-font-color"
                  labelKey="budget.row_font_color"
                  options={ROW_FONT_COLORS}
                  onSelect={setTextColorValue}
                  selectedOption={textColorValue}
                />
              </Grid>
            )}
          </Grid>
        )}
        <Grid
          className={classes.footer}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <Button
              className={classes.button}
              color="primary"
              size="small"
              variant="contained"
              aria-label={I18n.t('common.save')}
              onClick={handleSave}
            >
              <Translate value="common.save" />
            </Button>
            <Button
              className={classes.button}
              color="default"
              size="small"
              aria-label={I18n.t('common.cancel')}
              onClick={handleCancel}
            >
              <Translate value="common.cancel" />
            </Button>
          </Grid>
          <Grid item>
            <BudgetRowActionsMenu
              onDeleteRow={onDeleteRow}
              onMoveRow={onMoveRow}
            />
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default withStyles(styles)(BudgetRowDetails);
