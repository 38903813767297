import _ from 'lodash';
import React from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const FONT_SIZE = 14;
const CLEAR = 'clear';

const styles = () => ({
  root: {
    '& input, & label, & option': {
      fontSize: FONT_SIZE,
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 14px) scale(1)',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  },
  isDense: {
    marginTop: 0,
    marginBottom: 0,
    '& .MuiFormLabel-root:not(.Mui-focused):not(.Mui-disabled)': {
      color: 'inherit',
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: 6,
      paddingBottom: 6,
      height: '18.750px',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 9px) scale(1)',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  },
  selectedOptionColor: {
    height: 20,
  },
  optionColor: {
    height: 20,
    width: 'calc(100% - 20px)',
    marginTop: 15,
    marginLeft: 10,
    marginRight: 10,
    display: 'block',
    '&:first-child': {
      marginTop: 5,
    },
  },
  optionColorClear: {
    marginTop: 15,
    display: 'block',
    textAlign: 'center',
    fontSize: 14,
  },
  optionColorBorder: {
    border: '1px solid #ccc',
  },
});

const ColorPicker = ({
  id,
  labelKey,
  options,
  selectedOption = '',
  isDense,
  isDisabled,
  onSelect,
  classes,
}) => {
  const handleSelect = (event) => {
    const { value } = event.target;
    const selectedValue = value === CLEAR ? '' : value;

    onSelect(selectedValue);
  };

  const pickerOptions = [...options, CLEAR];

  const formControlClass = classNames(
    classes.root,
    isDense ? classes.isDense : null
  );

  return (
    <FormControl
      className={formControlClass}
      variant="outlined"
      margin="dense"
      fullWidth
      disabled={isDisabled}
    >
      <InputLabel id={`${id}-label`}>
        <Translate value={labelKey} />
      </InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        size="small"
        label={I18n.t(labelKey)}
        value={selectedOption}
        onChange={handleSelect}
        renderValue={(value) => {
          const optionClass = classNames(
            value !== CLEAR ? classes.selectedOptionColor : null,
            value === 'white' ? classes.optionColorBorder : null
          );
          return (
            <div
              className={optionClass}
              style={value !== CLEAR ? { backgroundColor: value } : null}
            ></div>
          );
        }}
      >
        {_.map(pickerOptions, (color) => {
          const optionClass = classNames(
            color !== CLEAR ? classes.optionColor : classes.optionColorClear,
            color === 'white' ? classes.optionColorBorder : null
          );
          return (
            <MenuItem
              key={color}
              className={optionClass}
              value={color}
              style={{ backgroundColor: color }}
            >
              {color === CLEAR && <Translate value="common.clear" />}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default withStyles(styles)(ColorPicker);
