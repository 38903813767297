import axios from 'axios';
import { I18n } from 'react-redux-i18n';
import debug from 'utils/debug';
import { fetchMessage } from 'models/message/actions';
import {
  UPDATE_CELL,
  UPDATE_ROW,
  UPDATE_TABLE,
  TRIGGER_UPDATE_CELL,
  UPDATE_YEAR,
} from './actionTypes';
import { STATUS_PENDING, STATUS_SUCCES, STATUS_ERROR } from 'constants.js';

const DEBUG_PATH = 'models/budget/actionsCell';

export const updateCellState = (data) => (dispatch) => {
  debug(DEBUG_PATH, 'updateCellState', {
    data,
  });

  dispatch({
    type: UPDATE_CELL,
    payload: data,
  });
};

export const triggerUpdateCell =
  (rowId, data, updateSum = false) =>
  (dispatch) => {
    debug(DEBUG_PATH, 'triggerUpdateCell', {
      rowId,
      data,
      updateSum,
    });

    dispatch({
      type: TRIGGER_UPDATE_CELL,
      payload: { rowId, data, updateSum },
    });
  };

export const updateCell =
  (rowId, tableId, data, updateSum = false) =>
  async (dispatch) => {
    debug(DEBUG_PATH, 'updateCell', {
      rowId,
      data,
    });

    dispatch({
      type: UPDATE_CELL,
      payload: {
        _row: rowId,
        ...data,
      },
    });

    dispatch({
      type: UPDATE_TABLE,
      payload: {
        _id: tableId,
        status: STATUS_PENDING,
      },
    });

    try {
      const res = await axios.post('/api/budget/cell', {
        rowId,
        data,
      });

      debug(DEBUG_PATH, 'updateCell: updated', { data: res.data });

      dispatch({
        type: UPDATE_TABLE,
        payload: {
          _id: tableId,
          status: STATUS_SUCCES,
        },
      });

      dispatch({
        type: UPDATE_CELL,
        payload: res.data?.cell,
      });

      if (!updateSum) {
        return;
      }

      dispatch({
        type: UPDATE_ROW,
        payload: res.data?.row,
      });

      dispatch({
        type: UPDATE_TABLE,
        payload: res.data?.table,
      });

      dispatch({
        type: UPDATE_YEAR,
        payload: res.data?.year,
      });
    } catch (err) {
      debug(DEBUG_PATH, 'updateCell: error', {
        rowId,
        data,
      });

      dispatch({
        type: UPDATE_CELL,
        payload: {
          _row: rowId,
          value: data.value,
          status: STATUS_ERROR,
        },
      });

      dispatch(fetchMessage(I18n.t('app.message.cant_fetch_budget')));
    }
  };
