import React, { useCallback } from 'react';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { Translate } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import validateEmail from 'utils/validateEmail';
import FormField from 'components/formField/FormField';

const FORGOT_PASSWORD_FORM_NAME = 'forgotPasswordForm';

const styles = (theme) => ({
  button: {
    marginTop: theme.spacing(2),
  },
});

const ForgotPasswordForm = ({
  onForgotPassword,
  classes,
  handleSubmit,
  submitting,
}) => {
  const onSubmit = useCallback(
    ({ email }) => {
      onForgotPassword({ email });
    },
    [onForgotPassword]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Field
        key="email"
        component={FormField}
        type="text"
        label="Email"
        name="email"
      />
      <Button
        type="submit"
        disabled={submitting}
        className={classes.button}
        variant="contained"
        color="secondary"
        fullWidth
      >
        <Translate value="auth.send_password_reset_email" />
      </Button>
    </form>
  );
};

function validate({ email }) {
  const errors = {};

  errors.email = validateEmail(email);

  return errors;
}

export default compose(
  reduxForm({ validate, form: FORGOT_PASSWORD_FORM_NAME }),
  withStyles(styles)
)(ForgotPasswordForm);
