import _ from 'lodash';
import axios from 'axios';
import debug from 'utils/debug';
import { I18n } from 'react-redux-i18n';
import { fetchMessage } from 'models/message/actions';
import { FETCH_USER } from './actionTypes';

const DEBUG_PATH = 'models/auth/actionsAccount';

export const updateUserEmail =
  ({ email }, callback) =>
  async (dispatch) => {
    try {
      const res = await axios.post('/api/user/account/update-email', { email });

      dispatch({ type: FETCH_USER, payload: res.data });
      dispatch(
        fetchMessage(
          I18n.t('app.message.email_successfully_changed'),
          'success'
        )
      );

      if (_.isFunction(callback)) {
        callback();
      }
    } catch (err) {
      dispatch(fetchMessage(_.get(err, 'response.data.message')));
      debug(DEBUG_PATH, 'updateUserEmail: error', err);
    }
  };

export const updateUserPassword =
  ({ currentPassword, newPassword }, callback) =>
  async (dispatch) => {
    try {
      const res = await axios.post('/api/user/account/update-password', {
        currentPassword,
        newPassword,
      });

      dispatch({ type: FETCH_USER, payload: res.data });

      if (_.isFunction(callback)) {
        callback();
      }

      dispatch(
        fetchMessage(
          I18n.t('app.message.password_successfully_changed'),
          'success'
        )
      );
    } catch (err) {
      dispatch(fetchMessage(_.get(err, 'response.data.message')));
      debug(DEBUG_PATH, 'updateUserPassword: error', err);
    }
  };

export const closeAccount = (callback) => async (dispatch) => {
  try {
    const res = await axios.post('/api/user/account/close-account');

    dispatch({ type: FETCH_USER, payload: res.data });

    if (_.isFunction(callback)) {
      callback();
    }

    dispatch(
      fetchMessage(I18n.t('app.message.account_closed_successfully'), 'success')
    );
  } catch (err) {
    dispatch(fetchMessage(_.get(err, 'response.data.message')));
    debug(DEBUG_PATH, 'closeAccount: error', err);
  }
};
