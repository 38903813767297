import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Translate } from 'react-redux-i18n';
import classNames from 'classnames';
import Table from '@material-ui/core//Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import RemoveIcon from '@material-ui/icons/Remove';
import { withStyles } from '@material-ui/core/styles';
import * as localeModelSelectors from 'models/locale/selectors';
import { SUBSCRIPTION_PLAN_PREMIUM_PATH } from 'constants.js';

const styles = {
  tableContainer: {
    '& span, & strong': {
      fontSize: '1.125rem',
    },
  },
  footerRow: {
    '& td': {
      border: 'none',
    },
  },
  button: {
    borderRadius: '2rem',
    padding: '8px 24px ',
    '& span': {
      fontSize: '1rem',
    },
  },
  noBorder: {
    '& td, & th': {
      border: 'none',
    },
  },
  isDense: {
    '& td:first-child': {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
};

function createData(nameTranslationKey, free, premium) {
  return { nameTranslationKey, free, premium };
}

const rows = [
  createData('budget_customization', true, true),
  createData('income_and_expanse_tracking', true, true),
  createData('no_adverts', false, true),
  createData('more_than_one_budget', false, true),
  createData('free_support', false, true),
];

const getIcon = (value) =>
  value ? <CheckIcon color="primary" /> : <RemoveIcon />;

const SubscriptionPlansTable = ({
  showFree,
  showPremium,
  showButtons,
  showHead,
  showPrice,
  isDense,
  noBorder,
  classes,
}) => {
  const subscriptionsPrice = useSelector(
    localeModelSelectors.getSubscriptionsPrice
  );

  let history = useHistory();

  const handleClick = () => {
    history.push(SUBSCRIPTION_PLAN_PREMIUM_PATH);
  };

  const tableClass = classNames(
    isDense ? classes.isDense : null,
    noBorder ? classes.noBorder : null
  );

  return (
    <TableContainer className={classes.tableContainer}>
      <Table
        sx={{ minWidth: 650 }}
        size={isDense ? 'small' : 'medium'}
        aria-label="subscription plans"
        className={tableClass}
      >
        {showHead && (
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="center">
                <Translate value="subscription.subscription_type.free" />
              </TableCell>
              <TableCell align="center">
                <Translate value="subscription.subscription_type.premium" />
              </TableCell>
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {showPrice && (
            <TableRow>
              <TableCell component="th" scope="row">
                <Translate value={'common.price'} />
              </TableCell>
              <TableCell align="center">
                <strong>{subscriptionsPrice.free}</strong>
              </TableCell>
              <TableCell align="center">
                <strong>{subscriptionsPrice.monthly}</strong>
              </TableCell>
            </TableRow>
          )}
          {rows.map((row) => (
            <TableRow
              key={row.nameTranslationKey}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {!isDense && (
                <TableCell component="th" scope="row">
                  <Translate value={`subscription.${row.nameTranslationKey}`} />
                </TableCell>
              )}
              {showFree && (
                <TableCell align="center">{getIcon(row.free)}</TableCell>
              )}
              {showPremium && (
                <TableCell align="center">{getIcon(row.premium)}</TableCell>
              )}
              {isDense && (
                <TableCell component="th" scope="row">
                  <Translate value={`subscription.${row.nameTranslationKey}`} />
                </TableCell>
              )}
            </TableRow>
          ))}
          {showButtons && (
            <TableRow className={classes.footerRow}>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell align="center">
                <Button
                  onClick={handleClick}
                  className={classes.button}
                  variant="contained"
                  color="secondary"
                >
                  <Translate value="subscription.go_premium" />
                </Button>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default withStyles(styles)(SubscriptionPlansTable);
