import React, { useState } from 'react';
import { Translate } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PostAddIcon from '@material-ui/icons/PostAdd';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import BudgetCellNoteDialog from 'modules/budget/budgetCell/budgetCellNoteDialog/BudgetCellNoteDialog';

const styles = (theme) => ({
  menuItemIcon: {
    minWidth: theme.spacing(4),
  },
});

const BudgetCellMenu = ({
  cell,
  anchorEl,
  closeMenu,
  onClear,
  onCheckToggle,
  onNoteSave,
  classes,
}) => {
  const { note } = cell;

  const [isNoteDialogOpen, setIsNoteDialogOpen] = useState(false);

  const handleClear = () => {
    onClear();
    closeMenu();
  };

  const handleCheckToggle = () => {
    onCheckToggle();
    closeMenu();
  };

  const handleNoteDialogOpen = () => {
    setIsNoteDialogOpen(true);
    closeMenu();
  };

  const handleNoteDialogClose = () => {
    setIsNoteDialogOpen(false);
  };

  return (
    <>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        data-test-id="budget-cell-menu"
      >
        <MenuItem onClick={handleNoteDialogOpen}>
          <ListItemIcon className={classes.menuItemIcon}>
            <PostAddIcon fontSize="small" />
          </ListItemIcon>
          {note ? (
            <Translate value="budget.edit_note" />
          ) : (
            <Translate value="budget.add_note" />
          )}
        </MenuItem>
        <MenuItem onClick={handleCheckToggle}>
          <ListItemIcon className={classes.menuItemIcon}>
            <CheckIcon fontSize="small" />
          </ListItemIcon>
          <Translate value="budget.toggle_complete" />
        </MenuItem>
        <MenuItem onClick={handleClear}>
          <ListItemIcon className={classes.menuItemIcon}>
            <ClearIcon fontSize="small" />
          </ListItemIcon>
          <Translate value="budget.clear" />
        </MenuItem>
      </Menu>
      <BudgetCellNoteDialog
        isOpen={isNoteDialogOpen}
        onClose={handleNoteDialogClose}
        note={note}
        onNoteSave={onNoteSave}
      />
    </>
  );
};

export default withStyles(styles)(BudgetCellMenu);
