import { connect } from 'react-redux';
import * as authModelActions from 'models/auth/actions';
import * as authModelSelectors from 'models/auth/selectors';
import Account from './Account';

const mapStateToProps = () => {
  return (state) => {
    return {
      user: authModelSelectors.getUser(state),
      userEmail: authModelSelectors.getUserEmail(state),
    };
  };
};

const mapDispatchToProps = {
  onCloseAccount: authModelActions.closeAccount,
  onUpdateUserEmail: authModelActions.updateUserEmail,
  onUpdateUserPassword: authModelActions.updateUserPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
