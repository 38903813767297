import { I18n } from 'react-redux-i18n';
import { PASSWORD_MIN_LENGTH } from 'constants.js';

const isNonWhiteSpace = /^\S*$/;
const isContainsUppercase = /^(?=.*[A-Z]).*$/;
const isContainsLowercase = /^(?=.*[a-z]).*$/;
const isContainsNumber = /^(?=.*[0-9]).*$/;

export default (password = '') => {
  if (!password) {
    return I18n.t('auth.enter_password');
  }

  if (!isNonWhiteSpace.test(password)) {
    return I18n.t('auth.password_must_not_have_whitespace');
  }

  if (!isContainsUppercase.test(password)) {
    return I18n.t('auth.password_must_have_upparcase');
  }

  if (!isContainsLowercase.test(password)) {
    return I18n.t('auth.password_must_have_lowercase');
  }

  if (!isContainsNumber.test(password)) {
    return I18n.t('auth.password_must_contain_digit');
  }

  if (password && password.length < PASSWORD_MIN_LENGTH) {
    return I18n.t('auth.use_n_characters', { number: PASSWORD_MIN_LENGTH });
  }

  return;
};
