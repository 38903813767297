import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({});

const BudgetSidebar = ({ children }) => {
  return (
    <>
      {children}
    </>
  );
};

export default withStyles(styles)(BudgetSidebar);
