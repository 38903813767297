import React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuMain from 'components/menuMain';
import { BUDGET_PATH } from 'constants.js';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
  appName: {
    margin: 0,
    flexGrow: 1,
  },
  appNameTitle: {
    fontFamily: 'Galada, cursive',
    fontSize: 35,
    fontWeight: 500,
    letterSpacing: 1,
    position: 'relative',
    top: 3,
  },
  appNameSubtitle: {
    marginLeft: theme.spacing(1),
    opacity: .75,
    fontStyle: 'italic',
    whiteSpace: 'nowrap',
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'inline'
    },
  }
});

const Masthead = ({ classes }) => {
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <h1 className={classes.appName}>
            <Link to={BUDGET_PATH} className={classes.link}>
              <span className={classes.appNameTitle}>
                <Translate value="app.title" />
              </span>
              <Typography className={classes.appNameSubtitle} component='sub' variant="body2">
                <Translate value="app.subtitle" />
              </Typography>
            </Link>
          </h1>
          <MenuMain />
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default withStyles(styles)(Masthead);
