import _ from 'lodash';
import React, { useState, useEffect, useCallback } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import CurrencySelector from 'components/currencySelector/CurrencySelector';
import ShowMoreButton from 'components/showMoreButton/ShowMoreButton';
import BudgetTemplateSelector from 'components/budgetTemplateSelector/BudgetTemplateSelector';
import { getBudgetVariantTranslationKey } from 'modules/budget/utils';
import {
  TABLE_VARIANTS,
  TABLE_DEFAULT_VARIANT,
} from 'modules/budget/constants';
import globalStyles from 'globalStyles.js';

const styles = (theme) => ({
  dialogContent: {
    [theme.breakpoints.up('sm')]: {
      minWidth: 400,
    },
  },
  title: {
    marginTop: 0,
  },
  variant: {
    marginTop: theme.spacing(2),
  },
  templateSelector: {
    marginTop: theme.spacing(1),
  },
  currency: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
});

const BudgetTableDetailsDialog = ({
  children,
  name = '',
  variant = TABLE_DEFAULT_VARIANT,
  currency,
  defaultCurrency,
  onCancel,
  onConfirm,
  confirmButtonText,
  description,
  tableTemplates,
  isTableTemplatesVisible,
  isVariantSelectorVisible,
  fetchTableTemplates,
  classes,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMoreOpen, setIsMoreOpen] = useState(false);
  const [nameValue, setNameValue] = useState(name);
  const [varinantValue, setVariantValue] = useState(variant);
  const [currencyValue, setCurrencyValue] = useState(currency);
  const [templateValue, setTemplateValue] = useState('');

  useEffect(() => {
    if (isTableTemplatesVisible && isOpen && !tableTemplates) {
      fetchTableTemplates();
    }
  }, [isTableTemplatesVisible, isOpen, tableTemplates, fetchTableTemplates]);

  useEffect(() => {
    setTemplateValue('');
  }, [isOpen]);

  const globalClasses = globalStyles();

  useEffect(() => {
    setCurrencyValue(currency || defaultCurrency);
  }, [currency, defaultCurrency]);

  const handleOpen = useCallback(() => {
    if (!name) {
      setNameValue('');
    }
    setIsOpen(true);
  }, [name, setIsOpen, setNameValue]);

  const handleClose = useCallback(() => {
    if (_.isFunction(onCancel)) {
      onCancel();
    }
    setIsOpen(false);
  }, [setIsOpen, onCancel]);

  const handleConfirm = useCallback(() => {
    onConfirm({
      name: nameValue,
      variant: varinantValue,
      currency: currencyValue || defaultCurrency,
      ...(templateValue && { template: templateValue }),
    });
    setIsOpen(false);
  }, [
    setIsOpen,
    nameValue,
    varinantValue,
    currencyValue,
    templateValue,
    onConfirm,
    defaultCurrency,
  ]);

  const handleCurrencyChange = useCallback(
    (value) => {
      setCurrencyValue(value);
    },
    [setCurrencyValue]
  );

  const handleVariantChange = (event) => {
    setVariantValue(event.target.value);
  };

  const handleNameValueChange = (event) => {
    setNameValue(event.target.value);
  };

  const handleTemplateValueChange = useCallback(
    (event) => {
      setTemplateValue(event.target.value);
    },
    [setTemplateValue]
  );

  const handleClickMore = () => {
    setIsMoreOpen(!isMoreOpen);
  };

  return (
    <>
      <Box component="span" onClick={handleOpen}>
        {children}
      </Box>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        aria-labelledby="edit-table-dialog-description"
      >
        <DialogTitle id="edit-table-dialog-description">
          {description}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <TextField
            className={classes.title}
            label={I18n.t('budget.table_name')}
            value={nameValue}
            onChange={handleNameValueChange}
            margin="dense"
            variant="outlined"
            fullWidth
            inputProps={{ maxLength: 100 }}
          />
          {isVariantSelectorVisible && (
            <FormControl
              className={classes.variant}
              variant="outlined"
              margin="dense"
              fullWidth
            >
              <InputLabel id="edit-table-dialog-varinat-label">
                <Translate value="budget.variant" />
              </InputLabel>
              <Select
                labelId="edit-table-dialog-varinat-label"
                label={I18n.t('budget.variant')}
                value={varinantValue}
                onChange={handleVariantChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Table variant' }}
              >
                {_.map(TABLE_VARIANTS, (variant) => (
                  <MenuItem key={variant} value={variant}>
                    <Translate
                      value={getBudgetVariantTranslationKey(variant)}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {isTableTemplatesVisible && (
            <div className={classes.templateSelector}>
              <BudgetTemplateSelector
                label={I18n.t('budget.budget_template_table')}
                templates={_.filter(tableTemplates, { variant: varinantValue })}
                templateValue={templateValue}
                handleTemplateValueChange={handleTemplateValueChange}
              />
            </div>
          )}
          <ShowMoreButton isOpen={isMoreOpen} onClick={handleClickMore} />
          {isMoreOpen && (
            <div className={classes.currency}>
              <CurrencySelector
                selectedCurrency={currencyValue}
                onCurrencyChange={handleCurrencyChange}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions className={globalClasses.dialogActions}>
          <Button onClick={handleClose} size="medium">
            <Translate value="common.cancel" />
          </Button>
          <Button
            onClick={handleConfirm}
            color="primary"
            variant="contained"
            size="medium"
            autoFocus
          >
            {confirmButtonText || <Translate value="common.confirm" />}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(BudgetTableDetailsDialog);
