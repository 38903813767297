import _ from 'lodash';
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import * as localeModelSelectors from 'models/locale/selectors';
import * as budgetModelSelectors from 'models/budget/selectors';
import * as authModelSelectors from 'models/auth/selectors';
import * as budgetModelActions from 'models/budget/actions';
import * as budgetModelQueries from 'models/budget/queries';
import * as localeModelQueries from 'models/locale/queries';
import CurrencySelector from 'components/currencySelector/CurrencySelector';
import BudgetTemplateSelector from 'components/budgetTemplateSelector/BudgetTemplateSelector';
import Footer from 'components/footer/Footer';
import globalStyles from 'globalStyles.js';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(4),
    padding: theme.custom.wrapper.padding,
    marginBottom: theme.custom.global.marginBottom,
    minHeight: theme.custom.global.minHeightWithFooter,
  },
  cardContent: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },
  subtitle: {
    marginTop: theme.spacing(2),
  },
  formFields: {
    marginTop: theme.spacing(1.5),
  },
  budgetName: {
    marginTop: theme.spacing(2),
  },
  selector: {
    marginTop: theme.spacing(1.25),
  },
  actions: {
    marginTop: theme.spacing(4),
  },
});

const BudgetCreate = ({ classes }) => {
  const globalClasses = globalStyles();
  const dispatch = useDispatch();

  let history = useHistory();

  const activeLocale = useSelector(localeModelSelectors.getActiveLocale);
  const budgetsList = useSelector(budgetModelSelectors.getBudgetsList);
  const budgetTemplates = useSelector(budgetModelSelectors.getBudgetTemplates);
  const userDefaultCurrency = useSelector(
    authModelSelectors.getUserProfileCurrency
  );
  const localeDefaultCurrency =
    localeModelQueries.getDefaultCurrency(activeLocale);
  const defaultCurrency = userDefaultCurrency || localeDefaultCurrency;

  const [nameValue, setNameValue] = useState('');
  const [currencyValue, setCurrencyValue] = useState(defaultCurrency);
  const [templateValue, setTemplateValue] = useState('');

  useEffect(() => {
    if (!budgetTemplates) {
      dispatch(budgetModelActions.fetchBudgetTemplates());
    }
  }, [budgetTemplates, dispatch]);

  const handleBudgetCreate = useCallback(() => {
    const budgetListSize = _.size(budgetsList);
    const newBudgetName = budgetModelQueries.getNewBudgetName(
      nameValue,
      budgetListSize
    );

    dispatch(
      budgetModelActions.addBudget(
        {
          name: newBudgetName,
          currency: currencyValue || defaultCurrency,
          template: templateValue,
        },
        (path) => {
          history.push(path);
        }
      )
    );
  }, [
    nameValue,
    currencyValue,
    templateValue,
    budgetsList,
    defaultCurrency,
    history,
    dispatch,
  ]);

  const handleCurrencyChange = useCallback(
    (value) => {
      setCurrencyValue(value);
    },
    [setCurrencyValue]
  );

  const handleNameValueChange = useCallback(
    (event) => {
      setNameValue(event.target.value);
    },
    [setNameValue]
  );

  const handleTemplateValueChange = useCallback(
    (event) => {
      setTemplateValue(event.target.value);
    },
    [setTemplateValue]
  );

  return (
    <>
      <div className={classes.root} data-test-id="budget-create">
        <Grid container justifyContent="center">
          <Grid item className={globalClasses.containerSm}>
            <Grid container direction="column" alignItems="stretch" spacing={4}>
              <Grid item>
                <Card>
                  <CardContent className={classes.cardContent}>
                    <Typography variant="h5" gutterBottom>
                      <Translate value="app.budget_new" />
                    </Typography>
                    <Typography
                      className={classes.subtitle}
                      variant="subtitle1"
                      gutterBottom
                    >
                      <Translate value="budget.budget_create_new_info" />
                    </Typography>
                    <div className={classes.formFields}>
                      <TextField
                        className={classes.budgetName}
                        label={I18n.t('budget.budget_name')}
                        value={nameValue}
                        onChange={handleNameValueChange}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        inputProps={{ maxLength: 100 }}
                      />
                      <div className={classes.selector}>
                        <BudgetTemplateSelector
                          label={I18n.t('budget.budget_template')}
                          templates={budgetTemplates}
                          templateValue={templateValue}
                          handleTemplateValueChange={handleTemplateValueChange}
                        />
                      </div>
                      <div className={classes.selector}>
                        <CurrencySelector
                          selectedCurrency={currencyValue}
                          onCurrencyChange={handleCurrencyChange}
                        />
                      </div>
                    </div>
                    <div className={classes.actions}>
                      <Button
                        onClick={handleBudgetCreate}
                        color="primary"
                        variant="contained"
                        fullWidth
                      >
                        <Translate value="budget.budget_create" />
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </>
  );
};

export default withStyles(styles)(BudgetCreate);
