import { FETCH_USER, UPDATE_COMPONENT_USER_SETTINGS } from './actionTypes';

const INITIAL_STATE = {
  user: {},
  isAuthenticated: null,
};

// only before we get information if user is logged in, or not, reducer state is "null", later it will be user object, or "false"
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_USER:
      return {
        user: action.payload,
        isAuthenticated: Boolean(action.payload),
      };
    case UPDATE_COMPONENT_USER_SETTINGS:
      return {
        ...state,
        user: {
          ...state.user,
          settings: {
            ...state.user?.settings,
            components: {
              ...state.user?.settings?.components,
              [action.payload.componentId]: {
                ...state.user?.settings?.components?.[
                action.payload.componentId
                ],
                ...action.payload.data,
              },
            },
          },
        },
      };
    default:
      return state;
  }
}
